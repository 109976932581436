import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {
    colors,
    fontFamilies,
    fontSizes,
    radius,
    spacing
} from '@/utils/constants';
import {LanguageDirection} from '@/types/languages';
import {Platform} from 'react-native';

export const ICON_SIZE = 24;

//

export const CheckboxContainer = styled.View`
    flex-direction: row;
    flex-shrink: 1;
    margin-bottom: ${px(spacing.xs)};
    margin-top: ${px(spacing.xs)};
    padding-right: ${px(spacing.md * 2)};
`;

export const CheckboxLabel = styled.Text<{
    isChecked: boolean;
}>`
    color: ${colors.greyDark};
    flex: 1;
    flex-shrink: 1;
    font-family: ${fontFamilies.medium};
    font-size: ${px(fontSizes.small)};
    font-weight: 500;

    ${props => props.isChecked && `font-family: ${fontFamilies.medium};`}
`;

export const CheckboxIconContainer = styled.TouchableOpacity<{
    isChecked: boolean;
}>`
    align-items: center;
    border: 1px solid ${colors.greyDark};
    border-radius: ${px(4)};
    height: ${px(ICON_SIZE)};
    justify-content: center;
    margin-bottom: ${px(-4)};
    margin-right: ${px(spacing.sm)};
    margin-top: ${px(-2)};
    width: ${px(ICON_SIZE)};

    ${props =>
        props.isChecked &&
        `
        background-color: ${colors.primary};
        border-color: ${colors.primary};
    `}
`;

export const checkboxIcon = css`
    height: ${px(18)};
    width: ${px(18)};
`;

//

export const RadioButton = styled.TouchableOpacity`
    align-items: center;
    background-color: ${colors.tertiary};
    border-radius: ${px(spacing.md)};
    flex: 1;
    flex-direction: row;
    padding: ${px(14)};
    padding-right: ${px(14 * 2)};
`;

export const RadioButtonIcon = styled.View`
    border-color: ${colors.primary};
    border-radius: ${px(spacing.sm * 2)};
    border-style: solid;
    border-width: 2px;
    height: ${px(spacing.input)};
    margin-right: ${px(spacing.sm)};
    padding: 2px;
    width: ${px(spacing.input)};
`;

export const RadioButtonIconChecked = styled.View<{
    isSelected: boolean;
}>`
    background-color: ${colors.primary};
    border-radius: ${px(spacing.sm * 2)};
    height: ${px(spacing.input - 8)};
    width: ${px(spacing.input - 8)};

    ${props => !props.isSelected && 'opacity: 0;'}
`;

export const RadioButtonLabel = styled.Text<{
    isSelected: boolean;
}>`
    ${props => props.isSelected && 'font-weight: medium;'}
`;

//

export const CheckboxButtonIcon = styled.View<{
    isSelected: boolean;
}>`
    border-color: ${colors.primary};
    border-radius: ${px(spacing.xs)};
    border-style: solid;
    border-width: 2px;
    height: ${px(spacing.input)};
    margin-right: ${px(spacing.sm)};
    padding: 2px;
    width: ${px(spacing.input)};

    ${props => props.isSelected && `background-color:${colors.primary};`}
`;

//

export const SelectedValuesContainer = styled.View`
    align-items: flex-start;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: ${px(spacing.md)};
`;

export const SelectedValue = styled.TouchableOpacity`
    align-items: center;
    background-color: ${colors.orangeLight};
    border-radius: ${px(radius.lg)};
    margin-bottom: ${px(spacing.sm)};
    padding: ${px(spacing.sm)} ${px(spacing.sm * 1.5)};
    padding-right: ${px(spacing.lg)};
    position: relative;
`;

export const SelectedValueLabel = styled.Text`
    font-size: ${px(fontSizes.small)};
`;

export const removeIcon = css`
    color: ${colors.black};
    height: 16px;
    margin-left: ${px(spacing.sm)};
    margin-top: ${px(fontSizes.small * 0.7)};
    position: absolute;
    right: ${px(spacing.sm * 1.5)};
    width: 16px;

    ${Platform.OS === 'web' && 'margin-top: 1px;'}
`;

//

export const TextContainer = styled.View<{
    autoGrow: boolean;
}>`
    ${props => !props.autoGrow && 'flex: 1;'}
`;

export const TextInputContainer = styled.View`
    flex: 1;
`;

export const textInput = (props: {
    isActive: boolean;
    direction?: string | LanguageDirection;
    hasBorder: boolean;
    hasError: boolean;
    rows: number;
}) => css`
    background-color: ${colors.white};
    border: 1px solid ${colors.greyMid};
    border-radius: ${px(radius.md)};
    color: ${colors.greyDark};
    font-family: ${fontFamilies.default};
    font-size: ${px(fontSizes.default + 1)};
    height: ${px(52)};
    padding: ${px(spacing.textInput * 0.8)} ${px(spacing.textInput)};

    ${props.isActive && `border: 1px solid ${colors.primary};`}
    ${props.hasError && `border: 1px solid ${colors.red};`}
    ${!props.hasBorder &&
    `
        border-width: 0;
        height: ${px(60)};
        padding-left: 0;
        padding-right: 0;
        `}
    ${`height: ${px(52 * (props.rows || 1))};`}
    ${props.direction === 'rtl' ? 'text-align: right;' : 'text-align:left;'}
`;

export const TextIconContainer = styled.TouchableOpacity`
    align-items: center;
    background-color: ${colors.white};
    border-radius: ${px(radius.md)};
    flex: 1;
    height: ${px(48)};
    justify-content: center;
    position: absolute;
    right: ${px(2)};
    top: ${px(2)};
    width: ${px(46)};
`;
