import React from 'react';
import {Text, View} from 'react-native';
import i18n from 'i18n-js';
import {LearningCourse} from '@/types/learning';

const CourseContent: React.FC<Partial<LearningCourse>> = ({
    instructions
}: Partial<LearningCourse>) => {
    if (!instructions) return null;

    return (
        <View className="mx-auto w-full max-w-2xl">
            <View className="px-4 py-8">
                <Text className="text-sm font-medium text-blp-grey-dark">
                    {i18n.t('learning.instructions')}
                </Text>
                <Text className="font-default leading-6 text-md mt-2">
                    {instructions}
                </Text>
            </View>
        </View>
    );
};

export default CourseContent;
