import React, {memo, ReactNode, useContext, useEffect} from 'react';
import {Text, View} from 'react-native';
import i18n from 'i18n-js';
import {useFormikContext} from 'formik';
import MemoizedLayout from '@/components/MemoizedLayout';
import Checkbox from './Checkbox';
// import CheckboxButton from '@/components/CheckboxButton';
// import FormField from '@/components/FormField';
import CheckboxButton from './CheckboxButton';
import RadioButton from './RadioButton';
import Select from './Select';
import InputText from './Text';
import Grid from '@/components/Grid';
import * as UI from '@/components/UI/styles';
// import ModalContext from '@/components/Modal/context';
// import SelectField from '@/components/SelectField';
import type {Form, FormFieldOptions} from '@/types/form';

interface Props {
    fields: Form['fields'];
    formName: string;
    handleChange?: Function;
    handleFileChange?: Function;
    options?: {
        [key: string]: {
            label: string;
            value: number | string;
        }[];
    };
    showHidden?: boolean;
    slots?: {
        [key: string]: () => ReactNode;
    };
}

const mergeTranslationsWithField = (
    formName: string,
    fieldName,
    field: Form['fields'][0]
) => {
    const translatableKeys = ['label', 'description', 'placeholder', 'options'];

    translatableKeys.forEach(key => {
        const value = i18n.t(`${formName}.form.${fieldName}_${key}`, {
            defaultValue: ''
        });

        if (value) {
            field[key] = value;
        }
    });

    return field;
};

const FormFields: React.FC<Props> = ({
    fields,
    formName,
    handleChange,
    handleFileChange,
    options,
    showHidden = true,
    slots
}: Props) => {
    const formik = useFormikContext();

    useEffect(() => {
        if (handleChange) {
            handleChange(formik.values);
        }
    }, [formik.values]);

    if (!fields) {
        return null;
    }

    return (
        <View className="space-y-4">
            {Object.keys(fields)
                .filter(fieldName => {
                    const field = fields[fieldName];

                    if (
                        (showHidden && field.type !== 'hidden') ||
                        (!showHidden && field.type === 'hidden')
                    ) {
                        return false;
                    } else if (field.hidden) {
                        const requiredValues = field.hidden.filter(
                            ([fieldKey, fieldValue]) => {
                                // This is purposefully not strict comparison
                                return formik.values?.[fieldKey] == fieldValue;
                            }
                        );

                        return !requiredValues.length;
                    } else {
                        return true;
                    }
                })
                .map(fieldName => {
                    const field = mergeTranslationsWithField(
                        formName,
                        fieldName,
                        fields[fieldName]
                    );
                    const hasError =
                        formik.errors.hasOwnProperty(fieldName) &&
                        formik.touched.hasOwnProperty(fieldName);
                    let renderField = null;

                    if (options?.[fieldName]?.length > 0) {
                        field.options = [...options?.[fieldName]];
                    }

                    if (field.field === 'select' && field.options.length < 5) {
                        field.field = 'input';
                        field.type = 'radio';
                    }

                    if (field.field === 'input' || field.field === 'textarea') {
                        if (
                            field.type === 'checkbox' ||
                            field.type === 'radio'
                        ) {
                            if (field.type === 'radio') {
                                renderField = (
                                    <RadioButton
                                        hasError={hasError}
                                        label={field.label}
                                        name={fieldName}
                                        options={field.options}
                                        setValue={value =>
                                            formik.setFieldValue(
                                                fieldName,
                                                value
                                            )
                                        }
                                        value={formik.values[fieldName]}
                                    />
                                );
                            } else {
                                if (field.options) {
                                    renderField = (
                                        <CheckboxButton
                                            hasError={hasError}
                                            label={field.label}
                                            name={fieldName}
                                            options={field.options}
                                            setValue={value =>
                                                formik.setFieldValue(
                                                    fieldName,
                                                    value
                                                )
                                            }
                                            value={formik.values[fieldName]}
                                        />
                                    );
                                } else {
                                    renderField = (
                                        <Checkbox
                                            label={field.label}
                                            name={fieldName}
                                        />
                                    );
                                }
                            }
                        } else if (field.type === 'hidden') {
                            // renderField = (
                            //     <input type="hidden" name={fieldName} />
                            // );
                        } else if (field.type === 'file') {
                            renderField = <Text>file</Text>;
                            // renderField = (
                            //     <FormField
                            //         description={field?.description}
                            //         label={field.label}
                            //         name={fieldName}
                            //     >
                            //         <FileUpload
                            //             handleFileChange={handleFileChange}
                            //             hasError={hasError}
                            //             name={fieldName}
                            //         />
                            //     </FormField>
                            // );
                        } else {
                            renderField = (
                                <InputText
                                    autoCompleteType={field?.autoCompleteType}
                                    keyboardType={field?.keyboardType}
                                    description={field.description}
                                    features={field.features}
                                    handleChange={formik.handleChange}
                                    hasError={hasError}
                                    label={field.label}
                                    multiline={field.field === 'textarea'}
                                    name={fieldName}
                                    placeholder={field.placeholder}
                                    rows={field.rows || 1}
                                    type={
                                        field.type as
                                            | 'password'
                                            | 'date'
                                            | 'text'
                                    }
                                    value={formik.values[fieldName]}
                                />
                            );
                        }
                    } else if (field.field === 'select') {
                        renderField = (
                            <Select
                                handleChange={formik.handleChange}
                                hasError={hasError}
                                label={field.label}
                                isMulti={field?.isMulti}
                                name={fieldName}
                                options={field.options}
                                placeholder={field.placeholder}
                                value={formik.values[fieldName]}
                            />
                        );
                    }

                    if (renderField) {
                        return field.type === 'hidden' ? (
                            renderField
                        ) : (
                            <View key={fieldName} className="flex">
                                {field.label && field.type !== 'checkbox' && (
                                    <Text className="mb-2 text-md flex font-medium text-blp-black">
                                        {field.label}
                                    </Text>
                                )}
                                <MemoizedLayout>{renderField}</MemoizedLayout>
                                {field.description && (
                                    <Text className="font-default leading-[18px] text-[13px] mt-2">
                                        {field.description}
                                    </Text>
                                )}
                            </View>
                        );
                    }
                })}
        </View>
    );
};

export default FormFields;
