import {Dimensions} from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export const layout = {
    window: {
        width,
        height
    },
    isSmallDevice: width < 375
};

export const colors = {
    orange: '#FF7602',
    orangeLight: '#febb80',
    grey: '#F8F3EE',
    greyDark: '#555454',
    greyMid: '#949186',
    greyLight: '#D5D3CC',
    blue: '#72C7E7',
    yellow: '#FDC82F',
    yellowLight: '#FFF4D5',
    white: '#fff',
    black: '#000',
    red: '#f44336',
    redLight: '#fff8f7',

    primary: '#FF7602',
    secondary: '#FDC82F',
    tertiary: '#F8F3EE'
};

export const themes = {
    primary: {
        background: colors.primary,
        border: colors.primary,
        buttonText: colors.black,
        text: colors.black
    },
    secondary: {
        background: colors.secondary,
        buttonText: colors.white,
        text: colors.white
    },
    tertiary: {
        background: colors.tertiary,
        buttonText: colors.black,
        heading: colors.black,
        text: colors.black
    },
    greyLight: {
        background: colors.greyLight,
        buttonText: colors.black,
        heading: colors.black,
        text: colors.black
    },
    red: {
        background: colors.red,
        buttonText: colors.white,
        heading: colors.white,
        text: colors.white
    },
    app: {
        background: colors.white,
        heading: colors.black,
        text: colors.black
    },
    transparent: {
        background: 'transparent',
        heading: colors.black,
        text: colors.black
    },
    navigation: {
        primary: colors.primary,
        background: colors.white,
        card: colors.white,
        text: colors.black,
        border: colors.grey,
        notification: colors.primary
    }
};

export const fontFamilies = {
    default: 'Roboto_300Light',
    light: 'Roboto_300Light',
    medium: 'Roboto_500Medium',
    heading: 'Roboto_500Medium'
};

export const fontSizes = {
    default: 16,
    lead: 18,
    small: 14,
    h1: 30,
    h2: 26,
    h3: 24,
    h4: 22,
    h5: 20,
    h6: 16,
    huge: 90,
    caption: 11,
    input: 18
};

export const radius = {
    sm: 6,
    md: 12,
    lg: 20
};

export const shadows = {
    default: '0 6px 18px 0 rgba(0, 0, 0, 0.1)',
    hint: '0 10px 12px 0 rgba(0, 0, 0, 0.01)',
    hover: '0 6px 24px 0 rgba(0, 0, 0, 0.1)'
};

export const spacing = {
    none: 0,
    xs: 4.5,
    sm: 9,
    md: 18,
    lg: 36,
    xl: 54,
    button: 20,
    divider: 3,
    input: 24,
    row: 16,
    rowDivider: 36,
    section: 24,
    tag: 8,
    textInput: 18
};

export type Color = keyof typeof colors;
export type FontFamily = keyof typeof fontFamilies;
export type FontSize = keyof typeof fontSizes;
export type Layout = keyof typeof layout;
export type Radius = number;
export type Shadow = keyof typeof shadows;
export type Spacing = keyof typeof spacing;
export type Theme = keyof typeof themes;
export type ThemeProperties = (typeof themes)['primary'];
