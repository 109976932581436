/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
import * as React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {createStackNavigator} from '@react-navigation/stack';
import {getFocusedRouteNameFromRoute} from '@react-navigation/native';
import i18n from 'i18n-js';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';
import ContactScreen from '@/screens/Contact';
import FeedbackScreen from '@/screens/Feedback';
import ForumScreen from '@/screens/Forum';
import GuideScreen from '@/screens/Guide';
import LearningScreen from '@/screens/Learning';
import LearningContentUpdatesScreen from '@/screens/LearningContentUpdates';
import LearningModuleScreen from '@/screens/LearningModule';
import LearningModuleAssessmentScreen from '@/screens/LearningModuleAssessment';
import LearningModuleAssessmentCompleteScreen from '@/screens/LearningModuleAssessmentComplete';
import LearningCourseScreen from '@/screens/LearningCourse';
import MoreScreen from '@/screens/More';
import ResourcesScreen from '@/screens/Resources';
import ResourcesDetailScreen from '@/screens/ResourcesDetail';
import SettingsScreen from '@/screens/Settings';
import SettingsAccountScreen from '@/screens/SettingsAccount';
import SettingsLanguageScreen from '@/screens/SettingsLanguage';
import SettingsRemindersScreen from '@/screens/SettingsReminders';
import SettingsRoleScreen from '@/screens/SettingsRole';
import ShareScreen from '@/screens/Share';
import SignUpOnboardingScreen from '@/screens/SignUpOnboarding';
import WellbeingScreen from '@/screens/Wellbeing';
import WellbeingBadgeScreen from '@/screens/WellbeingBadge';
import {
    BottomTabParamList,
    LearningParamList,
    MoreParamList
} from '@/types/navigation';
import {colors, radius, spacing} from '@/utils/constants';
import {Platform} from 'react-native';

const TAB_BAR_HIDDEN_SCREENS = [
    'LearningModule',
    'LearningModuleAssessment',
    'LearningModuleAssessmentComplete',
    'LearningContentUpdates',
    'LearningCourse',
    'Settings',
    'SettingsAccount',
    'SettingsLanguage',
    'SettingsReminders',
    'SettingsRole',
    'SignUpOnboarding',
    // 'Contact',
    'Feedback',
    'Guide',
    'Forum',
    'ResourcesDetail',
    'Share',
    'WellbeingBadge',
    'Wellbeing'
];

const LearningStack = createStackNavigator<LearningParamList>();
const MoreStack = createStackNavigator<MoreParamList>();
const BottomTab = createBottomTabNavigator<BottomTabParamList>();

const LearningNavigator = () => (
    <LearningStack.Navigator>
        <LearningStack.Screen
            name="Learning"
            component={LearningScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="LearningModule"
            component={LearningModuleScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="LearningModuleAssessment"
            component={LearningModuleAssessmentScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="LearningModuleAssessmentComplete"
            component={LearningModuleAssessmentCompleteScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="LearningCourse"
            component={LearningCourseScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="LearningContentUpdates"
            component={LearningContentUpdatesScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="Settings"
            component={SettingsScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="Contact"
            component={ContactScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="SettingsAccount"
            component={SettingsAccountScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="SettingsLanguage"
            component={SettingsLanguageScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="SettingsReminders"
            component={SettingsRemindersScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="SettingsRole"
            component={SettingsRoleScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="SignUpOnboarding"
            component={SignUpOnboardingScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="Resources"
            component={ResourcesScreen}
            options={{headerShown: false}}
        />
        <LearningStack.Screen
            name="ResourcesDetail"
            component={ResourcesDetailScreen}
            options={{headerShown: false}}
        />
    </LearningStack.Navigator>
);

const MoreNavigator = () => (
    <MoreStack.Navigator>
        <MoreStack.Screen
            name="More"
            component={MoreScreen}
            options={{headerShown: false}}
        />
        <MoreStack.Screen
            name="Feedback"
            component={FeedbackScreen}
            options={{headerShown: false}}
        />
        <MoreStack.Screen
            name="Guide"
            component={GuideScreen}
            options={{headerShown: false}}
        />
        <MoreStack.Screen
            name="Share"
            component={ShareScreen}
            options={{headerShown: false}}
        />
        <MoreStack.Screen
            name="Forum"
            component={ForumScreen}
            options={{headerShown: false}}
        />
        <MoreStack.Screen
            name="Wellbeing"
            component={WellbeingScreen}
            options={{headerShown: false}}
        />
        <MoreStack.Screen
            name="WellbeingBadge"
            component={WellbeingBadgeScreen}
            options={{headerShown: false}}
        />
    </MoreStack.Navigator>
);

const BottomTabNavigator = route => {
    const initialParams = route.params;

    return (
        <BottomTab.Navigator
            initialRouteName="Learning"
            screenOptions={({route: routeName}) => {
                const hasTabBar = !TAB_BAR_HIDDEN_SCREENS.includes(
                    getFocusedRouteNameFromRoute(routeName) || routeName.name
                );

                // Note: React Navigation recommends hiding the bar bar with 'display: none',
                // however we noted that this caused scrolling issues on Web. Instead, we
                // simply set a height of 0 and overflow hidden. This fixes the scrolling
                // issue, but means that the tab bar is still visible in the DOM.
                return {
                    headerShown: false,
                    tabBarActiveTintColor: colors.primary,
                    tabBarInactiveTintColor: colors.greyDark,
                    tabBarLabelPosition: 'below-icon',
                    tabBarLabelStyle: {
                        paddingBottom: 4
                    },
                    tabBarStyle: {
                        backgroundColor: colors.grey,
                        borderTopWidth: 1,
                        borderTopColor: '#e7e3df',
                        elevation: 0,
                        shadowOpacity: 0,
                        height: hasTabBar ? 56 : 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        overflow: 'hidden',
                        paddingVertical: hasTabBar ? 6 : 0,
                        paddingTop: hasTabBar
                            ? Platform.OS === 'web'
                                ? 0
                                : 6
                            : 0,
                        width: '100%'
                    }
                };
            }}
        >
            <BottomTab.Screen
                name="Learning"
                component={LearningNavigator}
                initialParams={initialParams}
                options={{
                    tabBarIcon: ({color}) => (
                        <Svg
                            width="20"
                            height="17"
                            fill="none"
                            viewBox="0 0 20 19"
                        >
                            <Path
                                d="M10 11l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0010 17.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L10 11zm-4 6V9.5l4-2.222"
                                stroke={color}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </Svg>
                    ),
                    tabBarLabel: i18n.t('navigation.learning')
                }}
            />
            <BottomTab.Screen
                name="Resources"
                component={ResourcesScreen}
                initialParams={initialParams}
                options={{
                    tabBarIcon: ({color}) => (
                        <Svg
                            width="20"
                            height="20"
                            fill="none"
                            style={{width: 18, height: 18}}
                            viewBox="0 0 20 20"
                        >
                            <Path
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M11.828 8.172a4 4 0 0 0-5.656 0l-4 4a4 4 0 1 0 5.656 5.656l1.102-1.101m-.758-4.899a4 4 0 0 0 5.656 0l4-4a4 4 0 0 0-5.656-5.656l-1.1 1.1"
                            />
                        </Svg>
                    ),
                    tabBarLabel: i18n.t('navigation.resources')
                }}
            />
            <BottomTab.Screen
                name="Contact"
                component={ContactScreen}
                initialParams={initialParams}
                options={{
                    tabBarIcon: ({color}) => (
                        <Svg width="20" height="16" fill="none">
                            <Path
                                d="M1 4l7.89 5.26a2 2 0 002.22 0L19 4M3 15h14a2 2 0 002-2V3a2 2 0 00-2-2H3a2 2 0 00-2 2v10a2 2 0 002 2z"
                                stroke={color}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </Svg>
                    ),
                    tabBarLabel: i18n.t('navigation.contact')
                }}
            />
            <BottomTab.Screen
                name="More"
                component={MoreNavigator}
                initialParams={initialParams}
                options={{
                    tabBarIcon: ({color}) => (
                        <Svg
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                            style={{width: 18, height: 18}}
                        >
                            <G
                                stroke={color}
                                strokeLinejoin="round"
                                strokeWidth="3"
                                clipPath="url(#a)"
                            >
                                <Path d="M12.01 12v.01H12V12zM19.01 12v.01H19V12zM5.01 12v.01H5V12z" />
                            </G>
                            <Defs>
                                <ClipPath id="a">
                                    <Path fill="#fff" d="M0 .001h24v24H0z" />
                                </ClipPath>
                            </Defs>
                        </Svg>
                    ),
                    tabBarLabel: i18n.t('navigation.more')
                }}
            />
        </BottomTab.Navigator>
    );
};

export default BottomTabNavigator;
