import React, {ReactNode} from 'react';
import {Ionicons} from '@expo/vector-icons';
import type {LanguageDirection} from '@/types/languages';
import * as S from './styles';

interface Props {
    children: ReactNode;
    direction?: LanguageDirection;
    onPress?: Function;
    isActive?: boolean;
}

const Label: React.FC<Props> = ({children, direction}: Props) => (
    <S.Label direction={direction}>{children}</S.Label>
);

const RadioButton: React.FC<Props> = ({children, onPress, isActive}: Props) => (
    <S.Container isActive={isActive} onPress={() => onPress()}>
        {children}
    </S.Container>
);

export default {
    Container: RadioButton,
    Label
};
