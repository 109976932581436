import {boolean, object} from 'yup';

const schema = object().shape({
    enabled: boolean().required('empty')
});

export const fields = {
    fields: {
        enabled: {field: 'input', options: [], type: 'radio'}
    }
};

export default {fields, schema};
