import React from 'react';
import {Platform, View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import i18n from 'i18n-js';
import {Formik} from 'formik';
import omit from 'lodash/omit';
import {toast} from 'react-hot-toast/src/core/toast';
import Button from '@/components/Button';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import Panel from '@/components/Panel';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import StickyFooter from '@/components/StickyFooter';
import TextButton from '@/components/TextButton';
import * as UI from '@/components/UI/styles';
import useAuth from '@/hooks/useAuth';
import useForm from '@/hooks/useForm';
import useSettings from '@/hooks/useSettings';
import userAccountSchema from '@/schemas/user-account';
import type {RootStackParamList} from '@/types/navigation';
import type {ApiResponse} from '@/types/api';
import type {AuthUser} from '@/types/auth';
import {logEvent} from '@/utils/analytics';
import RequestType from '@/types/request';

type Role = AuthUser['role'];
type SettingsScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'SettingsAccount'
>;
interface Props {
    navigation: SettingsScreenNavigationProp;
}

const getFieldOptions = (settings, values) => {
    const options = {
        country_of_residence: [],
        disability_status: [],
        gender_id: [],
        state_of_residence: [],
        role: []
    };
    const fieldAndSettingsKeys = [
        ['country_of_residence', 'countries'],
        ['disability_status', 'disability_statuses'],
        ['gender_id', 'genders'],
        ['role', 'roles']
    ];
    const countryAndStateKeys = ['residence'];

    fieldAndSettingsKeys.forEach(([fieldKey, settingsKey]) => {
        if (settings[settingsKey]) {
            options[fieldKey] = settings[settingsKey].map(i => ({
                label: i.name,
                value: i.id
            }));
        }
    });
    countryAndStateKeys.forEach(key => {
        if (values[`country_of_${key}`]) {
            const country = settings.countries.find(
                i => i.id === parseInt(values[`country_of_${key}`])
            );

            if (country?.states?.length > 0) {
                options[`state_of_${key}`] = country.states.map(i => ({
                    label: i.name,
                    value: i.id
                }));
            }
        }
    });

    return options;
};

const SettingsAccountScreen: React.FC<Props> = ({navigation}: Props) => {
    const {authUser, updateAuthUser} = useAuth();
    const form = useForm(true);
    const {settings} = useSettings();

    /**
     * TODO: Disparity between the API's GET/POST field names
     */
    // @ts-ignore
    const authUserRole = authUser.role?.id || authUser.role;
    const initialValues = {
        ...omit(authUser, [
            'country_of_residence_id',
            'created',
            'disability_statuses',
            'gender',
            'has_completed_profile',
            'has_done_walkthrough',
            'id',
            'is_active',
            'modified',
            'password',
            'state_of_residence_id',
            'username'
        ]),
        ...{
            classroom_size: authUser.classroom_size || 0,
            country_of_residence: authUser.country_of_residence_id || '',
            disability_status:
                authUser.disability_statuses?.map(i => i.id) || '',
            family_size: authUser.family_size || 0,
            gender_id: authUser.gender.id,
            phone: authUser.phone,
            role: authUserRole,
            state_of_residence: authUser.state_of_residence_id || '',
            // @deprecated
            school_name: 'N/A'
        }
    };

    const handlePrivacyCtaPress = async () => {
        await logEvent('account_settings_privacy_prompt_clicked');

        if (Platform.OS === 'web') {
            window.open(i18n.t('signUp.privacyUrl'), '_blank');
        } else {
            Linking.openURL(i18n.t('signUp.privacyUrl'));
        }
    };

    const handleFormError = () => {};

    const handleFormSuccess = async (response: ApiResponse<AuthUser>) => {
        if (response.data) {
            toast(i18n.t('settingsAccount.updated'), {
                icon: 'check',
                id: 'settings-account-updated'
            });
            updateAuthUser({
                ...authUser,
                ...response.data
            });
            await logEvent('user_account_updated');

            navigation.goBack();
        } else {
            /**
             * TODO: Handle unexpected response?
             */
        }
    };

    const handleFormSubmit = formValues => {
        if (form.status === RequestType.PENDING) {
            return;
        }

        form.setStatus(RequestType.PENDING);

        const values = {...formValues};
        values.classroom_size = values.classroom_size || '0';
        values.family_size = values.family_size || '0';

        form.submit({
            onError: handleFormError,
            onSuccess: response => handleFormSuccess(response),
            request: {
                config: {body: values},
                url: `/api/user/update/`
            }
        });
    };

    return (
        <Screen>
            <ScreenHeader theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero
                    subtitle={i18n.t('settings.title')}
                    title={i18n.t('settingsAccount.title')}
                />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <Panel
                        type="warning"
                        title={i18n.t('settingsAccount.privacyTitle')}
                    >
                        <UI.Text size="small">
                            {i18n.t('settingsAccount.privacyText')}
                        </UI.Text>
                        <UI.Spacer size="sm" />
                        <TextButton onPress={handlePrivacyCtaPress}>
                            {i18n.t('settingsAccount.privacyCta')}
                        </TextButton>
                    </Panel>
                    <View className="h-8" />
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validateOnBlur={true}
                        validateOnChange={false}
                        validationSchema={userAccountSchema.schema}
                    >
                        {({handleSubmit, values}) => (
                            <FormBuilder
                                errors={form.errors}
                                form={userAccountSchema.fields}
                                handleSubmit={handleSubmit}
                                name="settingsAccount"
                                options={getFieldOptions(settings, values)}
                                status={form.status}
                                submitLabel={i18n.t(
                                    'settingsAccount.form.submit'
                                )}
                            />
                        )}
                    </Formik>
                </View>
            </View>
        </Screen>
    );
};

export default SettingsAccountScreen;
