import {boolean, object, string} from 'yup';

const schema = object().shape({
    recommendation_grade: string().required('empty'),
    problems: string().required('empty'),
    willing_to_share_more: string().required('empty'),
    feedback: string().when('willing_to_share_more', {
        is: true,
        then: string().required('empty')
    })
});

export const fields = {
    fields: {
        recommendation_grade: {field: 'select', options: []},
        problems: {field: 'select', options: []},
        willing_to_share_more: {field: 'select', options: []},
        feedback: {
            field: 'textarea',
            hidden: [
                ['willing_to_share_more', false],
                ['willing_to_share_more', 'false'],
                ['willing_to_share_more', '']
            ],
            rows: 2
        }
    }
};

export default {fields, schema};
