import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import i18n from 'i18n-js';
import NetInfo from '@react-native-community/netinfo';
import {Formik} from 'formik';
import {toast} from 'react-hot-toast/src/core/toast';
import Button from '@/components/Button';
import Conditional from '@/components/Conditional';
import FormBuilder from '@/components/Form';
import Panel from '@/components/Panel';
import StickyFooter from '@/components/StickyFooter';
import * as UI from '@/components/UI/styles';
import useForm from '@/hooks/useForm';
import useOffline from '@/hooks/useOffline';
import videoDownloadSchema from '@/schemas/video-download';
import type {FormFieldOptions} from '@/types/form';
import RequestType, {ConnectionStatus} from '@/types/request';
import {VideoQualities} from '@/types/video';
import {logEvent} from '@/utils/analytics';
import {getConnectionStatus} from '@/utils/helpers';

interface Props {
    handleClose: Function;
    id: number;
    qualities: VideoQualities;
}

const VideoDownloadModal: React.FC<Props> = ({
    handleClose,
    id,
    qualities
}: Props) => {
    const form = useForm();
    const {downloadVideo} = useOffline();
    const [status, setStatus] = useState<RequestType>(RequestType.DEFAULT);
    const [qualityOptions, setQualityOptions] =
        useState<FormFieldOptions[]>(null);
    const [downloadProgress, setDownloadProgress] = useState<number>(null);
    const [networkStatus, setNetworkStatus] = useState<ConnectionStatus>(null);

    const handleDownloadProgress = (
        type: 'error' | 'progress' | 'success',
        response
    ) => {
        if (type === 'progress') {
            const progress = Math.ceil(
                (response.totalBytesWritten /
                    response.totalBytesExpectedToWrite) *
                    100
            );

            setStatus(RequestType.PENDING);
            setDownloadProgress(progress);
        } else if (type === 'success') {
            setStatus(RequestType.SUCCESS);

            setTimeout(async () => {
                toast(i18n.t('videoDownload.complete'), {
                    icon: 'check',
                    id: 'download-complete'
                });
                handleClose();

                if (id) {
                    await logEvent('video_download_saved', {courseId: id});
                }
            }, 500);
        } else {
            toast(`${i18n.t('generic.apiErrorTitle')}: ${response?.message}`, {
                icon: 'error',
                id: 'download-progress-error'
            });
            setStatus(RequestType.DEFAULT);
        }
    };

    const handleFormSubmit = values => {
        setStatus(RequestType.PENDING);
        downloadVideo(qualities[values.quality], id, handleDownloadProgress);
    };

    const getQualityOptions = () => {
        const availableQualities = Object.keys(qualities).filter(
            qualityKey => qualities[qualityKey]
        );
        const options: FormFieldOptions[] = i18n.t(
            'videoDownload.form.quality_options'
        );
        const filteredOptions = options.filter(i =>
            availableQualities.includes(i.value.toString())
        );

        setQualityOptions(filteredOptions);
    };

    useEffect(() => {
        if (qualities && Object.keys(qualities).length > 0) {
            getQualityOptions();
        }
    }, [qualities]);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(network => {
            setNetworkStatus(getConnectionStatus(network));
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const showForm =
        status === RequestType.DEFAULT &&
        qualityOptions &&
        qualities &&
        Object.keys(qualities).length > 1;

    return (
        <Formik
            initialValues={{quality: 'low'}}
            onSubmit={handleFormSubmit}
            validationSchema={videoDownloadSchema.schema}
        >
            {({handleSubmit}) => (
                <>
                    <UI.ScreenScrollView>
                        <UI.Box size="section">
                            <UI.Heading size="h4">
                                {i18n.t('videoDownload.title')}
                            </UI.Heading>
                            <UI.Spacer size="sm" />
                            <Conditional
                                all={[networkStatus !== ConnectionStatus.NONE]}
                            >
                                <UI.Text>
                                    {i18n.t('videoDownload.text')}
                                </UI.Text>
                                <UI.Spacer size="lg" />
                                {status === RequestType.PENDING ? (
                                    <Panel>
                                        <UI.Text>
                                            {i18n.t(
                                                'videoDownload.downloading',
                                                {
                                                    progress: `${
                                                        downloadProgress || 0
                                                    }%`
                                                }
                                            )}
                                        </UI.Text>
                                        <UI.Spacer size="sm" />
                                        <UI.Text medium size="small">
                                            {i18n.t(
                                                'videoDownload.downloadingWarning'
                                            )}
                                        </UI.Text>
                                    </Panel>
                                ) : (
                                    <View>
                                        {showForm && (
                                            <FormBuilder
                                                errors={form.errors}
                                                form={
                                                    videoDownloadSchema.fields
                                                }
                                                handleSubmit={handleSubmit}
                                                hasSubmitButton={false}
                                                key={status}
                                                name="videoDownload"
                                                options={{
                                                    quality: qualityOptions
                                                }}
                                                status={form.status}
                                            />
                                        )}
                                    </View>
                                )}
                            </Conditional>
                            <Conditional
                                all={[networkStatus === ConnectionStatus.NONE]}
                            >
                                <UI.Spacer size="lg" />
                                <Panel type="warning">
                                    <UI.Text medium>
                                        {i18n.t('generic.offlineQueue')}
                                    </UI.Text>
                                </Panel>
                            </Conditional>
                        </UI.Box>
                    </UI.ScreenScrollView>
                    <Conditional
                        all={[networkStatus !== ConnectionStatus.NONE]}
                    >
                        <StickyFooter>
                            <Button
                                isDisabled={status !== RequestType.DEFAULT}
                                label={i18n.t('videoDownload.submitCta')}
                                onPress={handleSubmit}
                                status={status}
                            />
                        </StickyFooter>
                    </Conditional>
                </>
            )}
        </Formik>
    );
};

export default VideoDownloadModal;
