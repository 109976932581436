import React, {ReactNode} from 'react';
import {Text, View} from 'react-native';

interface Props {
    children?: ReactNode;
    subtitle?: string;
    text?: string;
    title?: string;
}

const ScreenHero: React.FC<Props> = ({
    children,
    subtitle,
    text,
    title
}: Props) => (
    <View className="relative">
        <View className="flex bg-tertiary pt-4 pb-12">
            <View className="flex px-4 mx-auto w-full max-w-2xl space-y-3">
                {subtitle && (
                    <Text className="text-md -mb-2 font-medium text-blp-grey-mid">
                        {subtitle}
                    </Text>
                )}
                {title && (
                    <Text className="text-5xl font-medium leading-10">
                        {title}
                    </Text>
                )}
                {text && (
                    <Text className="font-default leading-[22px] text-md">
                        {text}
                    </Text>
                )}
                {children && <View className="flex">{children}</View>}
            </View>
        </View>
        <View className="h-[30px] w-full bottom-[-15px] left-0 absolute rounded-t-2xl bg-white" />
    </View>
);

export default ScreenHero;
