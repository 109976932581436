import React from 'react';
import {Text, View} from 'react-native';
import i18n from 'i18n-js';
import Svg, {Path} from 'react-native-svg';
import Button from '@/components/Button';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';

const ForumScreen: React.FC = () => (
    <Screen
        footer={
            <Button url={i18n.t('forum.ctaUrl')}>
                <View className="flex flex-row flex-grow justify-center">
                    <Svg
                        width="36"
                        height="36"
                        fill="none"
                        viewBox="0 0 36 36"
                        style={{
                            width: 30,
                            marginBottom: -6,
                            marginTop: -6,
                            height: 30
                        }}
                    >
                        <Path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M27 19.5v9a3 3 0 0 1-3 3H7.5a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h9M22.5 4.5h9v9M15 21 31.5 4.5"
                        />
                    </Svg>
                </View>
            </Button>
        }
        footerSize="oneCta"
    >
        <ScreenHeader theme="tertiary" />
        <ScreenHero title={i18n.t('forum.title')} />
        <View className="flex-1 mx-auto w-full max-w-2xl">
            <View className="p-4 space-y-3">
                <Text className="font-default leading-6 text-md">
                    {i18n.t('forum.text')}
                </Text>
            </View>
        </View>
    </Screen>
);

export default ForumScreen;
