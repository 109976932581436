import React, {useEffect} from 'react';
import {View} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import i18n from 'i18n-js';
import Svg, {Path} from 'react-native-svg';
import Button from '@/components/Button';
import NewLearningContentCard from '@/components/NewLearningContentCard';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import type {RootStackParamList} from '@/types/navigation';
import * as UI from '@/components/UI/styles';

type Props = StackScreenProps<RootStackParamList, 'LearningContentUpdates'>;

const LearningContentUpdatesScreen: React.FC<Props> = ({
    navigation,
    route
}: Props) => {
    const courses = route.params?.courses;
    const modules = route.params?.modules;

    useEffect(() => {
        if (!courses || !modules || (!courses.length && !modules.length)) {
            navigation.replace('Learning');
        }
    }, []);

    return (
        <Screen
            footer={
                <Button
                    // accessibilityLabel={i18n.t('learningContentUpdates.cta')}
                    onPress={() => navigation.push('Learning')}
                >
                    <Svg width="30" height="27" fill="none">
                        <Path
                            d="m15 16 13.5-7.5L15 1 1.5 8.5 15 16Zm0 0 9.239-5.133A17.954 17.954 0 0 1 25.5 17.5c0 1.052-.09 2.083-.263 3.085A17.93 17.93 0 0 0 15 25.083a17.93 17.93 0 0 0-10.237-4.498A18.116 18.116 0 0 1 4.5 17.5c0-2.342.447-4.58 1.261-6.633L15 16Zm-6 9V13.75l6-3.333"
                            stroke="#000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </Svg>
                </Button>
            }
            footerSize="oneCta"
        >
            <ScreenHeader hasBackButton={false} theme="tertiary" />
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4 space-y-3">
                    <View>
                        <Svg width="52" height="50" fill="none">
                            <Path
                                d="M47.667 43.616c0 3.178-2.6 5.778-5.778 5.778H10.111c-3.178 0-5.778-2.6-5.778-5.778V19.061c0-3.178 2.6-5.778 5.778-5.778H41.89c3.178 0 5.778 2.6 5.778 5.778v24.555Z"
                                fill="#FDD888"
                            />
                            <Path
                                d="M52 14.727c0 3.178-2.6 5.778-5.778 5.778H5.778C2.6 20.505 0 17.905 0 14.727 0 11.55 2.6 8.95 5.778 8.95h40.444C49.4 8.95 52 11.55 52 14.727Z"
                                fill="#FDD888"
                            />
                            <Path
                                d="M4.333 20.505h43.334v2.889H4.333v-2.889Z"
                                fill="#FCAB40"
                            />
                            <Path
                                d="M27.444 3.172h-2.889a4.333 4.333 0 0 0-4.333 4.333v41.89h11.556V7.504a4.334 4.334 0 0 0-4.334-4.333Z"
                                fill="#DA2F47"
                            />
                            <Path
                                d="M23.111 8.95c1.589 0 1.824-.746.522-1.657L13.923.495c-1.303-.911-3.012-.528-3.8.851L7.21 6.442c-.789 1.38-.133 2.508 1.456 2.508H23.11Zm5.778 0c-1.589 0-1.824-.746-.522-1.657L38.078.495c1.302-.911 3.012-.528 3.8.851l2.913 5.095c.787 1.38.131 2.509-1.458 2.509H28.89Z"
                                fill="#DA2F47"
                            />
                        </Svg>
                    </View>
                    <View>
                        <UI.Heading size="h1">
                            {i18n.t('learningContentUpdates.title')}
                        </UI.Heading>
                    </View>
                    <View>
                        <UI.Text>
                            {i18n.t('learningContentUpdates.text')}
                        </UI.Text>
                    </View>
                </View>

                {(modules || courses) && (
                    <View className="mt-4 space-y-3">
                        {modules.map(module => (
                            <NewLearningContentCard
                                key={module.id}
                                id={module.id}
                                title={module.title}
                                type="module"
                            />
                        ))}
                        {courses.map(course => (
                            <View key={course.id}>
                                <NewLearningContentCard
                                    id={course.module_id}
                                    title={course.module_title}
                                    type="course"
                                />
                            </View>
                        ))}
                    </View>
                )}
            </View>
        </Screen>
    );
};

export default LearningContentUpdatesScreen;
