import {Dimensions, Platform} from 'react-native';
import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {colors, spacing} from '@/utils/constants';

const window = Dimensions.get('window');
const videoHeight = (window.width / 100) * 56.25;

export const videoAnimatedWrapper = css`
    align-items: center;
    background-color: ${colors.orangeLight};
    height: ${px(videoHeight)};
    justify-content: center;
    width: 100%;

    ${Platform.OS === 'web' &&
    `height: 0; padding-bottom: 56.25%; width: 100%;`}
`;

export const video = css`
    background-color: ${colors.orangeLight};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    ${Platform.OS === 'web' && 'height: 0; padding-bottom: 56.25%;'}
`;

export const Button = styled.TouchableOpacity`
    padding: ${px(spacing.md * 3)};

    ${Platform.OS === 'web' &&
    'margin-top: -100px; position: absolute; top: 50%;'}
`;

export const playButton = css`
    border-radius: 54px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
    height: 54px;
    width: 54px;
`;

export const VideoContainer = styled.View`
    background-color: ${colors.orangeLight};
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
`;

export const icon = css`
    color: ${colors.black};
    height: 12px;
    width: 14px;
`;
