import React, {ReactElement, ReactNode} from 'react';
import {mapChildren} from '@/utils/helpers';
import type {Spacing} from '@/utils/constants';
import * as S from './styles';

interface Props {
    children: ReactNode;
    columns?: number;
    direction?: 'horizontal' | 'vertical';
    size?: Spacing;
}

const renderGridItem: React.FC<Props> = (
    child: ReactElement,
    index: number,
    direction: Props['direction'],
    columns: number,
    size: Spacing
) => {
    const isFirst =
        direction === 'horizontal' ? index % columns === 0 : index === 0;

    return (
        <S.Item
            key={`${direction}-${index}-${isFirst}-${size}`}
            direction={direction}
            isFirst={isFirst}
            size={size}
        >
            {child}
        </S.Item>
    );
};

const Grid = ({
    children,
    columns = 2,
    direction = 'horizontal',
    size = 'md'
}: Props) => {
    return (
        <S.Container direction={direction}>
            {mapChildren(children, renderGridItem, direction, columns, size)}
        </S.Container>
    );
};

export default Grid;
