import {NativeModules, Platform} from 'react-native';
import styled from '@emotion/native';
import {
    colors,
    fontFamilies,
    fontSizes,
    spacing,
    themes
} from '@/utils/constants';
import type {Color, FontSize, Spacing, Theme} from '@/utils/constants';
import {hasNotch} from '@/utils/devices-with-notich';
import {px} from '@/utils/helpers';

const {StatusBarManager} = NativeModules;

// Typography

export const BoldHeading = styled.Text<{
    size?: FontSize;
}>`
    color: ${colors.greyDark};
    font-family: ${fontFamilies.medium};
    font-size: ${props => (props.size ? px(fontSizes[props.size]) : px(16))};
    font-weight: 700;

    ${props => props.theme.direction === 'rtl' && 'text-align: left;'}
`;

export const Heading = styled.Text<{
    center?: boolean;
    color?: Color;
    size?: FontSize;
    theme?: Theme;
}>`
    color: ${props => themes[props.theme]?.text};
    font-family: ${fontFamilies.heading};
    font-size: ${props =>
        props.size ? px(fontSizes[props.size]) : px(fontSizes.h1)};
    font-weight: 500;
    line-height: ${props =>
        props.size ? px(fontSizes[props.size] * 1.3) : px(fontSizes.h1 * 1.3)};

    ${props => props.color && `color: ${colors[props.color]};`}
    ${props => props.theme.direction === 'rtl' && 'text-align: left;'}
        ${props => props.center && 'text-align: center;'}
`;

export const Text = styled.Text<{
    medium?: boolean;
    center?: boolean;
    size?: FontSize;
    theme?: Theme;
}>`
    color: ${props => themes[props.theme]?.text};
    font-family: ${fontFamilies.default};
    font-size: ${props =>
        props.size ? px(fontSizes[props.size]) : px(fontSizes.default)};
    line-height: ${props =>
        props.size
            ? px(fontSizes[props.size] * 1.4)
            : px(fontSizes.default * 1.5)};

    ${props => (props.medium ? `font-family: ${fontFamilies.medium};` : '')}
    ${props => props.theme.direction === 'rtl' && 'text-align: left;'}
    ${props => props.center && 'text-align: center;'}
`;

// Layout

export const Box = styled.View<{
    background?: Color;
    size?: Spacing;
    sizeHorizontal?: Spacing;
    sizeVertical?: Spacing;
}>`
    padding: ${props =>
        props.size ? px(spacing[props.size]) : px(spacing.md)};

    ${props =>
        props.background && `background-color: ${colors[props.background]};`}
    ${props =>
        props.sizeHorizontal &&
        `
        padding-left: ${px(spacing[props.sizeHorizontal])};
        padding-right: ${px(spacing[props.sizeHorizontal])};
    `};
    ${props =>
        props.sizeVertical &&
        `
        padding-bottom: ${px(spacing[props.sizeVertical])};
        padding-top: ${px(spacing[props.sizeVertical])};
    `};
`;

export const CenteredFullHeightContainer = styled.View`
    flex: 1;
    justify-content: center;
    width: 100%;
`;

export const Divider = styled.View`
    border-color: ${colors.greyLight};
    border-width: 2px;
    margin: ${px(spacing.md * 1.5)};
    opacity: 0.25;
`;

export const SafeAreaView = styled.SafeAreaView<{
    theme?: Theme;
}>`
    background-color: ${props => themes[props.theme]?.background};
    flex: 1;
    z-index: 2;

    ${Platform.OS === 'android' && `padding-top: ${StatusBarManager.HEIGHT}px;`}
`;

export const ScreenScrollView = styled.ScrollView<{
    background?: Color;
}>`
    ${props =>
        props.background && `background-color: ${colors[props.background]};`}
    flex: 1;
`;

export const Spacer = styled.View<{
    size?: Spacing;
    type?: 'height' | 'width';
}>`
    ${props => props?.type || 'height'}: ${props =>
        props.size ? px(spacing[props.size]) : px(spacing.md)};
`;

export const View = styled.View<{
    contained?: boolean;
    flex?: number;
    theme?: Theme;
}>`
    ${props =>
        props?.theme
            ? `background-color: ${themes[props.theme]?.background};`
            : ''}
    ${props => (props?.flex > 0 ? `flex: ${props.flex};` : '')}
    ${Platform.OS === 'web' && 'flex-basis: auto;'}
    ${props =>
        Platform.OS === 'web' &&
        props.contained &&
        'margin-left: auto; margin-right: auto; max-width: 740px; width: 100%;'}
`;
