import type {ResourceItem} from './resource';

export interface AllLearningContent {
    courses: LearningCourse[];
    lastFetched: number;
    modules: LearningModule[];
    progress: {
        currentCourseId: number;
        progress: string;
    };
}

export interface LearningCourse {
    description: string;
    high_quality_video: string;
    is_done: boolean;
    id: number;
    instructions: string;
    is_locked: boolean;
    low_quality_video: string;
    medium_quality_video: string;
    module_id: number;
    module_title?: string;
    resources?: ResourceItem[];
    position: number;
    title: string;
    video_length: number;
}

export interface LearningModule {
    id: number;
    is_done: boolean;
    is_locked: boolean;
    position: number;
    progression: string;
    questions?: LearningContentAssessmentQuestion[];
    title: string;
}

export enum LearningProgressState {
    LOCKED = 'locked',
    NOT_STARTED = 'not_started',
    STARTED = 'started',
    COMPLETE = 'complete'
}

export enum LearningProgressStateTheme {
    'locked' = 'grey',
    'not_started' = 'grey',
    'started' = 'yellow',
    'complete' = 'blue'
}

export interface LearningContentAssessmentQuestion {
    answers: {
        id: number;
        text: string;
    }[];
    id: number;
    is_multiple?: boolean;
    text: string;
}
