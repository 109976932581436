import React, {useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import {useRecoilValue} from 'recoil';
import i18n from 'i18n-js';
import {Formik} from 'formik';
import {toast} from 'react-hot-toast/src/core/toast';
import ApiView from '@/components/ApiView';
import Button from '@/components/Button';
import FadeInUp from '@/components/FadeInUp';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import ProgressBar from '@/components/ProgressBar';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import StickyFooter from '@/components/StickyFooter';
import * as UI from '@/components/UI/styles';
import useAuth from '@/hooks/useAuth';
import useForm from '@/hooks/useForm';
import useLearningContent from '@/hooks/useLearningContent';
import type {
    LearningModule,
    LearningContentAssessmentQuestion
} from '@/types/learning';
import type {RootStackParamList} from '@/types/navigation';
import {RequestType} from '@/types/request';
import fetch, {fetchOptions} from '@/utils/fetch';
import {getDefaultFieldValues} from '@/utils/helpers';
import {languageState} from '@/utils/store';

type Props = StackScreenProps<RootStackParamList, 'LearningModuleAssessment'>;

const LearningModuleAssessmentScreen: React.FC<Props> = ({
    route,
    navigation
}: Props) => {
    const {moduleId, title} = route.params;
    const language = useRecoilValue(languageState);
    const {getAuthToken} = useAuth();
    const form = useForm(true);
    const {getModule, unlockNextModule} = useLearningContent();
    const module = getModule(moduleId)?.[0] as LearningModule;
    const [questions, setQuestions] = useState<
        LearningContentAssessmentQuestion[]
    >(module?.questions);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<object>(null);
    const [initialFormValues, setInitialFormValues] = useState<{
        [key: string]: any;
    }>({});

    const fetchQuestions = async () => {
        try {
            const token = await getAuthToken();
            const response = await fetch(
                `${process.env.API_URL}/api/modules/${moduleId}/questions/`,
                fetchOptions({}, language, token)
            );

            if (response?.data?.length > 0) {
                setQuestions(response.data);
                setInitialFormValues(getDefaultFieldValues(response.data));
            } else {
                toast(i18n.t('learning.assessmentNoQuestions'), {
                    icon: 'error',
                    id: `no-questions`
                });
                navigation.navigate('LearningModule', {moduleId, title});
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleFormError = error => {
        form.setStatus(RequestType.DEFAULT);
        alert(error?.message || error);
    };

    const handleFormSuccess = () => {
        form.setStatus(RequestType.SUCCESS);
        unlockNextModule(moduleId);
        navigation.replace('LearningModuleAssessmentComplete');
    };

    const handleFinalSubmit = async formValues => {
        if (form.status === RequestType.PENDING) {
            return;
        }

        form.setStatus(RequestType.PENDING);

        /**
         * Single-choice fields must be converted to arrays before submitting.
         */
        const values = Object.keys(formValues).reduce((acc, cur) => {
            return {
                ...acc,
                [cur]: Array.isArray(formValues[cur])
                    ? formValues[cur]
                    : [formValues[cur]]
            };
        }, {});

        /**
         * Transform our index-keyed questions back to id-keyed.
         */
        const answers = Object.keys(values).map((_, index) => ({
            question: questions[index].id,
            answers: values[index]
        }));

        form.submit({
            onError: handleFormError,
            onSuccess: handleFormSuccess,
            request: {
                config: {body: {answers}},
                queueOffline: true,
                url: `/api/modules/${moduleId}/submit/`
            }
        });
    };

    const handleFormSubmit = values => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            handleFinalSubmit(values);
        }
    };

    useEffect(() => {
        if (questions?.length) {
            const question = questions[currentQuestionIndex];
            const fields = {
                [currentQuestionIndex]: {
                    errors: {
                        empty: 'Please select an answer'
                    },
                    field: 'input',
                    options: question.answers.map(answer => ({
                        label: answer.text,
                        value: answer.id
                    })),
                    type: question?.is_multiple ? 'checkbox' : 'radio'
                }
            };

            setCurrentQuestion({fields});
        }
    }, [questions, currentQuestionIndex]);

    useEffect(() => {
        if (module) {
            if (!questions?.length || !module.questions?.length) {
                fetchQuestions();
            }
        }
    }, [module]);

    if (!questions.length) {
        return null;
    }

    return (
        <Screen>
            <ScreenHeader
                title={i18n.t('learning.assessmentTitle', {
                    module: title
                })}
                text={
                    questions?.length
                        ? `${currentQuestionIndex + 1}/${questions.length}`
                        : '-/-'
                }
            />
            {questions?.length && (
                <ProgressBar
                    progress={
                        currentQuestionIndex === questions.length
                            ? 100
                            : (currentQuestionIndex / questions.length) * 100
                    }
                />
            )}
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <Formik
                        initialValues={initialFormValues}
                        onSubmit={handleFormSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {({handleSubmit}) => (
                            <ApiView
                                isEmpty={!currentQuestion}
                                isLoading={!questions}
                            >
                                <Text className="font-default my-4 text-xl leading-7">
                                    {questions[currentQuestionIndex].text}
                                </Text>
                                {currentQuestion && (
                                    <FormBuilder
                                        errors={form.errors}
                                        form={currentQuestion}
                                        handleSubmit={handleSubmit}
                                        name="assessment"
                                        status={form.status}
                                        submitLabel={i18n.t(
                                            'settingsAccount.submitCta'
                                        )}
                                    />
                                )}
                            </ApiView>
                        )}
                    </Formik>
                </View>
            </View>
        </Screen>
    );
};

export default LearningModuleAssessmentScreen;
