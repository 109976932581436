export enum RequestType {
    DEFAULT,
    PENDING,
    ERROR,
    SUCCESS
}

export default RequestType;

export interface OfflineQueueItem {
    options: object;
    url: string;
}

export enum ConnectionStatus {
    NONE = 'none',
    LOW = 'low',
    GOOD = 'good'
}
