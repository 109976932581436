import React from 'react';
import {View, Text} from 'react-native';
import map from 'lodash/map';
import Panel from '@/components/Panel';
import type {Form} from '@/types/form';

interface Props {
    error?: any;
    fields: Form['fields'] | Form['sections'];
}

const FormErrors: React.FC<Props> = ({error}: Props) => {
    if (!error?.message && error?.errors?.length === 0) {
        return null;
    }

    return (
        <View className="mt-4">
            <Panel type="error" title={error.message}>
                {error?.errors?.length > 0 && (
                    <View className="flex flex-shrink-0 -mb-1">
                        {map(error.errors, (message, index) => (
                            <View
                                key={`${message}-${index}`}
                                className="flex flex-row mb-1"
                            >
                                <View className="w-4">
                                    <View className="bg-blp-red rounded-full h-1.5 flex-shrink-0 w-1.5 mt-[6px]" />
                                </View>
                                <View className="flex-row flex flex-shrink flex-grow">
                                    <Text className="flex-wrap text-blp-red">
                                        {message}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                )}
            </Panel>
        </View>
    );
};

export default FormErrors;
