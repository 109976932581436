import * as Linking from 'expo-linking';

export default {
    prefixes: [Linking.createURL('/')],
    config: {
        screens: {
            Root: {
                screens: {
                    Learning: {
                        screens: {
                            LearningScreen: 'learning'
                        }
                    },
                    Settings: {
                        screens: {
                            SettingsScreen: 'settings'
                        }
                    }
                }
            },
            NotFound: '*'
        }
    }
};
