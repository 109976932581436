import React, {useEffect, useState} from 'react';
import {
    ActivityIndicator,
    Platform,
    Text,
    TouchableOpacity,
    View
} from 'react-native';
import * as Application from 'expo-application';
import {StackNavigationProp} from '@react-navigation/stack';
import i18n from 'i18n-js';
import * as Localization from 'expo-localization';
import Svg, {Path} from 'react-native-svg';
import {useRecoilState, useRecoilValue} from 'recoil';
import head from 'lodash/head';
import split from 'lodash/split';
import Button from '@/components/Button';
import LanguageSelector from '@/components/LanguageSelector';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import TextButton from '@/components/TextButton';
import * as UI from '@/components/UI/styles';
import useLanguage from '@/hooks/useLanguage';
import type {AuthUser} from '@/types/auth';
import type {RootStackParamList} from '@/types/navigation';
import {languageState, languageScreenNextScreenState} from '@/utils/store';

type Language = AuthUser['preferred_language'];
type LanguageSelectionScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'LanguageSelection'
>;
interface Props {
    navigation: LanguageSelectionScreenNavigationProp;
}

const LanguageSelectionScreen: React.FC<Props> = ({navigation}: Props) => {
    const {language, setAppLanguage} = useLanguage();
    const defaultLanguage = useRecoilValue<Language>(languageState);
    const [newLanguage, setNewLanguage] = useState<Language>(
        defaultLanguage || (head(split(Localization.locale, '-')) as Language)
    );
    const [languageScreenNextScreen, setLanguageScreenNextScreen] =
        useRecoilState(languageScreenNextScreenState);
    const [isRefreshPending, setIsRefreshPending] = useState<boolean>(false);

    const showCurrentAppVersion = () => {
        const version = `v${Application?.nativeApplicationVersion} (${Application?.nativeBuildVersion},1)`;
        alert(version);
    };

    const handleSelection = (language: Language) => {
        setNewLanguage(language);
    };

    /**
     * Text align will flip if we're viewing RTL so we need to know how to align
     * based on current LTR/RTL view.
     */
    const getTextAlignment = () => {
        if (newLanguage === 'ar') {
            return language !== 'ar' ? 'right' : 'left';
        } else {
            return language === 'ar' ? 'right' : 'left';
        }
    };

    const handleContinue = (nextScreen: 'SignUpOnboarding' | 'SignIn') => {
        /**
         * We use the language as a key on the parent `App` component. This forces
         * the app screens to "refresh" when the language changes, otherwise there's
         * lots of screen caching going on. This means that when we select a _different_
         * language and then continue it will actually cause the app to refresh and
         * we'll return to this screen again (as it's the first in the stack).
         *
         * To get around this, _if the user changes from the default language_ we will
         * store the new language in Recoil so then when this screen is refreshed we will
         * know where the user was supposed to navigate to next and we can take them there
         * immediately.
         */
        if (newLanguage !== defaultLanguage) {
            setAppLanguage(newLanguage);

            // TODO: This is a hack-y workaround to fix language selection crashing when
            // selecting a language with the same direction as the current language.
            // If the new language is AR, or the previous language was AR, and the language has changed
            // we need to refresh the app.
            if (
                (newLanguage === 'ar' || language === 'ar') &&
                newLanguage !== language
            ) {
                setLanguageScreenNextScreen(nextScreen);
                setIsRefreshPending(true);
            } else {
                navigation.push(nextScreen);
            }
        } else {
            navigation.push(nextScreen);
        }
    };

    useEffect(() => {
        /**
         * If a new language has been selected `isRefreshPending` will be true until the
         * app refreshes. In this case, we want to wait for the next screen to be confirmed
         * before we navigate.
         */
        if (languageScreenNextScreen) {
            if (!isRefreshPending || Platform.OS === 'web') {
                navigation.push(languageScreenNextScreen);
            }
        }
    }, [languageScreenNextScreen]);

    /**
     * If the screen has been refreshed because of a language change, show some
     * feedback instead of the same screen in the new language.
     */
    if (languageScreenNextScreen) {
        return (
            <UI.SafeAreaView
                theme="tertiary"
                style={{justifyContent: 'center'}}
            >
                <ActivityIndicator />
            </UI.SafeAreaView>
        );
    }

    return (
        <Screen
            footer={
                <View className="flex items-center">
                    <TextButton onPress={() => handleContinue('SignIn')}>
                        {i18n.t('languageSelection.signIn', {
                            locale: newLanguage
                        })}
                    </TextButton>
                    <UI.Spacer size="sm" />
                    <Button
                        label={i18n.t('languageSelection.signUp', {
                            locale: newLanguage
                        })}
                        onPress={() => handleContinue('SignUpOnboarding')}
                    />
                </View>
            }
            footerSize="twoCtas"
        >
            <ScreenHeader hasBackButton={false} theme="tertiary" />
            <ScreenHero>
                <View className="flex-1 space-y-3">
                    <TouchableOpacity onLongPress={showCurrentAppVersion}>
                        <View>
                            <Svg
                                width="48"
                                height="44"
                                fill="none"
                                className="h-auto w-14"
                                viewBox="0 0 48 44"
                            >
                                <Path
                                    d="m25.885 28.652-5.503-5.439.065-.065A37.958 37.958 0 0 0 28.485 9h6.348V4.667H19.667V.333h-4.334v4.334H.167v4.311h24.201A34.042 34.042 0 0 1 17.5 20.592a33.898 33.898 0 0 1-5.005-7.259H8.162a38.051 38.051 0 0 0 6.456 9.88L3.59 34.09l3.077 3.077L17.5 26.333l6.738 6.739 1.647-4.42Zm12.198-10.985H33.75l-9.75 26h4.333l2.427-6.5h10.292l2.448 6.5h4.333l-9.75-26Zm-5.676 15.166 3.51-9.381 3.51 9.381h-7.02Z"
                                    fill="#FF7602"
                                />
                            </Svg>
                        </View>
                    </TouchableOpacity>
                    <Text
                        className="text-5xl -mb-1 font-medium"
                        style={{textAlign: getTextAlignment()}}
                    >
                        {i18n.t('languageSelection.title', {
                            locale: newLanguage
                        })}
                    </Text>
                    <Text
                        className="font-default leading-[20px] text-md"
                        style={{textAlign: getTextAlignment()}}
                    >
                        {i18n.t('languageSelection.text', {
                            locale: newLanguage
                        })}
                    </Text>
                </View>
            </ScreenHero>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <LanguageSelector
                        activeLanguage={newLanguage}
                        onPress={handleSelection}
                    />
                </View>
            </View>
        </Screen>
    );
};

export default LanguageSelectionScreen;
