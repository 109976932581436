import * as Updates from 'expo-updates';
import {Platform} from 'react-native';
import * as Sentry from 'sentry-expo';

// From https://github.com/mahevstark/fiction-expo-restart
const Restart = async () => {
    if (Platform.OS == 'web') {
        window.location.reload();
    } else {
        await Updates.reloadAsync();
    }
};

export default Restart;
