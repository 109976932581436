import React from 'react';
import {View} from 'react-native';
import {useRecoilValue} from 'recoil';
import i18n from 'i18n-js';
import Svg, {Path} from 'react-native-svg';
import Video from '@/components/Video';
import VideoQualitySelect from '@/components/VideoQualitySelect';
import * as UI from '@/components/UI/styles';
import useOffline from '@/hooks/useOffline';
import type {LearningCourse} from '@/types/learning';
import {videoQualityState} from '@/utils/store';

interface Props extends Partial<LearningCourse> {
    handleDownloadModalOpen: Function;
    handlePlayStatusChanged: Function;
    handleVideoWatched: Function;
    hasCompleted?: boolean;
    id: number;
    qualities: {
        high?: string;
        low: string;
        medium?: string;
    };
}

const CourseVideo: React.FC<Props> = ({
    handleDownloadModalOpen,
    handlePlayStatusChanged,
    handleVideoWatched,
    id,
    qualities,
    video_length
}: Props) => {
    const videoQuality = useRecoilValue(videoQualityState);
    const videoUrl = qualities?.[videoQuality] || qualities.low;
    const {getVideoUri, downloadedVideosList} = useOffline();
    const hasDownloaded = downloadedVideosList.includes(
        getVideoUri(videoUrl, id)
    );

    return (
        <Video
            handlePlayStatusChanged={handlePlayStatusChanged}
            handleDownloadModalOpen={handleDownloadModalOpen}
            handleVideoWatched={handleVideoWatched}
            id={id}
            qualities={qualities}
            quality={videoQuality}
            source={videoUrl}
            type="course"
        >
            <View className="flex flex-grow flex-row items-center">
                <Svg width="18" height="19" fill="none">
                    <Path
                        d="M9 6.5v3l2.25 2.25m4.5-2.25a6.75 6.75 0 11-13.5 0 6.75 6.75 0 0113.5 0z"
                        stroke="#000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
                <UI.Spacer size="xs" type="width" />
                <UI.BoldHeading>
                    {i18n.t('learning.videoDuration', {
                        duration: video_length || '-'
                    })}
                </UI.BoldHeading>
            </View>
            {!hasDownloaded && (
                <>
                    <VideoQualitySelect qualities={qualities} />
                    <UI.Spacer size="sm" type="width" />
                </>
            )}
        </Video>
    );
};

export default CourseVideo;
