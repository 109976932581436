import {Children, isValidElement, ReactElement, ReactNode} from 'react';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import snakeCase from 'lodash/snakeCase';
import trim from 'lodash/trim';
import zipObject from 'lodash/zipObject';
import {ConnectionStatus} from '@/types/request';
import {LearningProgressState} from '@/types/learning';

export const px = (value: number) => `${value}px`;

/**
 * TODO: Match with the Yup field schema
 */
export const getDefaultFieldValues = (fields: any) => {
    const keys = Object.keys(fields);
    const values = map(fields, f => {
        // eslint-disable-next-line no-underscore-dangle
        if (f?._type === 'boolean') {
            return false;
        } else if (f?._type === 'number') {
            return '';
        } else if (f?._type === 'array' || f?.is_multiple) {
            return [];
        } else {
            return '';
        }
    });

    return zipObject(keys, values);
};

export const humanise = (string: string) => {
    return capitalize(trim(snakeCase(string).replace(/_/g, ' ')));
};

export const getMessageFromError = (
    error: any,
    fallbackMessage: string = ''
) => {
    if (error) {
        if (typeof error === 'string') {
            return {message: error};
        } else if (error.details) {
            return {
                message: error?.message || '',
                errors: Object.keys(error.details).map(
                    key => `${humanise(key)} - ${error.details[key][0]}`
                )
            };
        } else if (error.message) {
            return {message: error.message};
        }
    } else {
        return {message: fallbackMessage};
    }
};

/**
 * Inspired by https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Tabs.tsx
 */
export const mapChildren = <P = any>(
    children: ReactNode,
    func: (el: ReactElement<P>, index: number, ...props: any) => any,
    ...props: any
) => {
    let index = 0;

    return Children.map(children, child =>
        isValidElement<P>(child) ? func(child, index++, ...props) : child
    );
};

export const getProgressBarOffset = (progress: number, barWidth: number) => {
    const barProgress = barWidth * (progress / 100);
    const offset =
        barProgress === 0 ? 0 : Math.ceil((barWidth - barProgress) * -1);

    return offset;
};

export const getModuleProgressState = (
    progress: string,
    isLocked: boolean
): LearningProgressState => {
    const progressInt = parseInt(progress);

    if (progressInt === 100) {
        return LearningProgressState.COMPLETE;
    } else if (progressInt > 0) {
        return LearningProgressState.STARTED;
    } else {
        return LearningProgressState.NOT_STARTED;
    }
};

export const getCourseProgressState = (
    isComplete: boolean,
    isLocked: boolean
): LearningProgressState => {
    if (isLocked) {
        return LearningProgressState.LOCKED;
    } else if (isComplete) {
        return LearningProgressState.COMPLETE;
    } else {
        return LearningProgressState.STARTED;
    }
};

export const timeout = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const getConnectionStatus = network => {
    if (!network || !network.isConnected || !network.isInternetReachable) {
        return ConnectionStatus.NONE;
    } else {
        return network.details.isConnectionExpensive
            ? ConnectionStatus.LOW
            : ConnectionStatus.GOOD;
    }
};
