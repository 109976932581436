import React, {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {StackScreenProps} from '@react-navigation/stack';
import Onboarding from '@/components/Onboarding';
import type {RootStackParamList} from '@/types/navigation';
import {languageScreenNextScreenState} from '@/utils/store';

type Props = StackScreenProps<RootStackParamList, 'SignUpOnboarding'>;

const SignUpOnboardingScreen: React.FC<Props> = ({
    navigation,
    route
}: Props) => {
    const [languageScreenNextScreen, setLanguageScreenNextScreen] =
        useRecoilState(languageScreenNextScreenState);

    const handleComplete = () => {
        navigation.push('SignUp');
    };

    /**
     * Cleanup workaround for changing language on the first app screen.
     */
    useEffect(() => {
        if (languageScreenNextScreen) {
            setTimeout(() => {
                setLanguageScreenNextScreen(null);
            }, 1000);
        }
    }, []);

    return <Onboarding handleComplete={handleComplete} isRecap={false} />;
};

export default SignUpOnboardingScreen;
