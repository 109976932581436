import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {colors, fontFamilies, radius, spacing, themes} from '@/utils/constants';
import type {Color} from '@/utils/constants';
import {
    LearningProgressState,
    LearningProgressStateTheme
} from '@/types/learning';
import {Platform} from 'react-native';

export const Container = styled.TouchableOpacity<{
    backgroundColor?: Color;
    isDisabled?: boolean;
}>`
    background-color: ${props =>
        props.backgroundColor
            ? colors[props.backgroundColor]
            : themes.tertiary.background};
    border-radius: ${px(radius.lg)};
    flex-basis: auto;
    overflow: hidden;
    padding: ${px(spacing.button)} ${px(spacing.md)};
    width: 100%;

    ${props => props.isDisabled && 'opacity: 0.5;'}
`;

export const progressBar = (props: {
    progressState: LearningProgressState;
}) => css`
    background-color: ${colors[
        LearningProgressStateTheme[props.progressState]
    ]};
    bottom: 0;
    flex: 1;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

export const Content = styled.View`
    align-items: center;
    flex: 1;
    flex-basis: auto;
    flex-direction: row;
`;

export const TextContainer = styled.View`
    flex: 1;
`;

export const IconContainer = styled.View<{
    alignRight?: boolean;
    hasBackgroundColor: boolean;
    isComplete?: boolean;
    isLocked?: boolean;
}>`
    align-items: center;
    border-radius: 50px;
    height: 50px;
    justify-content: center;
    width: 50px;

    ${props =>
        props.hasBackgroundColor &&
        props.isLocked &&
        `background-color:${colors.white};`}
    ${props =>
        props.hasBackgroundColor &&
        !props.isLocked &&
        `background-color:${colors.yellow};`}
    ${props =>
        props.hasBackgroundColor &&
        props.isComplete &&
        `background-color:${colors.blue};`}
    ${props =>
        props.alignRight
            ? `margin-left: ${px(spacing.md)};`
            : `margin-right: ${px(spacing.md)};`}
`;

export const icon = css`
    height: 30px;
    width: 30px;

    ${Platform.OS === 'web' && 'height: 16px; width: 16px;'}
`;

export const Position = styled.Text`
    font-family: ${fontFamilies.medium};
    font-size: 22px;
`;

export const DownloadContainer = styled.View`
    height: 54px;
    position: absolute;
    right: ${px(spacing.md)};
    margin-top: -29px;
    top: 50%;
`;
