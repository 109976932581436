import React, {useState} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import {Formik} from 'formik';
import i18n from 'i18n-js';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import * as UI from '@/components/UI/styles';
import useAuth from '@/hooks/useAuth';
import useForm from '@/hooks/useForm';
import useLearningContent from '@/hooks/useLearningContent';
import useSettings from '@/hooks/useSettings';
import signUpProfileSchema from '@/schemas/signup-profile';
import {RequestType} from '@/types/request';
import type {RootStackParamList} from '@/types/navigation';
import {getDefaultFieldValues} from '@/utils/helpers';

type SignUpProfileScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'SignUpProfile'
>;
interface Props {
    navigation: SignUpProfileScreenNavigationProp;
}

const SignUpProfileScreen: React.FC<Props> = ({navigation}: Props) => {
    const {completeSignUp} = useAuth();
    const {settings} = useSettings();
    const {syncLearningContent} = useLearningContent();
    const [selectedRole, setSelectedRole] = useState(null);
    const form = useForm(true);
    const initialFormValues = {
        ...getDefaultFieldValues(signUpProfileSchema.schema.fields),
        is_nrc_beneficiary_parent: '',
        is_nrc_beneficiary_teacher: '',
        is_nrc_beneficiary: ''
    };

    const handleFormError = response => {
        form.setStatus(RequestType.DEFAULT);
        form.setErrors(response);
    };

    const handleFormSuccess = async response => {
        if (response.status === 'ok') {
            await completeSignUp(response.data);

            /**
             * Prefill the learning content store before we move to the next screen.
             */
            await syncLearningContent();

            form.setStatus(RequestType.SUCCESS);
        } else {
            handleFormError({message: i18n.t('generic.unknownApiError')});
        }
    };

    const handleFormSubmit = async formValues => {
        if (form.status === RequestType.PENDING) {
            return;
        }

        form.setStatus(RequestType.PENDING);

        /**
         * Create a new object so that when we delete beneficiary keys below
         * they won't be cleared from the actual form value object in Formik
         */
        const values = {...formValues};

        values.classroom_size = values.classroom_size || '0';
        values.family_size = values.family_size || '0';

        /**
         * Parent/Caregiver option has ID 2. This is too tightly-coupled to the
         * CMS...
         */
        if (values.role == 2) {
            values.is_nrc_beneficiary = values.is_nrc_beneficiary_parent;
        } else {
            values.is_nrc_beneficiary = values.is_nrc_beneficiary_teacher;
        }

        /**
         * Remove extra fields, these are separated so we can display
         * different labels depending on which role has been selected...
         */
        delete values.is_nrc_beneficiary_parent;
        delete values.is_nrc_beneficiary_teacher;

        form.submit({
            onError: handleFormError,
            onSuccess: handleFormSuccess,
            request: {
                config: {
                    body: {
                        ...values,
                        has_done_walkthrough: true
                    }
                },
                url: `/api/user/update/`
            }
        });
    };

    /**
     * We use the submit count as a key as this will force the `KeyboardAwareScrollView`
     * to render. This is required as, when validation messages are shown, the height change
     * isn't handled correctly and elements appear 'removed' in `ScreenHero`.
     */
    return (
        <Screen>
            <ScreenHeader hasBackButton={false} theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero
                    text={i18n.t('signUpProfile.text')}
                    title={i18n.t('signUpProfile.title')}
                />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    {settings?.genders?.length > 0 &&
                    settings?.roles?.length > 0 ? (
                        <Formik
                            initialValues={initialFormValues}
                            onSubmit={handleFormSubmit}
                            validationSchema={signUpProfileSchema.schema}
                            validateOnMount={true}
                        >
                            {({
                                handleSubmit,
                                setFieldTouched,
                                setFieldValue,
                                submitCount
                            }) => {
                                const updateValuesForNewRole = async role => {
                                    if (selectedRole) {
                                        setSelectedRole(role);

                                        const fieldsToReset = [
                                            'classroom_size',
                                            'family_size',
                                            'is_nrc_beneficiary_parent',
                                            'is_nrc_beneficiary_teacher'
                                        ];

                                        fieldsToReset.forEach(field => {
                                            setFieldTouched(field, true);
                                        });
                                        await Promise.all(
                                            fieldsToReset.map(field => {
                                                setFieldValue(field, '', true);
                                            })
                                        );
                                    } else {
                                        setSelectedRole(role);
                                    }
                                };

                                return (
                                    <FormBuilder
                                        errors={form.errors}
                                        form={signUpProfileSchema.fields}
                                        handleChange={newValues => {
                                            /**
                                             * If a user's role changes we must confirm their
                                             * beneficiary status again.
                                             */
                                            if (
                                                newValues.role != selectedRole
                                            ) {
                                                updateValuesForNewRole(
                                                    newValues.role
                                                );
                                            }
                                        }}
                                        handleSubmit={handleSubmit}
                                        name="signUpProfile"
                                        options={{
                                            gender_id:
                                                settings?.genders?.map(i => ({
                                                    label: i.name,
                                                    value: i.id
                                                })) || null,
                                            role:
                                                settings?.roles?.map(i => ({
                                                    label: i.name,
                                                    value: i.id
                                                })) || null
                                        }}
                                        status={form.status}
                                        submitLabel={i18n.t(
                                            'signUpProfile.form.submit'
                                        )}
                                    />
                                );
                            }}
                        </Formik>
                    ) : (
                        <View className="flex items-center">
                            <ActivityIndicator color="#000" />
                        </View>
                    )}
                </View>
            </View>
        </Screen>
    );
};

export default SignUpProfileScreen;
