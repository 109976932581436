import {useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {I18nManager, Platform} from 'react-native';
import * as Localization from 'expo-localization';
import * as Sentry from 'sentry-expo';
import {useRecoilState} from 'recoil';
import i18n from 'i18n-js';
import Restart from '@/components/Restart';
import {Language, RTLLanguages} from '@/types/languages';
import {logEvent} from '@/utils/analytics';
import {
    languageState,
    languageDirectionState,
    isLayoutUpdatingState
} from '@/utils/store';

export const LOCALES = [
    'ar',
    'en',
    'es',
    'fr',
    'md',
    'pl',
    'ru',
    'ua'
] as const;
const isRTLLanguage = lang => Object.values(RTLLanguages).includes(lang);

const useLanguage = () => {
    const [language, setLanguage] = useRecoilState(languageState);
    const [direction, setDirection] = useRecoilState(languageDirectionState);
    const [isLayoutUpdating, setIsLayoutUpdating] = useRecoilState(
        isLayoutUpdatingState
    );

    const handleDirectionUpdate = async newLanguage => {
        if (isLayoutUpdating) {
            return;
        }

        const newDirection = isRTLLanguage(newLanguage) ? 'rtl' : 'ltr';

        if (!direction) {
            setDirection(newDirection);
        }

        if (
            (I18nManager.isRTL && newDirection !== 'rtl') ||
            (!I18nManager.isRTL && newDirection === 'rtl')
        ) {
            try {
                I18nManager.forceRTL(newDirection === 'rtl');

                if (Platform.OS !== 'web') {
                    setIsLayoutUpdating(true);
                    await Restart();
                }
            } catch (error) {
                if (error.message) {
                    // console.log(error.message);
                }

                if (Platform.OS === 'web') {
                    Sentry.Browser.captureException(error);
                } else {
                    Sentry.Native.captureException(error);
                }
            }
        }
    };

    const setAppLanguage = async (newLanguage, isFromAppLoading = false) => {
        if (!newLanguage) {
            // console.log('no language passed');
            return;
        }

        try {
            i18n.language = newLanguage;
            setLanguage(newLanguage);
            await AsyncStorage.setItem('language', newLanguage);

            if (!isFromAppLoading) {
                await logEvent('language_changed', {language: newLanguage});
            }

            await handleDirectionUpdate(newLanguage);
        } catch (error) {
            alert(error?.message);
        }
    };

    const getActiveLanguage = () => language;

    const getDefaultLanguage = async () => {
        // console.log('finding a lang to set');
        let defaultLanguage = 'en';

        try {
            const storedLanguage = await AsyncStorage.getItem('language');

            if (storedLanguage) {
                // console.log('using stored language');
                defaultLanguage = storedLanguage;
            } else {
                // console.log('no stored lang, getting default lang');

                if (Localization.locale) {
                    const [lang, _] = Localization.locale.split('-') as [
                        Language,
                        string
                    ];

                    if (lang && LOCALES.includes(lang)) {
                        defaultLanguage = lang as Language;
                    }
                }
            }
        } catch (error) {
            // console.log('ERR!');
            // console.log(error);
        } finally {
            return defaultLanguage;
        }
    };

    useEffect(() => {
        if (!language) {
            // @ts-ignore
            setLanguage(getActiveLanguage());
        }
    }, []);

    return {
        direction: getActiveLanguage() === 'ar' ? 'rtl' : 'ltr',
        getDefaultLanguage,
        language: getActiveLanguage(),
        setAppLanguage
    };
};

export default useLanguage;
