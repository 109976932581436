import React from 'react';
import {StackNavigationProp} from '@react-navigation/stack';
import ContactFormScreen from '@/components/ContactFormScreen';
import type {RootStackParamList} from '@/types/navigation';

type ContactScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'Contact'
>;
interface Props {
    navigation: ContactScreenNavigationProp;
}

const ContactScreen: React.FC<Props> = ({navigation}: Props) => (
    <ContactFormScreen
        hasBackButton={false}
        navigation={navigation}
        type="contact"
    />
);

export default ContactScreen;
