import React, {memo, ReactNode} from 'react';

interface Props {
    children: ReactNode;
    condition: any;
    wrapper?: (children: ReactNode) => ReactNode;
}

const ConditionalWrapper: React.FC<Props> = ({
    children,
    condition,
    wrapper
}: Props) => <>{condition ? wrapper(children) : children}</>;

export default memo(ConditionalWrapper);
