import React from 'react';
import {View} from 'react-native';
import {Field} from 'formik';
import isInteger from 'lodash/isInteger';
import * as S from './styles';

interface Props {
    hasError?: boolean;
    info?: string;
    label?: string;
    name: string;
    options: {
        label: string;
        value: number | string;
    }[];
    setValue: Function;
    value: boolean | number | string;
}

const RadioField: React.FC<Props> = ({
    hasError,
    info,
    label,
    name,
    options = [],
    setValue,
    value
}: Props) => (
    <Field label={label} name={name}>
        {({field, form, isFocused}) => {
            return (
                <View className="space-y-2">
                    {options.map(option => {
                        let isSelected = false;

                        if (isInteger(field.value) || isInteger(option.value)) {
                            isSelected = field.value == option.value;
                        } else if (typeof option.value === 'boolean') {
                            /**
                             * Catch empty values being loosely equated to falsey value in a boolean field.
                             */
                            isSelected = field.value === option.value;
                        } else {
                            isSelected = field.value == option.value;
                        }

                        return (
                            <S.RadioButton
                                key={option.value}
                                onPress={() => setValue(option.value)}
                            >
                                <S.RadioButtonIcon>
                                    <S.RadioButtonIconChecked
                                        isSelected={isSelected}
                                    />
                                </S.RadioButtonIcon>
                                <S.RadioButtonLabel isSelected={isSelected}>
                                    {option.label}
                                </S.RadioButtonLabel>
                            </S.RadioButton>
                        );
                    })}
                </View>
            );
        }}
    </Field>
);

export default RadioField;
