import styled, {css} from '@emotion/native';
import {colors, spacing} from '@/utils/constants';
import {px} from '@/utils/helpers';
import {Platform} from 'react-native';

export const ImageContainer = styled.View`
    background-color: ${colors.yellow};
    border-bottom-left-radius: ${px(spacing.lg)};
    border-bottom-right-radius: ${px(spacing.lg)};
    height: 330px;
`;

export const Image = styled.Image`
    bottom: 0;
    border-bottom-left-radius: ${px(spacing.lg)};
    border-bottom-right-radius: ${px(spacing.lg)};
    height: 330px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
`;

export const TextContainer = styled.ScrollView`
    flex: 1;
    margin-top: ${px(spacing.md)};
`;

export const paginationContainer = css`
    background-color: transparent;
    position: absolute;
    left: ${px(spacing.md)};

    ${Platform.OS !== 'web' && 'box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);'}
`;

export const skipLinkContainer = css`
    background-color: transparent;
    position: absolute;
    right: ${px(spacing.md)};
`;

export const PaginationItem = styled.View`
    background-color: ${colors.white};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 1);
    border-radius: ${px(spacing.sm)};
    height: ${px(spacing.sm)};
    width: ${px(spacing.sm)};
    z-index: 100;
`;
