import React from 'react';
import i18n from 'i18n-js';
import RadioButton from '../RadioButton';
import type {AuthUser} from '@/types/auth';
import * as S from './styles';
import {SUPPORTED_LANGUAGES} from '../../App';

interface LanguageSelector {
    key: string;
    language: AuthUser['preferred_language'];
    label: string;
}

interface Props {
    activeLanguage: AuthUser['preferred_language'];
    onPress?: Function;
}

const LanguageSelectorSelector: React.FC<Props> = ({
    activeLanguage,
    onPress
}: Props) => {
    const languagesString: unknown = i18n.t('languageSelection.languages');
    const languages = languagesString as LanguageSelector[];

    return (
        <S.Container>
            <S.Grid>
                {languages
                    .filter(item => {
                        return SUPPORTED_LANGUAGES.includes(item.language);
                    })
                    .map(item => {
                        const direction =
                            item.language === 'ar' ? 'rtl' : 'ltr';
                        const isActive = item.language === activeLanguage;

                        return (
                            <S.GridItem key={item.language}>
                                <RadioButton.Container
                                    isActive={isActive}
                                    onPress={() => onPress(item.language)}
                                >
                                    <RadioButton.Label direction={direction}>
                                        {item.label}
                                    </RadioButton.Label>
                                </RadioButton.Container>
                            </S.GridItem>
                        );
                    })}
            </S.Grid>
        </S.Container>
    );
};

export default LanguageSelectorSelector;
