import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import clsx from 'clsx';

interface Props {
    handleChange: Function;
    value: number;
}

const WellbeingScale: React.FC<Props> = ({handleChange, value}: Props) => {
    const options = [1, 2, 3, 4, 5];

    return (
        <View className="flex relative space-y-4">
            <View className="flex flex-row flex-grow justify-between">
                <View>
                    <Text className="text-center text-[40px]">😡</Text>
                </View>
                <View></View>
                <View>
                    <Text className="text-center text-[40px]">😬</Text>
                </View>
                <View></View>
                <View>
                    <Text className="text-center text-[40px]">😊</Text>
                </View>
            </View>
            <View className="flex flex-row flex-grow justify-between">
                <View className="h-[2px] bg-blp-grey-light opacity-40 w-[85%] ml-[7.5%] mt-4 absolute z-10" />
                {options.map(option => {
                    const isSelected = option === value;

                    return (
                        <View key={option} className="relative z-20">
                            <View className="mb-2 rounded-full h-[26px] w-[26px] overflow-hidden bg-white">
                                <TouchableOpacity
                                    className={clsx(
                                        'absolute left-[3px] top-[3px] h-[20px] w-[20px] rounded-full p-1',
                                        {
                                            'bg-blp-grey-light': !isSelected,
                                            'bg-blp-orange scale-[1.2]':
                                                isSelected
                                        }
                                    )}
                                    onPress={() => handleChange(option)}
                                >
                                    <View
                                        className={clsx(
                                            'absolute left-[2px] top-[2px] border-2 border-white rounded-full w-[16px] h-[16px]',
                                            {
                                                'bg-white': !isSelected,
                                                'bg-blp-orange': isSelected
                                            }
                                        )}
                                    />
                                </TouchableOpacity>
                            </View>
                            <Text
                                className={clsx('text-center', {
                                    'font-medium mt-[1px]': isSelected
                                })}
                            >
                                {option}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </View>
    );
};

export default WellbeingScale;
