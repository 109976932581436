import React from 'react';
import {Text, View} from 'react-native';
import i18n from 'i18n-js';

const LearningReminderPrompt: React.FC = () => (
    <View className="mb-4">
        <Text className="font-medium text-sm leading-5">
            {i18n.t('learningIndex.reminder')}
        </Text>
    </View>
);

export default LearningReminderPrompt;
