import {object, string} from 'yup';

const schema = object().shape({
    name: string().required('empty'),
    phone: string().min(5, 'empty').max(16, 'empty').required('empty')
});

export const fields = {
    fields: {
        name: {field: 'input'},
        phone: {
            autoCompleteType: 'tel',
            dataDetectorTypes: 'phoneNumber',
            field: 'input'
        }
    }
};

export default {fields, schema};
