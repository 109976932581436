import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Alert, Platform, View} from 'react-native';
import i18n from 'i18n-js';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import useOffline from '@/hooks/useOffline';
import * as S from './styles';
import {RequestType} from '@/types/request';
import {logEvent} from '@/utils/analytics';

interface Props {
    handleOpen: Function;
    hasDownloaded: boolean;
    id: number;
    qualities?: {
        high?: string;
        low: string;
        medium?: string;
    };
    quality?: string;
    source?: string;
}

const VideoDownload: React.FC<Props> = ({
    handleOpen,
    hasDownloaded,
    id,
    qualities,
    quality,
    source
}: Props) => {
    const video = source || qualities?.[quality] || qualities?.low;
    const {deleteDownloadedVideo, getDownloadedVideoUri} = useOffline();
    const [status, setStatus] = useState<RequestType>(RequestType.DEFAULT);
    const [_, setDownloadedVideo] = useState<string>(null);

    const getDownloadedVideo = async () => {
        const uri = await getDownloadedVideoUri(video, id);

        if (uri) {
            setDownloadedVideo(uri);
        }
    };

    const handleVideoDelete = async () => {
        setStatus(RequestType.PENDING);
        await deleteDownloadedVideo(video, id);
        setDownloadedVideo(null);
        setStatus(RequestType.DEFAULT);
        await logEvent('video_download_deleted', {courseId: id});
    };

    const handlePress = () => {
        if (status !== RequestType.DEFAULT) {
            return;
        }

        if (hasDownloaded) {
            Alert.alert(
                i18n.t('videoDownload.remove.title'),
                i18n.t('videoDownload.remove.text'),
                [
                    {
                        text: i18n.t('videoDownload.remove.cancel'),
                        style: 'cancel'
                    },
                    {
                        style: 'destructive',
                        text: i18n.t('videoDownload.remove.confirm'),
                        onPress: handleVideoDelete
                    }
                ]
            );
        } else {
            handleOpen({courseId: id, qualities, video});
        }
    };

    useEffect(() => {
        getDownloadedVideo();
    }, [video]);

    if (!video || Platform.OS === 'web') {
        return null;
    }

    return (
        <View>
            <Button
                isGhost={true}
                isIcon={true}
                onPress={handlePress}
                theme="greyLight"
            >
                {status === RequestType.PENDING ? (
                    <ActivityIndicator />
                ) : (
                    <Icon
                        name={hasDownloaded ? 'downloaded' : 'download'}
                        style={S.icon({hasDownloaded})}
                    />
                )}
            </Button>
        </View>
    );
};

export default VideoDownload;
