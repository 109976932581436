import React, {useState} from 'react';
import {LayoutChangeEvent, Platform, Text, View} from 'react-native';
import {useRecoilValue} from 'recoil';
import i18n from 'i18n-js';
import Button from '@/components/Button';
import OnboardingPagination from '@/components/Onboarding/Pagination';
import Screen from '@/components/Screen';
import StickyFooter from '@/components/StickyFooter';
import TextButton from '@/components/TextButton';
import * as UI from '@/components/UI/styles';
import * as S from './styles';
import {logEvent} from '@/utils/analytics';
import {colors, spacing} from '@/utils/constants';
import {languageState} from '@/utils/store';

interface Props {
    handleComplete: Function;
    isRecap?: boolean;
}

const Onboarding: React.FC<Props> = ({handleComplete, isRecap}: Props) => {
    const language = useRecoilValue(languageState);
    const [activeSection, setActiveSection] = useState<number>(0);
    const [signUpButtonOffset, setSignUpButtonOffset] = useState<number>(0);
    const screenContent = i18n.t('signUpOnboarding.sections');
    const backgroundColors = [
        colors.blue,
        colors.yellow,
        colors.blue,
        colors.yellow,
        colors.blue,
        colors.yellow
    ];
    const assets = {
        ar: [
            require('../../assets/images/onboarding/ar/1.png'),
            require('../../assets/images/onboarding/ar/2.png'),
            require('../../assets/images/onboarding/ar/3.png'),
            require('../../assets/images/onboarding/ar/4.png'),
            require('../../assets/images/onboarding/ar/5.png'),
            require('../../assets/images/onboarding/ar/6.png')
        ],
        en: [
            require('../../assets/images/onboarding/en/1.png'),
            require('../../assets/images/onboarding/en/2.png'),
            require('../../assets/images/onboarding/en/3.png'),
            require('../../assets/images/onboarding/en/4.png'),
            require('../../assets/images/onboarding/en/5.png'),
            require('../../assets/images/onboarding/en/6.png')
        ],
        es: [
            require('../../assets/images/onboarding/es/1.png'),
            require('../../assets/images/onboarding/es/2.png'),
            require('../../assets/images/onboarding/es/3.png'),
            require('../../assets/images/onboarding/es/4.png'),
            require('../../assets/images/onboarding/es/5.png'),
            require('../../assets/images/onboarding/es/6.png')
        ],
        fr: [
            require('../../assets/images/onboarding/fr/1.png'),
            require('../../assets/images/onboarding/fr/2.png'),
            require('../../assets/images/onboarding/fr/3.png'),
            require('../../assets/images/onboarding/fr/4.png'),
            require('../../assets/images/onboarding/fr/5.png'),
            require('../../assets/images/onboarding/fr/6.png')
        ],
        pl: [
            require('../../assets/images/onboarding/pl/1.png'),
            require('../../assets/images/onboarding/pl/2.png'),
            require('../../assets/images/onboarding/pl/3.png'),
            require('../../assets/images/onboarding/pl/4.png'),
            require('../../assets/images/onboarding/pl/5.png'),
            require('../../assets/images/onboarding/pl/6.png')
        ],
        ro: [
            require('../../assets/images/onboarding/ro/1.png'),
            require('../../assets/images/onboarding/ro/2.png'),
            require('../../assets/images/onboarding/ro/3.png'),
            require('../../assets/images/onboarding/ro/4.png'),
            require('../../assets/images/onboarding/ro/5.png'),
            require('../../assets/images/onboarding/ro/6.png')
        ],
        ru: [
            require('../../assets/images/onboarding/ru/1.png'),
            require('../../assets/images/onboarding/ru/2.png'),
            require('../../assets/images/onboarding/ru/3.png'),
            require('../../assets/images/onboarding/ru/4.png'),
            require('../../assets/images/onboarding/ru/5.png'),
            require('../../assets/images/onboarding/ru/6.png')
        ],
        ua: [
            require('../../assets/images/onboarding/ua/1.png'),
            require('../../assets/images/onboarding/ua/2.png'),
            require('../../assets/images/onboarding/ua/3.png'),
            require('../../assets/images/onboarding/ua/4.png'),
            require('../../assets/images/onboarding/ua/5.png'),
            require('../../assets/images/onboarding/ua/6.png')
        ]
    };
    const assetsForLanguage = assets?.[language] || assets.en;

    const handleLayoutChange = (event: LayoutChangeEvent) => {
        const {height} = event.nativeEvent.layout;

        setSignUpButtonOffset(height + spacing.lg);
    };

    const handleNext = () => {
        if (activeSection !== screenContent.length - 1) {
            setActiveSection(activeSection + 1);
        }
    };

    const handlePrevious = () => {
        if (activeSection !== 0) {
            setActiveSection(activeSection - 1);
        }
    };

    const handleSkip = async () => {
        if (!isRecap) {
            await logEvent('onboarding_skipped');
        }

        handleComplete();
    };

    const handleSignUp = async () => {
        if (!isRecap) {
            await logEvent('onboarding_completed');
        }

        handleComplete();
    };

    return (
        <Screen
            footer={
                <View className="flex flex-row space-x-2">
                    <View
                        className="flex-grow flex-1"
                        onLayout={handleLayoutChange}
                    >
                        <Button
                            isDisabled={activeSection === 0}
                            isGhost={true}
                            key={activeSection}
                            label={i18n.t('signUpOnboarding.previous')}
                            onPress={handlePrevious}
                        />
                    </View>
                    {signUpButtonOffset > 0 && (
                        <View className="flex-grow flex-1">
                            <Button
                                label={
                                    activeSection < screenContent.length - 1
                                        ? i18n.t('signUpOnboarding.next')
                                        : isRecap
                                        ? i18n.t('signUpOnboarding.home')
                                        : i18n.t('signUpOnboarding.signUp')
                                }
                                onPress={() => {
                                    if (
                                        activeSection ===
                                        screenContent.length - 1
                                    ) {
                                        handleSignUp();
                                    } else {
                                        handleNext();
                                    }
                                }}
                            />
                        </View>
                    )}
                </View>
            }
            footerSize="oneCta"
            hasSafeArea={false}
            statusBarColor="transparent"
        >
            <S.ImageContainer
                style={{backgroundColor: backgroundColors[activeSection]}}
            >
                {assetsForLanguage[activeSection] && (
                    <S.Image
                        source={assetsForLanguage[activeSection]}
                        resizeMode={Platform.OS === 'web' ? 'contain' : 'cover'}
                    />
                )}
            </S.ImageContainer>
            <UI.SafeAreaView style={S.paginationContainer}>
                <UI.Spacer />
                <OnboardingPagination
                    activeIndex={activeSection}
                    size={screenContent.length}
                />
            </UI.SafeAreaView>
            <UI.SafeAreaView style={S.skipLinkContainer}>
                <TextButton color="black" onPress={handleSkip}>
                    {i18n.t('signUpOnboarding.skip')}
                </TextButton>
            </UI.SafeAreaView>

            <View className="flex-1 mx-auto w-full max-w-2xl p-4 mt-4">
                <Text className="text-2xl font-medium leading-7">
                    {screenContent[activeSection]?.title}
                </Text>
                <Text className="text-md leading-6 mt-2 font-default">
                    {screenContent[activeSection]?.text}
                </Text>
            </View>
        </Screen>
    );
};

export default Onboarding;
