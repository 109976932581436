import {useState} from 'react';
import {View} from 'react-native';
import Image from 'react-native-scalable-image';

interface Props {
    source: string;
}

const ScalableImage: React.FC<Props> = ({source}) => {
    const [width, setWidth] = useState<number>(0);

    const handleOnLayout = (event: any) => {
        if (event.nativeEvent.layout) {
            setWidth(event.nativeEvent.layout.width);
        }
    };

    return (
        <View onLayout={handleOnLayout}>
            {width > 0 && <Image width={width} source={{uri: source}} />}
        </View>
    );
};

export default ScalableImage;
