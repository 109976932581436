import React from 'react';
import {Text} from 'react-native';
import i18n from 'i18n-js';
import Panel from '@/components/Panel';

const ApiViewError: React.FC = () => (
    <Panel type="error" title={i18n.t('generic.apiErrorTitle')}>
        <Text className="text-sm leading-5 font-default">
            {i18n.t('generic.apiErrorText')}
        </Text>
    </Panel>
);

export default ApiViewError;
