import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
import i18n from 'i18n-js';
import ApiView from '@/components/ApiView';
import MemoizedLayout from '@/components/MemoizedLayout';
import Panel from '@/components/Panel';
import ResourcesList from '@/components/ResourcesList';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import * as UI from '@/components/UI/styles';
import useResources from '@/hooks/useResources';
import {RequestType} from '@/types/request';

const ResourcesScreen: React.FC = () => {
    const isFocused = useIsFocused();
    const {fetchAllResources, resources, status} = useResources();

    useEffect(() => {
        if (resources.length === 0) {
            fetchAllResources();
        }
    }, [isFocused]);

    return (
        <Screen>
            <ScreenHeader hasBackButton={false} theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero title={i18n.t('resources.title')} />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <ApiView
                        empty={
                            <Panel
                                type="pending"
                                title={i18n.t('resources.emptyTitle')}
                            >
                                <Text className="font-default leading-5">
                                    {i18n.t('resources.emptyText')}
                                </Text>
                            </Panel>
                        }
                        hasError={status === RequestType.ERROR}
                        isEmpty={!resources || resources?.length === 0}
                        isLoading={status === RequestType.PENDING}
                    >
                        <ResourcesList resources={resources} />
                    </ApiView>
                </View>
            </View>
        </Screen>
    );
};

export default ResourcesScreen;
