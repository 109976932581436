import {ReactNode} from 'react';
import {View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

interface Props {
    children: ReactNode;
}

export const STICKY_FOOTER_HEIGHTS = {
    oneCta: 74 + 16 + 16,
    twoCtas: 74 * 1.5 + 16 + 16
};

const StickyFooter: React.FC<Props> = ({children}) => {
    const insets = useSafeAreaInsets();

    return (
        <View
            className="border-t-2 border-t-gray-100 absolute w-full px-4 py-4"
            style={{
                backgroundColor: 'rgba(255,255,255,0.9)',
                bottom: insets.bottom
            }}
        >
            <View className="flex-1 mx-auto w-full max-w-2xl">{children}</View>
        </View>
    );
};

export default StickyFooter;
