import styled from '@emotion/native';
import type {LanguageDirection} from '@/types/languages';
import {colors, spacing} from '@/utils/constants';
import {px} from '@/utils/helpers';

export const Container = styled.TouchableOpacity<{
    isActive: boolean;
}>`
    background-color: white;
    border: 1px solid ${colors.greyLight};
    border-radius: ${px(spacing.sm)};
    flex-grow: 1;
    padding: ${px(spacing.md)} ${px(spacing.sm)};
    overflow: hidden;

    ${props =>
        props.isActive
            ? `background-color:${colors.orange}; border: 2px solid ${colors.orange};`
            : `border: 2px solid ${colors.greyLight};`}
`;

export const Label = styled.Text<{
    direction?: LanguageDirection;
}>`
    font-weight: 500;
    font-size: 16px;
    text-align: center;
`;

export const CheckedIconContainer = styled.View`
    left: ${px(spacing.xs)};
    position: absolute;
    top: ${px(spacing.xs)};
`;

export const CheckedIcon = styled.Text`
    color: ${colors.primary};
`;
