import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {colors, fontFamilies, radius, spacing, themes} from '@/utils/constants';
import {
    LearningProgressState,
    LearningProgressStateTheme
} from '@/types/learning';

export const Container = styled.TouchableOpacity<{
    isDisabled?: boolean;
}>`
    background-color: ${themes.tertiary.background};
    border-radius: ${px(radius.lg)};
    flex: 1;
    flex-basis: auto;
    overflow: hidden;
    width: 100%;

    ${props => props.isDisabled && 'opacity: 0.5;'}
`;

export const ProgressBar = styled.View<{
    progress: number;
    progressState: LearningProgressState;
}>`
    border-radius: ${px(radius.lg)};
    bottom: 0;
    flex: 1;
    left: 0;
    position: absolute;
    top: 0;

    ${props => `background-color: ${
        colors[LearningProgressStateTheme[props.progressState]]
    };
    width: ${Math.floor(props.progress)}%;`}
`;

export const Content = styled.View`
    align-items: center;
    flex: 1;
    flex-direction: row;
    flex-basis: auto;
    padding: ${px(spacing.button)} ${px(spacing.md)};w
`;

export const TextContainer = styled.View`
    flex: 1;
`;

export const IconContainer = styled.View`
    align-items: center;
    margin-left: ${px(spacing.md)};
    width: 30px;
`;

export const icon = css`
    height: 30px;
    width: 30px;
`;

export const Position = styled.Text`
    font-family: ${fontFamilies.medium};
    font-size: 18px;
`;
