import React, {useRef} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import NotFoundScreen from '@/screens/NotFound';
import {RootStackParamList} from '@/types/navigation';
import BottomTabNavigator from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import useAuth from '@/hooks/useAuth';
import LanguageSelectionScreen from '@/screens/LanguageSelection';
import SignUpScreen from '@/screens/SignUp';
import SignUpProfileScreen from '@/screens/SignUpProfile';
import SignUpOnboardingScreen from '@/screens/SignUpOnboarding';
import SignInScreen from '@/screens/SignIn';
import {themes} from '@/utils/constants';
import {logScreenView} from '@/utils/analytics';

const Stack = createStackNavigator<RootStackParamList>();

const RootNavigator = () => {
    const {authUser} = useAuth();

    return (
        <Stack.Navigator screenOptions={{headerShown: false}}>
            {authUser?.is_active ? (
                <>
                    {!authUser?.has_done_walkthrough && (
                        <Stack.Screen
                            name="SignUpProfile"
                            component={SignUpProfileScreen}
                        />
                    )}
                    <Stack.Screen name="Root" component={BottomTabNavigator} />
                    {/* <Stack.Screen
                        name="NotFound"
                        component={NotFoundScreen}
                        options={{title: 'Oops!'}}
                    /> */}
                </>
            ) : (
                <>
                    <Stack.Screen
                        name="LanguageSelection"
                        component={LanguageSelectionScreen}
                    />
                    <Stack.Screen
                        name="SignUpOnboarding"
                        component={SignUpOnboardingScreen}
                    />
                    <Stack.Screen name="SignUp" component={SignUpScreen} />
                    <Stack.Screen name="SignIn" component={SignInScreen} />
                </>
            )}
        </Stack.Navigator>
    );
};

const Navigation = () => {
    const navigationRef = useRef(null);
    const routeNameRef = useRef(null);

    const handleStateChange = async () => {
        try {
            const previousRouteName = routeNameRef.current;
            const currentRoute = navigationRef.current.getCurrentRoute();
            const currentRouteName = currentRoute.name;

            if (previousRouteName !== currentRouteName) {
                await logScreenView(
                    currentRouteName,
                    currentRoute?.params || {}
                );
            }

            // Save the current route name for later comparison
            routeNameRef.current = currentRouteName;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            ref={navigationRef}
            onReady={() => {
                routeNameRef.current =
                    navigationRef.current.getCurrentRoute().name;
            }}
            onStateChange={handleStateChange}
            theme={{colors: themes.navigation, dark: false}}
        >
            <RootNavigator />
        </NavigationContainer>
    );
};

export default Navigation;
