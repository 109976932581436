import React from 'react';
import {ActivityIndicator, Text, View} from 'react-native';
import i18n from 'i18n-js';
import Panel from '@/components/Panel';

const ApiViewLoading: React.FC = () => (
    <Panel type="pending">
        <View className="flex space-y-2 items-center justify-center">
            <View className="w-5">
                <ActivityIndicator color="#000" />
            </View>
            <Text className="text-sm leading-5 font-default">
                {i18n.t('generic.loading')}
            </Text>
        </View>
    </Panel>
);

export default ApiViewLoading;
