import React from 'react';
import {StackNavigationProp} from '@react-navigation/stack';
import ContactFormScreen from '@/components/ContactFormScreen';
import type {RootStackParamList} from '@/types/navigation';

type FeedbackScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'Contact'
>;
interface Props {
    navigation: FeedbackScreenNavigationProp;
}

const FeedbackScreen: React.FC<Props> = ({navigation}: Props) => (
    <ContactFormScreen navigation={navigation} type="feedback" />
);

export default FeedbackScreen;
