import React from 'react';
import {StackScreenProps} from '@react-navigation/stack';
import Onboarding from '@/components/Onboarding';
import type {RootStackParamList} from '@/types/navigation';

type Props = StackScreenProps<RootStackParamList, 'Guide'>;

const GuideScreen: React.FC<Props> = ({navigation}: Props) => {
    const handleComplete = () => {
        navigation.push('Root');
    };

    return <Onboarding handleComplete={handleComplete} isRecap={true} />;
};

export default GuideScreen;
