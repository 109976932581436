import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Platform, View} from 'react-native';
import posed from 'react-native-pose';
import Svg, {Circle, Path} from 'react-native-svg';
import * as ScreenOrientation from 'expo-screen-orientation';
import {ResizeMode, Video as VideoPrimitive} from 'expo-av';
import * as UI from '@/components/UI/styles';
import VideoDownload from '@/components/VideoDownload';
import * as S from './styles';
import useOffline from '@/hooks/useOffline';
import {logEvent} from '@/utils/analytics';

interface Props {
    children?: ReactNode;
    handleDownloadModalOpen?: Function;
    handlePlayStatusChanged?: Function;
    handleVideoWatched?: Function;
    hasCompleted?: boolean;
    id: number;
    quality?: string;
    qualities?: {
        high?: string;
        low: string;
        medium?: string;
    };
    source: string;
    type: 'course' | 'resource';
}

const AnimatedContainer = posed.View({
    visible: {
        opacity: 1,
        transition: ({delay, duration}) => ({
            delay,
            duration,
            stiffness: 300,
            mass: 4,
            damping: 70,
            type: 'spring'
        })
    },
    hidden: {
        opacity: 0,
        transition: {type: 'spring'}
    }
});

const Video: React.FC<Props> = ({
    children,
    handleDownloadModalOpen,
    handlePlayStatusChanged,
    handleVideoWatched,
    hasCompleted,
    id,
    quality,
    qualities,
    source,
    type
}: Props) => {
    const video = useRef(null);
    const {getDownloadedVideoUri, getVideoUri, downloadedVideosList} =
        useOffline();
    const hasDownloaded = downloadedVideosList.includes(
        getVideoUri(source, id)
    );
    const [downloadedVideoUrl, setDownloadedVideoUrl] = useState<string>(null);
    const [isDisplayReady, setIsDisplayReady] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [hasPlayed, setHasPlayed] = useState<boolean>(false);

    const handleDisplayReady = () => {
        setIsDisplayReady(true);
    };

    const handlePlayVideo = () => {
        if (video?.current) {
            video.current.playAsync();
            setIsPlaying(true);
        }
    };

    const handlePauseVideo = () => {
        if (video?.current) {
            video.current.pauseAsync();
            setIsPlaying(false);
        }
    };

    const checkForDownloadedVideo = async () => {
        const uri = await getDownloadedVideoUri(source, id);

        setDownloadedVideoUrl(uri);
    };

    const startFullscreen = async () => {
        if (Platform.OS === 'web') {
            return;
        }

        try {
            await ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock.LANDSCAPE
            );
            await video.current.presentFullscreenPlayer();
        } catch (error) {
            console.log(error);
        }
    };

    const endFullscreen = async () => {
        if (Platform.OS === 'web') {
            return;
        }

        try {
            await ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock.PORTRAIT
            );
            await video.current.dismissFullscreenPlayer();
        } catch (error) {
            console.log(error);
        }
    };

    const handleStatusUpdate = async updatedStatus => {
        if (updatedStatus?.didJustFinish) {
            try {
                if (handleVideoWatched && !hasCompleted) {
                    handleVideoWatched();
                }

                /**
                 * Once the video has finished we need to set the play position back to the start.
                 * Otherwise, if the user attempts to immediately watch the video again then
                 * 'didJustFinish' will always be true and they will not be able to replay.
                 */
                if (video.current) {
                    await video.current.setStatusAsync({
                        positionMillis: 0,
                        shouldPlay: false
                    });
                }

                // handlePauseVideo();
                await endFullscreen();
            } catch (error) {
                throw new Error(error);
            }
        } else {
            setIsPlaying(updatedStatus.isPlaying);
        }
    };

    const handleFullscreenUpdate = async event => {
        if (Platform.OS === 'web') {
            return;
        }

        /**
         * With this callback we need to handle users dismissing fullscreen video player. When they trigger a
         * video to play we set the video fullscreen and lock orientation to landscape. Then, when they pause
         * we dismiss the fullscreen and reverse the orientation change. However we also need to account for
         * when a user manually dismisses fullscreen via the video controls.
         *
         * https://docs.expo.dev/versions/latest/sdk/video/
         * 0 Video.FULLSCREEN_UPDATE_PLAYER_WILL_PRESENT: describing that the fullscreen player is about to present
         * 1 Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT: describing that the fullscreen player just finished presenting
         * 2 Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS: describing that the fullscreen player is about to dismiss
         * 3 Video.FULLSCREEN_UPDATE_PLAYER_DID_DISMISS: describing that the fullscreen player just finished dismissing
         */
        if (event.fullscreenUpdate >= 2) {
            if (isPlaying) {
                handlePauseVideo();
            }

            await endFullscreen();
        }
    };

    const handleFullScreenChange = async (isPlaying: boolean) => {
        if (Platform.OS === 'web') {
            return;
        }

        try {
            if (isPlaying) {
                await startFullscreen();
            } else {
                await endFullscreen();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleHasPlayed = async () => {
        setHasPlayed(true);
        await logEvent('video_played', {[`${type}Id`]: id});
    };

    useEffect(() => {
        checkForDownloadedVideo();
    }, [video]);

    useEffect(() => {
        if (video.current && Platform.OS !== 'web') {
            if (handleFullScreenChange) {
                handleFullScreenChange(isPlaying);
            }

            if (handlePlayStatusChanged) {
                handlePlayStatusChanged(isPlaying);
            }
        }

        if (!hasPlayed) {
            handleHasPlayed();
        }
    }, [isPlaying]);

    if (!source) return null;

    return (
        <>
            <AnimatedContainer
                initialPose="hidden"
                pose={isDisplayReady ? 'visible' : 'hidden'}
                nativeID="video-wrapper"
                style={S.videoAnimatedWrapper}
            >
                <VideoPrimitive
                    onFullscreenUpdate={handleFullscreenUpdate}
                    onPlaybackStatusUpdate={handleStatusUpdate}
                    onReadyForDisplay={handleDisplayReady}
                    progressUpdateIntervalMillis={1000}
                    resizeMode={ResizeMode.COVER}
                    ref={video}
                    source={{
                        uri:
                            hasDownloaded && downloadedVideoUrl
                                ? downloadedVideoUrl
                                : source
                    }}
                    style={S.video}
                    usePoster={true}
                    useNativeControls={Platform.OS === 'web'}
                />
                {!isPlaying && (
                    <S.Button onPress={handlePlayVideo}>
                        <Svg
                            width="52"
                            height="52"
                            fill="none"
                            style={S.playButton}
                        >
                            <Circle cx="26" cy="26" r="26" fill="#fff" />
                            <Path
                                d="M23.537 19.978a1 1 0 00-1.537.844v10.356a1 1 0 001.537.844l8.137-5.178a1 1 0 000-1.688l-8.137-5.178z"
                                fill="#000"
                            />
                        </Svg>
                    </S.Button>
                )}
            </AnimatedContainer>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="items-center justify-between flex flex-row bg-white h-[80px] px-4 z-50">
                    {children ? children : <View />}
                    <VideoDownload
                        hasDownloaded={hasDownloaded}
                        handleOpen={handleDownloadModalOpen}
                        id={id}
                        qualities={qualities}
                        quality={quality}
                    />
                </View>
            </View>
        </>
    );
};

export default Video;
