import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {radius, spacing} from '@/utils/constants';
import {Platform} from 'react-native';

export const Container = styled.TouchableOpacity`
    background-color: #fff;
    border-radius: ${px(radius.lg)};
    flex-basis: auto;
    overflow: hidden;

    width: 100%;
`;

export const Content = styled.View`
    align-items: center;
    flex: 1;
    flex-basis: auto;
    flex-direction: row;
`;

export const TextContainer = styled.View`
    flex: 1;
    padding: ${px(spacing.button)} ${px(spacing.md)};
`;

export const IconContainer = styled.View<{
    alignRight?: boolean;
    hasBackgroundColor: boolean;
    isComplete?: boolean;
    isLocked?: boolean;
}>`
    align-items: center;
    border-radius: 50px;
    height: 50px;
    justify-content: center;
    width: 50px;

    ${props =>
        props.alignRight
            ? `margin-left: ${px(spacing.md)};`
            : `margin-right: ${px(spacing.md)};`}
`;

export const icon = css`
    height: 30px;
    width: 30px;

    ${Platform.OS === 'web' && 'height: 16px; width: 20px;'}
`;
