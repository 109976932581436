import {ReactNode} from 'react';
import {ScrollView, View} from 'react-native';
import {StatusBar} from 'expo-status-bar';
import {SafeAreaView} from 'react-native-safe-area-context';
import ConditionalWrapper from '@/components/ConditionalWrapper';
import StickyFooter, {STICKY_FOOTER_HEIGHTS} from '@/components/StickyFooter';

interface Props {
    background?: ReactNode;
    children: ReactNode;
    footer?: ReactNode;
    footerSize?: 'oneCta' | 'twoCtas' | null;
    hasHeader?: boolean;
    hasSafeArea?: boolean;
    hasScrollView?: boolean;
    isScrollable?: boolean;
    options?: any;
    statusBarColor?: string;
}

const Screen: React.FC<Props> = ({
    background,
    children,
    footer,
    footerSize,
    hasHeader = true,
    hasSafeArea = true,
    hasScrollView = true,
    isScrollable = true,
    statusBarColor = '#F8F3EE'
}) => (
    <>
        <StatusBar backgroundColor={statusBarColor} style="dark" />
        <ConditionalWrapper
            condition={hasScrollView}
            wrapper={wrappedChildren => (
                <ScrollView
                    className="flex flex-grow"
                    scrollEnabled={isScrollable}
                    nativeID="screen-scrollview"
                >
                    {wrappedChildren}
                </ScrollView>
            )}
        >
            {background}
            <ConditionalWrapper
                condition={hasSafeArea}
                wrapper={wrappedChildren => (
                    <SafeAreaView className="flex flex-grow">
                        {wrappedChildren}
                    </SafeAreaView>
                )}
            >
                <View
                    className="flex flex-grow"
                    style={{
                        paddingBottom: footerSize
                            ? STICKY_FOOTER_HEIGHTS[footerSize]
                            : 0
                    }}
                >
                    {children}
                </View>
            </ConditionalWrapper>
        </ConditionalWrapper>
        {footer && <StickyFooter>{footer}</StickyFooter>}
    </>
);

export default Screen;
