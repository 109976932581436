import React, {useState} from 'react';
import {Text, View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import i18n from 'i18n-js';
import {Formik} from 'formik';
import omit from 'lodash/omit';
import {toast} from 'react-hot-toast/src/core/toast';
import Button from '@/components/Button';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import * as UI from '@/components/UI/styles';
import useForm from '@/hooks/useForm';
import useSettings from '@/hooks/useSettings';
import contactSchema from '@/schemas/contact';
import feedbackSchema from '@/schemas/feedback';
import type {RootStackParamList} from '@/types/navigation';
import type {ApiResponse} from '@/types/api';
import RequestType from '@/types/request';
import {logEvent} from '@/utils/analytics';
import {getDefaultFieldValues} from '@/utils/helpers';

type ContactScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'Contact'
>;
interface Props {
    hasBackButton?: boolean;
    navigation: ContactScreenNavigationProp;
    type: 'contact' | 'feedback';
}

const SCHEMAS = {
    contact: contactSchema,
    feedback: feedbackSchema
};

const ContactFormScreen: React.FC<Props> = ({
    hasBackButton = true,
    navigation,
    type
}: Props) => {
    const form = useForm(true);
    const {settings} = useSettings();
    const [formStatus, setFormStatus] = useState<RequestType>(
        RequestType.DEFAULT
    );
    const initialValues = getDefaultFieldValues(SCHEMAS[type].schema.fields);

    const handleFormError = () => {
        setFormStatus(RequestType.DEFAULT);
        toast(i18n.t(`${type}.form.error`), {icon: 'error', id: 'form-error'});
    };

    const handleFormSuccess = async (response: ApiResponse<any>) => {
        if (response.data) {
            setFormStatus(RequestType.SUCCESS);
            await logEvent('form_submitted', {type});
            // navigation.goBack();
        } else {
            /**
             * TODO: Handle unexpected response?
             */
        }
    };

    const handleFormSubmit = values => {
        if (formStatus === RequestType.PENDING) {
            return;
        }

        setFormStatus(RequestType.PENDING);

        let formattedValues = omit(values, ['consent']);

        if (type === 'contact') {
            /**
             * TODO: This field is no longer required
             */
            formattedValues.preferred_nrc = true;
        } else if (type === 'feedback') {
            formattedValues.problems = [formattedValues.problems];
            formattedValues.feedback = !values.willing_to_share_more
                ? 'N/A'
                : values.feedback;
        }

        form.submit({
            onError: handleFormError,
            onSuccess: response => handleFormSuccess(response),
            request: {
                config: {body: formattedValues},
                url: `/api/forms/${type}/submit/`
            }
        });
    };

    return (
        <Screen>
            <ScreenHeader hasBackButton={hasBackButton} theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero title={i18n.t(`${type}.title`)} />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <Text className="font-default leading-6 mb-12 text-md">
                        {i18n.t(`${type}.text`)}
                    </Text>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validateOnBlur={true}
                        validateOnChange={false}
                        validationSchema={SCHEMAS[type].schema}
                    >
                        {({handleSubmit}) => (
                            <FormBuilder
                                errors={form.errors}
                                form={SCHEMAS[type].fields}
                                handleSubmit={handleSubmit}
                                name={type}
                                options={{
                                    problems:
                                        settings?.feedback_problems?.map(i => ({
                                            label: i.name,
                                            value: i.id
                                        })) || null
                                }}
                                status={formStatus}
                                submitLabel={i18n.t('signIn.form.submit')}
                            />
                        )}
                    </Formik>
                </View>
            </View>
        </Screen>
    );

    return (
        <UI.SafeAreaView theme="tertiary">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validationSchema={SCHEMAS[type].schema}
            >
                {({handleSubmit, resetForm}) => {
                    const handleRefresh = () => {
                        resetForm();
                        setFormStatus(RequestType.DEFAULT);
                    };

                    return (
                        <UI.View flex={1} theme="app">
                            <UI.ScreenScrollView>
                                <ScreenHeader
                                    hasBackButton={hasBackButton}
                                    theme="tertiary"
                                />
                                <ScreenHero>
                                    <UI.Box>
                                        <UI.Heading size="h1">
                                            {i18n.t(`${type}.title`)}
                                        </UI.Heading>
                                        <UI.Spacer size="sm" />
                                        <UI.Text>
                                            {i18n.t(`${type}.text`)}
                                        </UI.Text>
                                    </UI.Box>
                                </ScreenHero>
                                <UI.View contained={true} flex={1} theme="app">
                                    <UI.Box sizeVertical="section">
                                        {formStatus === RequestType.SUCCESS ? (
                                            <UI.Text medium>
                                                {i18n.t(`${type}.form.success`)}
                                            </UI.Text>
                                        ) : (
                                            <FormBuilder
                                                errors={form.errors}
                                                form={SCHEMAS[type].fields}
                                                handleSubmit={handleSubmit}
                                                hasSubmitButton={false}
                                                name={type}
                                                options={{
                                                    problems:
                                                        settings?.feedback_problems?.map(
                                                            i => ({
                                                                label: i.name,
                                                                value: i.id
                                                            })
                                                        ) || null
                                                }}
                                                status={formStatus}
                                            />
                                        )}
                                        {formStatus === RequestType.SUCCESS ? (
                                            <>
                                                <Button
                                                    isGhost={true}
                                                    label={i18n.t(
                                                        `${type}.form.refresh`
                                                    )}
                                                    onPress={handleRefresh}
                                                />
                                                <UI.Spacer size="sm" />
                                                <Button
                                                    label={i18n.t(
                                                        `${type}.form.goBack`
                                                    )}
                                                    onPress={() => {
                                                        if (
                                                            type === 'contact'
                                                        ) {
                                                            navigation.push(
                                                                'Root'
                                                            );
                                                        } else {
                                                            navigation.goBack();
                                                        }
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <Button
                                                label={i18n.t(
                                                    `${type}.form.submit`
                                                )}
                                                onPress={handleSubmit}
                                                status={formStatus}
                                            />
                                        )}
                                    </UI.Box>
                                </UI.View>
                            </UI.ScreenScrollView>
                        </UI.View>
                    );
                }}
            </Formik>
        </UI.SafeAreaView>
    );
};

export default ContactFormScreen;
