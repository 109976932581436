import React, {useContext, useRef} from 'react';
import {Animated, Platform, Text, View} from 'react-native';
import {SafeAreaInsetsContext} from 'react-native-safe-area-context';
import clsx from 'clsx';
import Icon from '@/components/Icon';
import * as S from './styles';

// TODO: Move this to a separate file
const THEMES = {
    default: {
        bg: 'bg-blp-yellow-light border-blp-yellow',
        text: 'text-black'
    },
    error: {
        bg: 'bg-blp-red-light border-blp-red border-dashed',
        text: 'text-blp-red'
    }
};

const Toast = ({t, updateHeight, offset}) => {
    const insets = useContext(SafeAreaInsetsContext);
    const fadeAnim = useRef(new Animated.Value(0.5)).current;
    const posAnim = useRef(new Animated.Value(-80)).current;

    React.useEffect(() => {
        Animated.timing(fadeAnim, {
            duration: 300,
            toValue: t.visible ? 1 : 0,
            useNativeDriver: Platform.OS !== 'web'
        }).start();
    }, [fadeAnim, t.visible]);

    React.useEffect(() => {
        Animated.spring(posAnim, {
            toValue: t.visible ? offset : -80,
            useNativeDriver: Platform.OS !== 'web'
        }).start();
    }, [posAnim, offset, t.visible]);

    const type = t.icon === 'error' ? 'error' : 'default';

    return (
        <View style={{marginTop: insets.top / 2}}>
            <Animated.View
                pointerEvents="none"
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    zIndex: t.visible ? 9999 : undefined,
                    alignItems: 'center',
                    opacity: fadeAnim,
                    transform: [{translateY: posAnim}]
                }}
            >
                <View
                    className={clsx(
                        'items-center flex-1 flex-shrink-0 flex-row justify-center my-10 mx-6 py-2.5 rounded-xl px-5 max-w-10/12 shadow-lg',
                        {[THEMES[type].bg]: type}
                    )}
                    key={t.id}
                    onLayout={event => {
                        updateHeight(t.id, event.nativeEvent.layout.height);
                    }}
                >
                    {t.icon && (
                        <View className="flex-shrink-0 mr-2">
                            <Icon name={t.icon} style={S.icon} />
                        </View>
                    )}
                    <Text
                        className={clsx(
                            'font-medium text-sm text-left text-black',
                            {[THEMES[type].text]: type}
                        )}
                    >
                        {t.message}
                    </Text>
                </View>
            </Animated.View>
        </View>
    );
};

export default Toast;
