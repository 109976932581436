import React from 'react';
import {View} from 'react-native';
import * as Linking from 'expo-linking';
import Svg, {Path} from 'react-native-svg';
import {StackScreenProps} from '@react-navigation/stack';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import VideoDownloadModal from '@/components/Modal/VideoDownload';
import ScalableImage from '@/components/ScalableImage';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import Video from '@/components/Video';
import * as UI from '@/components/UI/styles';
import useModal from '@/hooks/useModal';
import useResources from '@/hooks/useResources';
import type {RootStackParamList} from '@/types/navigation';
import {Visibility} from '@/types/ui';

type Props = StackScreenProps<RootStackParamList, 'ResourcesDetail'>;

const ResourcesDetailScreen: React.FC<Props> = ({route}: Props) => {
    const {resource, resourceId, backToScreen} = route.params;
    const downloadModal = useModal('video_download');
    const {isExternal, isImage, isPdf, isVideo} = useResources();
    const isExternalResource = isExternal(resource) || isPdf(resource);

    return (
        <>
            <Screen
                footer={
                    isExternalResource ? (
                        <Button
                            onPress={() => {
                                if (resource.resource.url) {
                                    Linking.openURL(resource.resource.url);
                                }
                            }}
                        >
                            <View className="flex flex-row flex-grow justify-center">
                                <Svg
                                    width="36"
                                    height="36"
                                    fill="none"
                                    viewBox="0 0 36 36"
                                    style={{
                                        width: 30,
                                        marginBottom: -6,
                                        marginTop: -6,
                                        height: 30
                                    }}
                                >
                                    <Path
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M27 19.5v9a3 3 0 0 1-3 3H7.5a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h9M22.5 4.5h9v9M15 21 31.5 4.5"
                                    />
                                </Svg>
                            </View>
                        </Button>
                    ) : null
                }
                footerSize={isExternal ? 'oneCta' : null}
            >
                <ScreenHeader backToScreen={backToScreen || 'Resources'} />
                <View className="mx-auto w-full max-w-2xl">
                    <View className="p-4">
                        {resource.title && (
                            <>
                                <UI.Heading size="h3">
                                    {resource.title}
                                </UI.Heading>
                                <UI.Spacer />
                            </>
                        )}
                        {resource.description && (
                            <>
                                <UI.Text>{resource.description}</UI.Text>
                                <UI.Spacer />
                            </>
                        )}
                    </View>
                </View>
                {isImage(resource) && (
                    <ScalableImage source={resource.resource.url} />
                )}
                {isVideo(resource) && (
                    <Video
                        handleDownloadModalOpen={() => {
                            downloadModal.open({
                                courseId: resourceId,
                                qualities: {low: resource.resource.url}
                            });
                        }}
                        id={resourceId}
                        qualities={{low: resource.resource.url}}
                        quality="low"
                        source={resource.resource.url}
                        type="resource"
                    />
                )}
            </Screen>
            {downloadModal.visibility !== Visibility.HIDDEN && (
                <Modal
                    handleClose={() => downloadModal.close()}
                    visibility={downloadModal.visibility}
                >
                    <VideoDownloadModal
                        handleClose={() => downloadModal.close()}
                        id={downloadModal.getParams()?.courseId}
                        qualities={downloadModal.getParams()?.qualities}
                    />
                </Modal>
            )}
        </>
    );
};

export default ResourcesDetailScreen;
