import {useState} from 'react';
import {useRecoilState} from 'recoil';
import useApi from '@/hooks/useApi';
import {resourcesState} from '@/utils/store';
import {RequestType} from '@/types/request';
import {ResourceItem, ResourceItemList} from '@/types/resource';

const useResources = () => {
    const api = useApi();
    const [status, setStatus] = useState(RequestType.DEFAULT);
    const [resources, setAllResources] = useRecoilState(resourcesState);

    const deleteAllResources = () => {
        setAllResources([]);
    };

    const fetchAllResources = async (courseIds?: number[]): Promise<void> => {
        setStatus(RequestType.PENDING);

        try {
            const response = await api.request<ResourceItemList>(
                `/api/resources/list/?page_size=300${
                    courseIds?.length
                        ? `&course_ids=${courseIds.join(',')}`
                        : ''
                }`
            );

            if (response?.data) {
                setAllResources(response.data);
                setStatus(RequestType.DEFAULT);
            }
        } catch (error) {
            setStatus(RequestType.ERROR);
        }
    };

    const getResource = (id: number) => {
        return resources.find(resource => resource.id === id);
    };

    const getResourceExtension = (resource: ResourceItem) => {
        const url = resource.resource.url;
        const fileExtension = url.substring(url.lastIndexOf('.') + 1);

        return fileExtension;
    };

    const isImage = (resource: ResourceItem) => {
        if (resource?.resource?.type === 'inline other resource') {
            return ['jpg', 'jpeg', 'png', 'gif'].includes(
                getResourceExtension(resource)
            );
        }
    };

    const isVideo = (resource: ResourceItem) => {
        return resource?.resource?.type === 'inline video';
    };

    const isPdf = (resource: ResourceItem) => {
        return resource?.resource?.type === 'inline pdf';
    };

    const isExternal = (resource: ResourceItem) => {
        return resource.resource.type.startsWith('external');
    };

    return {
        allResources: resources,
        deleteAllResources,
        fetchAllResources,
        getResource,
        isExternal,
        isImage,
        isPdf,
        isVideo,
        resources,
        status
    };
};

export default useResources;
