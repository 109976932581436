import React, {ReactNode, useEffect} from 'react';
import {View, Text} from 'react-native';
import i18n from 'i18n-js';
import {useFormikContext} from 'formik';
import Button from '@/components/Button';
import FormFields from './Fields';
import FormErrors from './Errors';
import type {Form, FormattedFormError} from '@/types/form';
import {RequestType} from '@/types/request';

interface Props {
    cancel?: string;
    errors?: FormattedFormError;
    form: Form;
    handleChange?: Function;
    handleFileChange?: Function;
    handleReset?: Function;
    handleSubmit?: Function;
    hasSubmitButton?: boolean;
    name: string;
    options?: {
        [key: string]: {
            label: string;
            value: number | string;
        }[];
    };
    spacing?: string;
    slots?: {
        [key: string]: () => ReactNode;
    };
    status?: RequestType;
    submitLabel?: string;
}

const mergeValidationAndServerErrors = (
    formName: string,
    validationErrors: {[key: string]: string},
    serverErrors: any,
    formSubmitCount: number
) => {
    let formattedValidationErrors = [];

    if (formSubmitCount > 0) {
        formattedValidationErrors = Object.keys(validationErrors).reduce(
            (allErrors, errorKey) => {
                return [
                    ...allErrors,
                    i18n.t(
                        `${formName}.form.${errorKey}_error_${validationErrors[errorKey]}`
                    )
                ];
            },
            []
        );
    }

    const errors = [
        ...(serverErrors?.errors || []),
        ...formattedValidationErrors
    ];

    return {
        errors,
        message:
            serverErrors?.message ||
            (errors.length > 0 ? i18n.t('generic.formErrors') : '')
    };
};

const FormBuilder: React.FC<Props> = ({
    cancel,
    errors,
    form,
    handleChange,
    handleFileChange,
    handleReset,
    handleSubmit,
    hasSubmitButton = true,
    name,
    options,
    slots,
    status = RequestType.DEFAULT,
    submitLabel
}: Props) => {
    const formik = useFormikContext();
    const allFormErrors = mergeValidationAndServerErrors(
        name,
        formik.errors,
        errors,
        formik.submitCount
    );

    return (
        <View className="flex" nativeID="flex-basis-fix">
            <View className="flex">
                {form.sections ? (
                    form.sections.map(section => (
                        <View className="flex" key={section.title}>
                            <Text>{section.title}</Text>
                            <FormFields
                                fields={section.fields}
                                formName={name}
                                handleChange={handleChange}
                                handleFileChange={handleFileChange}
                                options={options}
                                showHidden={false}
                                slots={slots}
                            />
                        </View>
                    ))
                ) : (
                    <FormFields
                        fields={form.fields}
                        formName={name}
                        handleChange={handleChange}
                        handleFileChange={handleFileChange}
                        options={options}
                        showHidden={false}
                        slots={slots}
                    />
                )}
            </View>
            <View className="flex">
                <FormFields
                    fields={form.fields}
                    formName={name}
                    showHidden={true}
                />
                <FormErrors
                    error={allFormErrors}
                    fields={form.sections || form.fields}
                />
                {hasSubmitButton && (
                    <View className="flex mt-6">
                        <Button
                            label={submitLabel || form.submitLabel}
                            onPress={handleSubmit}
                            status={status}
                        />
                    </View>
                )}
            </View>
        </View>
    );
};

export default FormBuilder;
