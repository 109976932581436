import React from 'react';
import {Text} from 'react-native';
import i18n from 'i18n-js';
import Panel from '@/components/Panel';

const ApiViewEmpty: React.FC = () => (
    <Panel>
        <Text className="text-sm leading-5 font-default">
            {i18n.t('generic.empty')}
        </Text>
    </Panel>
);

export default ApiViewEmpty;
