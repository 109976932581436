import {Platform} from 'react-native';
import styled from '@emotion/native';
import {spacing} from '@/utils/constants';
import type {Spacing} from '@/utils/constants';
import {px} from '@/utils/helpers';

export const Container = styled.View<{
    direction?: 'horizontal' | 'vertical';
}>`
    flex: 1;
    flex-direction: ${props =>
        props.direction === 'horizontal' ? 'row' : 'column'};
    justify-content: space-between;
    width: 100%;

    ${Platform.OS === 'web' && 'flex-basis: auto;'}
`;

export const Item = styled.View<{
    direction?: 'horizontal' | 'vertical';
    isFirst: boolean;
    size: Spacing;
}>`
    flex: 1;
    ${props =>
        !props.isFirst
            ? props.direction === 'horizontal'
                ? `margin-start: ${px(spacing[props.size])};`
                : `margin-top: ${px(spacing[props.size])};`
            : ''};

    ${Platform.OS === 'web' && 'flex-basis: auto;'}
`;
