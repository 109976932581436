import React from 'react';
import {Picker} from '@react-native-picker/picker';
import * as S from './styles';

interface Props {
    handleChange: (itemValue: string, itemIndex: number) => void;
    options: {label: string; value: string}[];
    value?: string;
}

const Select: React.FC<Props> = ({handleChange, options, value}: Props) => (
    <Picker selectedValue={value} style={S.input} onValueChange={handleChange}>
        {options.map(({label, value}) => (
            <Picker.Item key={value} label={label} value={value} />
        ))}
    </Picker>
);

export default Select;
