import React from 'react';
import {Image, Platform, Text, View} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import i18n from 'i18n-js';
import {toast} from 'react-hot-toast/src/core/toast';
import Button from '@/components/Button';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';

const ShareScreen: React.FC = () => {
    const handleCopy = async () => {
        const url =
            Platform.OS === 'web'
                ? // ? process.env.PUBLIC_URL
                  'https://blp.app.nrc.no'
                : 'https://play.google.com/store/apps/details?id=com.norwegianrefugeecouncil.blp';

        await Clipboard.setStringAsync(url);
        toast(i18n.t('share.copied'), {
            icon: 'check',
            id: 'link-copied'
        });
    };

    return (
        <Screen
            footer={
                <Button onPress={handleCopy} label={i18n.t('share.copyCta')} />
            }
            footerSize="oneCta"
            hasScrollView={false}
        >
            <ScreenHeader theme="app" />
            <View className="flex-1 mx-auto w-full max-w-2xl mt-[-70px] justify-center flex-grow">
                <View className="px-8">
                    <View>
                        <Image
                            className="mx-auto mb-8"
                            source={require(`@/assets/images/app-badge.png`)}
                            style={{width: 150, height: 150}}
                        />
                    </View>
                    <Text className="text-center font-medium text-2xl">
                        {i18n.t('share.title')}
                    </Text>
                    <Text className="text-center font-default text-md leading-6 mt-2 web:max-w-sm web:mx-auto">
                        {i18n.t('share.text')}
                    </Text>
                </View>
            </View>
        </Screen>
    );
};

export default ShareScreen;
