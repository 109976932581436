import {atom, selectorFamily} from 'recoil';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {recoilPersist} from './persist-store';
import type {AuthUser} from '@/types/auth';
import {Language} from '@/types/languages';
import type {
    AllLearningContent,
    LearningCourse,
    LearningModule
} from '@/types/learning';
import {OfflineQueueItem, RequestType} from '@/types/request';
import {AllSettings} from '@/types/settings';
import type {VideoQuality} from '@/types/video';
import {ResourceItem} from '@/types/resource';

export const STORAGE_KEYS = [
    'authUser',
    'language',
    'settings',
    'learningContent',
    'videoQuality'
];

export const clearStorage = async () => {
    try {
        await AsyncStorage.multiRemove(STORAGE_KEYS);
    } catch (error) {
        console.log(error);
    }
};

/**
 * WIP workaround for race conditions when saving multiple updates asynchronously.
 * Instead of persisting the full state object, we can instead persist each atom
 * individually.
 */
const authUserPersist = recoilPersist({
    key: 'authUser',
    storage: AsyncStorage
}).persistAtom;
const languagePersist = recoilPersist({
    key: 'language',
    storage: AsyncStorage
}).persistAtom;
const languageScreenNextScreenPersist = recoilPersist({
    key: 'languageScreenNextScreen',
    storage: AsyncStorage
}).persistAtom;
const settingsPersist = recoilPersist({
    key: 'settings',
    storage: AsyncStorage
}).persistAtom;
const learningContentPersist = recoilPersist({
    key: 'learningContent',
    storage: AsyncStorage
}).persistAtom;
const videoQualityPersist = recoilPersist({
    key: 'videoQuality',
    storage: AsyncStorage
}).persistAtom;
const offlineQueuePersist = recoilPersist({
    key: 'offlineQueue',
    storage: AsyncStorage
}).persistAtom;
const resourcesPersist = recoilPersist({
    key: 'resources',
    storage: AsyncStorage
}).persistAtom;
const wellbeingSubmissionPersist = recoilPersist({
    key: 'wellbeingSubmission',
    storage: AsyncStorage
}).persistAtom;
const remindersPersist = recoilPersist({
    key: 'reminders',
    storage: AsyncStorage
}).persistAtom;

export const authUserState = atom<Partial<AuthUser>>({
    key: 'authUser',
    default: null,
    effects_UNSTABLE: [authUserPersist]
});

export const languageState = atom<Language>({
    key: 'language',
    default: null
    //     effects_UNSTABLE: [languagePersist]
});

export const isLayoutUpdatingState = atom<boolean>({
    key: 'isLayoutUpdating',
    default: false
});

export const languageDirectionState = atom<'ltr' | 'rtl'>({
    key: 'languageDirection',
    default: null
});

export const settingsState = atom<AllSettings>({
    key: 'settings',
    default: null,
    effects_UNSTABLE: [settingsPersist]
});

/**
 * This is a workaround for having locale used as a key on the parent `App` component.
 * Without this, changing the locale will cause the language selection screen to refresh
 * before the user will be able to continue.
 */
export const languageScreenNextScreenState = atom<
    'SignUpOnboarding' | 'SignIn' | 'Root'
>({
    key: 'languageScreenNextScreen',
    default: null,
    effects_UNSTABLE: [languageScreenNextScreenPersist]
});

//

/**
 * This acts as the key for triggering learning state fetches. When we
 * update the timestamp, Recoil will automatically update 'learningContentState'
 * as it uses this as a dependency.
 */

export const LEARNING_CONTENT_DEFAULT = {
    courses: null,
    lastFetched: null,
    modules: null,
    progress: {
        currentCourseId: null,
        progress: '0%'
    }
};

export const learningContentState = atom<AllLearningContent>({
    key: 'learningContent',
    default: LEARNING_CONTENT_DEFAULT,
    effects_UNSTABLE: [learningContentPersist]
});

export const learningContentFetchStatusState = atom<RequestType>({
    key: 'learningContentFetchStatus',
    default: RequestType.DEFAULT
});

/**
 * TODO: Figure out this recoil/typescript mess..
 */
export const learningContentList = selectorFamily({
    key: 'learningContentList',
    get:
        ([type, key, id]: ['courses' | 'modules', string, number]) =>
        ({
            get
        }):
            | LearningModule
            | LearningCourse
            | Array<LearningCourse | LearningModule> => {
            const content: AllLearningContent = get(learningContentState);

            if (id) {
                // 😩
                const items = (content as any)[type]?.filter(
                    (i: LearningModule | LearningCourse) => i[key] === id
                );

                return items || [];
            } else {
                return content?.[type];
            }
        }
});

//

export const videoQualityState = atom<VideoQuality>({
    key: 'videoQuality',
    default: 'low',
    effects_UNSTABLE: [videoQualityPersist]
});

export const videoDownloadsState = atom<string[]>({
    key: 'videoDownloads',
    default: []
});

//

export const offlineQueueState = atom<OfflineQueueItem[]>({
    key: 'offlineQueue',
    default: [],
    effects_UNSTABLE: [offlineQueuePersist]
});

//

export const fetchedOnAppLaunchState = atom<boolean>({
    key: 'fetchedOnAppLaunch',
    default: false
});

//

export const resourcesState = atom<ResourceItem[]>({
    key: 'resources',
    default: [],
    effects_UNSTABLE: [resourcesPersist]
});

//

export const wellbeingSubmissionStateDefault = {
    lastSubmitted: null,
    value: null
};
export const wellbeingSubmissionState = atom<{
    lastSubmitted: number | null;
    value: number | null;
}>({
    key: 'wellbeingSubmission',
    default: wellbeingSubmissionStateDefault,
    effects_UNSTABLE: [wellbeingSubmissionPersist]
});

//

export const remindersStateDefault = {
    enabled: false,
    hasBeenPrompted: false
};
export const remindersState = atom<{
    enabled: boolean;
    hasBeenPrompted: boolean;
}>({
    key: 'reminders',
    default: remindersStateDefault,
    effects_UNSTABLE: [remindersPersist]
});
