import {Platform} from 'react-native';
import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {colors, fontFamilies, radius, spacing} from '@/utils/constants';

export const Container = styled.View<{
    platform: string;
}>`
    align-items: center;
    background-color: ${colors.white};
    border: 1px solid ${colors.greyLight};
    border-radius: ${px(radius.md)};
    flex-direction: row;
    height: 54px;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
`;

export const input = css`
    border: 0;,
    color: black;
    font-family: ${fontFamilies.medium};
    padding-left: ${px(spacing.lg)};
    padding-right: 0;
    min-width: 180px;
`;

export const placeholder = css`
    color: black;
    font-family: ${fontFamilies.medium};
`;
