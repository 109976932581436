import styled, {css} from '@emotion/native';
import Constants from 'expo-constants';
import {px} from '@/utils/helpers';
import {
    colors,
    fontFamilies,
    fontSizes,
    radius,
    spacing
} from '@/utils/constants';

export const Toast = styled.View`
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
`;

const COLORS = {
    default: 'yellowLight',
    error: 'redLight'
};

export const ToastContainer = styled.View<{
    type: 'default' | 'error';
}>`
    align-items: center;
    background-color: ${props => colors[COLORS[props.type]]};
    border-radius: ${px(radius.md)};
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    flex: 1;
    flex-basis: auto;
    flex-direction: row;
    justify-content: center;
    margin: ${px(Constants.statusBarHeight + 10)};
    padding: ${px(spacing.md * 0.75)} ${px(spacing.lg * 0.75)};
    max-width: 80%;
`;

export const Text = styled.Text`
    color: ${colors.black};
    font-family: ${fontFamilies.medium};
    font-size: ${fontSizes.lead};
    text-align: center;
`;

export const IconContainer = styled.View`
    margin-right: ${px(spacing.sm)};
`;

export const icon = css`
    height: 16px;
    width: 16px;
`;
