import {useState} from 'react';
import useApi from '@/hooks/useApi';
import {FormattedFormError} from '@/types/form';
import {RequestType} from '@/types/request';
import {getMessageFromError} from '@/utils/helpers';

interface SubmitOptions {
    onError?: Function;
    onSuccess?: Function;
    request: {
        config: any;
        files?: string[];
        queueOffline?: boolean;
        url: string;
    };
}

const useForm = (withAuth?: boolean) => {
    const api = useApi();
    const [errors, setErrors] = useState<FormattedFormError>(null);
    const [status, setStatus] = useState<RequestType>(RequestType.DEFAULT);

    const reset = () => {
        setErrors(null);
        setStatus(RequestType.DEFAULT);
    };

    const handleError = (error, onError) => {
        const formattedErrors = getMessageFromError(error?.data || error);

        setErrors(formattedErrors);
        setStatus(RequestType.DEFAULT);

        if (onError) {
            onError(formattedErrors);
        } else {
            return error;
        }
    };

    const submit = async (options: SubmitOptions) => {
        reset();
        setStatus(RequestType.PENDING);

        try {
            let formattedOptions = {};

            if (options.request.files?.[0]) {
                const formData = new FormData();

                Object.keys(options.request.config.body).map(key => {
                    formData.append(key, options.request.config.body[key]);
                });

                formattedOptions = {
                    body: formData,
                    headers: {},
                    method: 'post'
                };
            } else {
                formattedOptions = options.request.config;
            }

            const response = await api.request(
                options.request.url,
                formattedOptions,
                options?.request?.queueOffline
            );

            if (response?.status === 'error') {
                return handleError(response?.message, options?.onError);
            } else {
                setStatus(RequestType.SUCCESS);

                if (options.onSuccess) {
                    options.onSuccess(response);
                } else {
                    return response;
                }
            }
        } catch (error) {
            return handleError(error, options?.onError);
        }
    };

    return {
        errors,
        reset,
        submit,
        setErrors,
        setStatus,
        status
    };
};

export default useForm;
