import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {useRecoilValue} from 'recoil';
import i18n from 'i18n-js';
import Icon from '@/components/Icon';
import * as UI from '@/components/UI/styles';
import * as S from './styles';
import useLanguage from '@/hooks/useLanguage';
import {LearningProgressState, LearningModule} from '@/types/learning';
import {getModuleProgressState} from '@/utils/helpers';

const ModuleCard: React.FC<Partial<LearningModule>> = ({
    is_locked,
    progression,
    id,
    title
}: Partial<LearningModule>) => {
    const {direction} = useLanguage();
    const navigation = useNavigation();
    const progressState = getModuleProgressState(progression, is_locked);

    const handlePress = () => {
        navigation.navigate('LearningModule', {moduleId: id, title});
    };

    return (
        <S.Container
            disabled={progressState === LearningProgressState.LOCKED}
            isDisabled={progressState === LearningProgressState.LOCKED}
            onPress={handlePress}
        >
            <S.ProgressBar
                progress={parseInt(progression)}
                progressState={progressState}
            />
            <S.Content>
                <S.TextContainer>
                    <UI.Heading size="lead">{title}</UI.Heading>
                    <UI.Spacer size="xs" />
                    <UI.Text size="small">
                        {i18n.t('learning.completed', {percent: progression})}
                    </UI.Text>
                </S.TextContainer>
                <S.IconContainer>
                    {(progressState === LearningProgressState.NOT_STARTED ||
                        progressState === LearningProgressState.STARTED) && (
                        <Icon
                            name={
                                direction === 'rtl'
                                    ? 'right-arrow-reverse'
                                    : 'right-arrow'
                            }
                            style={S.icon}
                        />
                    )}
                    {progressState === LearningProgressState.COMPLETE && (
                        <Icon name="check" style={S.icon} />
                    )}
                </S.IconContainer>
            </S.Content>
        </S.Container>
    );
};

export default ModuleCard;
