import {useEffect, useState} from 'react';
import {Image} from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import {Asset} from 'expo-asset';
import useAuth from '@/hooks/useAuth';
import {timeout} from '@/utils/helpers';

SplashScreen.preventAutoHideAsync().catch(console.warn);

const useCachedResources = () => {
    useAuth();
    const [isLoadingComplete, setLoadingComplete] = useState(false);

    const cacheImages = images => {
        return images.map(image => {
            if (typeof image === 'string') {
                return Image.prefetch(image);
            } else {
                return Asset.fromModule(image).downloadAsync();
            }
        });
    };

    const loadResourcesAndDataAsync = async () => {
        try {
            const imageAssets = cacheImages([
                require('../assets/images/onboarding/ar/2.png'),
                require('../assets/images/onboarding/ar/1.png'),
                require('../assets/images/onboarding/ar/3.png'),
                require('../assets/images/onboarding/ar/4.png'),
                require('../assets/images/onboarding/ar/5.png'),
                require('../assets/images/onboarding/ar/6.png'),
                require('../assets/images/onboarding/en/1.png'),
                require('../assets/images/onboarding/en/2.png'),
                require('../assets/images/onboarding/en/3.png'),
                require('../assets/images/onboarding/en/4.png'),
                require('../assets/images/onboarding/en/5.png'),
                require('../assets/images/onboarding/en/6.png'),
                require('../assets/images/onboarding/es/1.png'),
                require('../assets/images/onboarding/es/2.png'),
                require('../assets/images/onboarding/es/3.png'),
                require('../assets/images/onboarding/es/4.png'),
                require('../assets/images/onboarding/es/5.png'),
                require('../assets/images/onboarding/es/6.png'),
                require('../assets/images/onboarding/fr/1.png'),
                require('../assets/images/onboarding/fr/2.png'),
                require('../assets/images/onboarding/fr/3.png'),
                require('../assets/images/onboarding/fr/4.png'),
                require('../assets/images/onboarding/fr/5.png'),
                require('../assets/images/onboarding/fr/6.png'),
                require('../assets/images/onboarding/pl/1.png'),
                require('../assets/images/onboarding/pl/2.png'),
                require('../assets/images/onboarding/pl/3.png'),
                require('../assets/images/onboarding/pl/4.png'),
                require('../assets/images/onboarding/pl/5.png'),
                require('../assets/images/onboarding/pl/6.png'),
                require('../assets/images/onboarding/ro/1.png'),
                require('../assets/images/onboarding/ro/2.png'),
                require('../assets/images/onboarding/ro/3.png'),
                require('../assets/images/onboarding/ro/4.png'),
                require('../assets/images/onboarding/ro/5.png'),
                require('../assets/images/onboarding/ro/6.png'),
                require('../assets/images/onboarding/ru/1.png'),
                require('../assets/images/onboarding/ru/2.png'),
                require('../assets/images/onboarding/ru/3.png'),
                require('../assets/images/onboarding/ru/4.png'),
                require('../assets/images/onboarding/ru/5.png'),
                require('../assets/images/onboarding/ru/6.png'),
                require('../assets/images/onboarding/ua/1.png'),
                require('../assets/images/onboarding/ua/2.png'),
                require('../assets/images/onboarding/ua/3.png'),
                require('../assets/images/onboarding/ua/4.png'),
                require('../assets/images/onboarding/ua/5.png'),
                require('../assets/images/onboarding/ua/6.png')
            ]);

            await Promise.all(imageAssets);
        } catch (e) {
            // We might want to provide this error information to an error reporting service
            console.warn(e);
        } finally {
            /**
             * Allow time for persisted user data to be loaded, there is no async functionality
             * in Recoil for this
             */
            await timeout(500);
            setLoadingComplete(true);
            SplashScreen.hideAsync();
        }
    };

    useEffect(() => {
        loadResourcesAndDataAsync();
    }, []);

    return isLoadingComplete;
};

export default useCachedResources;
