import React, {ReactNode} from 'react';
import {Text, TouchableOpacity} from 'react-native';
import clsx from 'clsx';
import useLanguage from '@/hooks/useLanguage';
import type {Color} from '@/utils/constants';

interface Props {
    children: ReactNode;
    color?: Color;
    isCentered?: boolean;
    onPress?: Function;
}

// TODO: Move this to a separate file
const TEXT_THEMES = {
    orange: 'text-blp-orange'
};

const TextButton: React.FC<Props> = ({
    children,
    color = 'orange',
    isCentered,
    onPress
}: Props) => {
    const {direction} = useLanguage();

    return (
        <TouchableOpacity onPress={() => onPress()} className="py-1">
            <Text
                className={clsx(`font-medium py-0.5 underline`, {
                    'align-center': isCentered,
                    'align-left': !isCentered && direction === 'ltr',
                    [TEXT_THEMES[color]]: color
                })}
            >
                {children}
            </Text>
        </TouchableOpacity>
    );
};

export default TextButton;
