import React from 'react';
import {Platform} from 'react-native';
import * as Linking from 'expo-linking';
import i18n from 'i18n-js';
import {Field} from 'formik';
import Button from '@/components/Button';
import Checkbox from '@/components/Form/Checkbox';
import Grid from '@/components/Grid';
import ListItem from '@/components/ListItem';
import TextButton from '@/components/TextButton';
import * as UI from '@/components/UI/styles';
import {RequestType} from '@/types/request';

interface Props {
    handleClose: Function;
    handleSubmit: Function;
    status: RequestType;
}

const SignUpPrivacy: React.FC<Props> = ({
    handleClose,
    handleSubmit,
    status
}: Props) => (
    <Field name="terms">
        {({field}) => (
            <UI.ScreenScrollView nativeID="flex-basis-fix">
                <UI.Box size="section">
                    <UI.Heading size="h4">
                        {i18n.t('signUp.privacyTitle')}
                    </UI.Heading>
                    <UI.Spacer size="sm" />
                    <UI.Text size="lead">
                        {i18n.t('signUp.privacyText')}
                    </UI.Text>
                    <UI.Spacer />

                    <Grid direction="vertical" size="xs">
                        {Array.from(Array(5).keys()).map(i => (
                            <ListItem key={i}>
                                {i18n.t(`signUp.privacyItem${i + 1}`)}
                            </ListItem>
                        ))}
                    </Grid>
                    <UI.Spacer />

                    <TextButton
                        onPress={() => {
                            if (Platform.OS === 'web') {
                                window.open(
                                    i18n.t('signUp.privacyUrl'),
                                    '_blank'
                                );
                            } else {
                                Linking.openURL(i18n.t('signUp.privacyUrl'));
                            }
                        }}
                    >
                        {i18n.t('signUp.privacyUrlCta')}
                    </TextButton>
                    <UI.Spacer size="lg" />

                    <Checkbox
                        label={i18n.t('signUp.privacyConfirm')}
                        name="terms"
                    />
                    <UI.Spacer />

                    <Button
                        isDisabled={!field.value}
                        label={i18n.t('signUp.privacySubmit')}
                        onPress={handleSubmit}
                        status={status}
                    />
                    <UI.Spacer />

                    <TextButton isCentered={true} onPress={handleClose}>
                        {i18n.t('signUp.privacyClose')}
                    </TextButton>
                </UI.Box>
            </UI.ScreenScrollView>
        )}
    </Field>
);

export default SignUpPrivacy;
