import React from 'react';
import {Text, View} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import i18n from 'i18n-js';
import Svg, {Path} from 'react-native-svg';
import Button from '@/components/Button';
import Screen from '@/components/Screen';
import type {RootStackParamList} from '@/types/navigation';

type Props = StackScreenProps<
    RootStackParamList,
    'LearningModuleAssessmentComplete'
>;

const LearningModuleAssessmentCompleteScreen: React.FC<Props> = ({
    navigation
}: Props) => (
    <View className="bg-blp-grey flex flex-grow">
        <Screen
            footer={
                <Button
                    fontSize="lead"
                    label={i18n.t('learning.assessmentCompleteCta')}
                    onPress={() => navigation.push('Learning')}
                />
            }
            footerSize="oneCta"
            hasScrollView={false}
        >
            <View className="flex-1 mx-auto w-full max-w-2xl justify-center">
                <View className="p-4 space-y-3">
                    <View>
                        <Svg width="52" height="52" fill="none">
                            <Path
                                d="M41.656 25.476c-1.425 1.508-2.359 4.43-2.077 6.49l1.288 9.303c.285 2.06-1.012 3.014-2.883 2.121l-8.45-4.038c-1.871-.896-4.923-.883-6.79.026l-8.414 4.105c-1.865.908-3.168-.038-2.9-2.097l1.218-9.315c.269-2.061-.686-4.975-2.123-6.473l-6.491-6.771c-1.439-1.498-.946-3.036 1.09-3.416l9.201-1.716c2.037-.38 4.501-2.193 5.479-4.028L24.21 1.38c.975-1.836 2.584-1.84 3.572-.013l4.47 8.252c.991 1.827 3.467 3.621 5.509 3.984l9.212 1.648c2.04.364 2.544 1.898 1.118 3.407l-6.436 6.817z"
                                fill="#FFAC33"
                            />
                            <Path
                                d="M14.13 3.15c1.478 2.04 3.534 7.102 2.48 7.867-1.05.767-5.209-2.759-6.687-4.8-1.476-2.04-1.35-3.853-.189-4.7 1.161-.848 2.918-.407 4.397 1.633zm27.947 3.067c-1.476 2.041-5.635 5.569-6.689 4.799-1.053-.766 1.004-5.826 2.483-7.865 1.478-2.042 3.232-2.483 4.395-1.634 1.16.847 1.286 2.66-.189 4.7zM24.013 48.085c-.001-2.522 1.297-7.83 2.602-7.83 1.295 0 2.597 5.308 2.595 7.828 0 2.523-1.16 3.917-2.6 3.915-1.435.002-2.597-1.39-2.597-3.913zm23.37-13.378c-2.391-.779-7.018-3.659-6.615-4.904.4-1.24 5.831-.84 8.219-.06 2.39.78 3.352 2.319 2.908 3.693-.445 1.372-2.122 2.05-4.513 1.27zM3.008 29.745c2.39-.78 7.82-1.18 8.224.064.4 1.238-4.229 4.122-6.616 4.9-2.39.78-4.07.1-4.511-1.272-.445-1.372.511-2.91 2.903-3.692z"
                                fill="#FFD983"
                            />
                        </Svg>
                    </View>
                    <View>
                        <Text className="text-5xl font-medium leading-9">
                            {i18n.t('learning.assessmentCompleteTitle')}
                        </Text>
                    </View>
                    <View>
                        <Text className="font-default text-md leading-6">
                            {i18n.t('learning.assessmentCompleteText')}
                        </Text>
                    </View>
                </View>
            </View>
        </Screen>
    </View>
);

export default LearningModuleAssessmentCompleteScreen;
