import React from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import SyncContentButton from './SyncContentButton';

const AppActions: React.FC = () => {
    const navigation = useNavigation();

    return (
        <View className="w-full justify-end flex flex-row space-x-2">
            <SyncContentButton />
            <View>
                <Button
                    isGhost={true}
                    isIcon={true}
                    onPress={() => {
                        navigation.navigate('Settings');
                    }}
                    theme="greyLight"
                >
                    <Icon name="settings" style={{height: 16, width: 16}} />
                </Button>
            </View>
        </View>
    );
};

export default AppActions;
