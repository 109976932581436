import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {colors, spacing} from '@/utils/constants';

export const Container = styled.View`
    margin-top: ${px(spacing.md * -1)};
    padding: ${px(spacing.md)};
`;

export const Header = styled.View`
    align-items: center;
    flex: 1;
    flex-direction: row;
    padding-top: 2px;
`;

export const TimeContainer = styled.View`
    flex: 1;
    flex-direction: row;
`;

export const VideoContainer = styled.View`
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
`;

export const InstructionsContainer = styled.View`
    border-left-width: 4px;
    border-left-color: ${colors.orangeLight};
    padding-left: ${px(spacing.md)};
`;

export const icon = (props: {hasDownloaded: boolean}) => css`
    color: ${props.hasDownloaded ? colors.blue : colors.black};
    height: 12px;
    width: 14px;
`;
