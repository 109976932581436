import {Platform} from 'react-native';
import styled from '@emotion/native';
import {px} from '@/utils/helpers';
import {
    colors,
    fontFamilies,
    FontSize,
    fontSizes,
    radius,
    spacing
} from '@/utils/constants';
import type {Spacing, Color, ThemeProperties} from '@/utils/constants';

export const Container = styled.TouchableOpacity<{
    hasBadge?: boolean;
    isDisabled?: boolean;
    isGhost?: boolean;
    isIcon?: boolean;
    size?: Spacing;
    theme?: ThemeProperties;
}>`
    align-items: center;
    background-color: ${props => props.theme.background};
    border: 1px solid ${props => props.theme.border || props.theme.background};
    border-radius: ${px(radius.lg)};
    flex-direction: row;
    justify-content: space-between;
    padding: ${px(spacing.button)} ${px(spacing.md)};
    width: 100%;

    ${props => props.isDisabled && 'opacity: 0.5;'}
    ${props => props.isGhost && `background-color: transparent;`}
    ${props =>
        props.isIcon &&
        `border-radius: ${px(radius.md)}; padding-top: ${px(
            20
        )}; padding-bottom: ${px(20)}; width: 44px; justify-content: center;`}
    ${props => props.hasBadge && `width: 76px;`}
    ${props => props.size === 'sm' && `padding: ${px(spacing.sm * 1.5)};`}

    ${props => Platform.OS === 'web' && props.isIcon && 'min-width: 50px;'}
`;

export const Label = styled.Text<{
    align?: string;
    hasTitle?: boolean;
    isGhost?: boolean;
    size?: FontSize;
    theme?: ThemeProperties;
    textColor?: Color;
}>`
    color: ${props => props.theme.buttonText};
    font-family: ${fontFamilies.medium};
    font-size: ${px(18)};
    flex-grow: 1;
    font-weight: 500;
    line-height: ${px(18)};
    text-align: ${props => props.align};

    ${props => props.hasTitle && `font-family: ${fontFamilies.default};`}
    ${props =>
        props.size &&
        `
        font-size: ${px(fontSizes[props.size])};
    `}
    ${props =>
        props.textColor &&
        colors[props.textColor] &&
        `color: ${colors[props.textColor]};`}
`;
