import {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import useApi from '@/hooks/useApi';
import {settingsState} from '@/utils/store';

const useSettings = () => {
    const api = useApi();
    const [settings, setSettings] = useRecoilState(settingsState);

    const syncSettings = async () => {
        try {
            const response = await api.request('/api/metadata/all/');

            if (response?.data) {
                setSettings(response.data);
            }
        } catch (error) {
            /**
             * TODO: Handle settings error
             */
            console.log(error);
        }
    };

    useEffect(() => {
        if (!settings) {
            syncSettings();
        }
    }, []);

    return {
        settings,
        syncSettings,
        updateSettings: setSettings
    };
};

export default useSettings;
