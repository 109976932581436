import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import i18n from 'i18n-js';
import Svg, {Path} from 'react-native-svg';
import ApiView from '@/components/ApiView';
import Button from '@/components/Button';
import CourseCard from '@/components/CourseCard';
import Modal from '@/components/Modal';
import Panel from '@/components/Panel';
import ProgressBar from '@/components/ProgressBar';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import VideoDownloadModal from '@/components/Modal/VideoDownload';
import * as UI from '@/components/UI/styles';
import useAuth from '@/hooks/useAuth';
import useLearningContent from '@/hooks/useLearningContent';
import useModal from '@/hooks/useModal';
import type {LearningCourse, LearningModule} from '@/types/learning';
import type {RootStackParamList} from '@/types/navigation';
import {RequestType} from '@/types/request';
import {Visibility} from '@/types/ui';

type Props = StackScreenProps<RootStackParamList, 'LearningModule'>;

const LearningModuleScreen: React.FC<Props> = ({route, navigation}: Props) => {
    const {moduleId, title} = route.params;
    const {isTeacher} = useAuth();
    const {canTakeTest, getAllCourses, getModule, status} =
        useLearningContent();
    const downloadModal = useModal('video_download');
    const module = getModule(moduleId)?.[0] as LearningModule;
    const courses = getAllCourses(moduleId) as LearningCourse[];

    const handleDownloadModalOpen = params => {
        downloadModal.open(params);
    };

    return (
        <>
            <Screen
                footer={
                    courses.length > 0 ? (
                        <Button
                            fontSize="lead"
                            isDisabled={!canTakeTest(module)}
                            label={i18n.t('learning.testCta')}
                            onPress={() => {
                                navigation.push('LearningModuleAssessment', {
                                    moduleId,
                                    title
                                });
                            }}
                        >
                            <Svg
                                width="36"
                                height="36"
                                fill="none"
                                style={{
                                    marginBottom: -10,
                                    marginLeft: 10,
                                    marginTop: -10
                                }}
                            >
                                <Path
                                    d="M13.5 18l3 3 6-6M11.752 7.046a5.131 5.131 0 002.92-1.21 5.131 5.131 0 016.656 0 5.13 5.13 0 002.92 1.21 5.131 5.131 0 014.706 4.706 5.131 5.131 0 001.21 2.92 5.13 5.13 0 010 6.656 5.131 5.131 0 00-1.21 2.92 5.131 5.131 0 01-4.706 4.706 5.131 5.131 0 00-2.92 1.21 5.131 5.131 0 01-6.656 0 5.131 5.131 0 00-2.92-1.21 5.13 5.13 0 01-4.706-4.706 5.131 5.131 0 00-1.21-2.92 5.13 5.13 0 010-6.656 5.131 5.131 0 001.21-2.92 5.13 5.13 0 014.706-4.706z"
                                    stroke="#000"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </Svg>
                        </Button>
                    ) : null
                }
                footerSize={courses.length > 0 ? 'oneCta' : null}
            >
                <ScreenHeader
                    title={module?.title || title}
                    text={i18n.t('learning.completed', {
                        percent: module?.progression || '0%'
                    })}
                />
                <ProgressBar progress={parseInt(module?.progression) || 0} />
                <View className="flex-1 mx-auto w-full max-w-2xl">
                    <View className="p-4 space-y-3">
                        {isTeacher() &&
                            module?.progression !== '100%' &&
                            courses?.length > 0 && (
                                <View className="mb-8">
                                    <Panel
                                        type="warning"
                                        title={i18n.t(
                                            courses?.[0]?.is_locked
                                                ? 'learning.lockedWarningTitle'
                                                : 'learning.orderWarningTitle'
                                        )}
                                    >
                                        <Text className="font-default text-sm leading-5">
                                            {i18n.t(
                                                courses?.[0]?.is_locked
                                                    ? 'learning.lockedWarningText'
                                                    : 'learning.orderWarningText'
                                            )}
                                        </Text>
                                    </Panel>
                                </View>
                            )}
                        <ApiView
                            empty={i18n.t('learning.noCourses')}
                            hasError={status === RequestType.ERROR}
                            isEmpty={!module || courses?.length === 0}
                            isLoading={status === RequestType.PENDING}
                        >
                            <View className="space-y-3">
                                {courses.length > 0 &&
                                    courses.map(course => (
                                        <View key={course.id}>
                                            <CourseCard
                                                key={course.id}
                                                {...course}
                                                handleDownloadModalOpen={
                                                    handleDownloadModalOpen
                                                }
                                                hasIcon={true}
                                                module_title={null}
                                            />
                                        </View>
                                    ))}
                            </View>
                        </ApiView>
                    </View>
                </View>
            </Screen>
            {downloadModal.visibility !== Visibility.HIDDEN && (
                <Modal
                    handleClose={() => downloadModal.close()}
                    visibility={downloadModal.visibility}
                >
                    <VideoDownloadModal
                        handleClose={() => downloadModal.close()}
                        id={downloadModal.getParams()?.courseId}
                        qualities={downloadModal.getParams()?.qualities}
                    />
                </Modal>
            )}
        </>
    );
};

export default LearningModuleScreen;
