import React from 'react';
import {Text, View} from 'react-native';
import clsx from 'clsx';
import {useNavigation} from '@react-navigation/native';
import AppActions from '@/components/AppActions';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import type {Theme} from '@/utils/constants';

interface Props {
    backToScreen?: string | {name: string; params?: any};
    hasAppActions?: boolean;
    hasBackButton?: boolean;
    hasModal?: boolean;
    text?: string;
    theme?: Theme;
    title?: string;
}

// TODO: Move this to a separate file
const THEMES = {
    tertiary: {bg: 'bg-tertiary'},
    app: {bg: 'bg-white'}
};

const ScreenHeader: React.FC<Props> = ({
    backToScreen,
    hasAppActions,
    hasBackButton = true,
    hasModal,
    text,
    theme = 'app',
    title
}: Props) => {
    const navigation = useNavigation();

    return (
        <View
            className={clsx('items-start flex-0 py-2 w-full relative', {
                [THEMES[theme].bg]: theme,
                'min-h-[58px]': hasAppActions || hasBackButton,
                'z-50': !hasModal
            })}
        >
            <View
                className={clsx('max-w-2xl w-full mx-auto flex flex-row', {
                    'min-h-[58px]': hasAppActions || hasBackButton,
                    'min-h-[20px]': !hasAppActions && !hasBackButton
                })}
            >
                {hasBackButton && (
                    <View className="left-4 absolute top-0 z-[100]">
                        <Button
                            isGhost={theme !== 'transparent'}
                            isIcon={true}
                            onPress={() => {
                                if (backToScreen) {
                                    const screenName =
                                        typeof backToScreen === 'string'
                                            ? backToScreen
                                            : backToScreen.name;
                                    const screenParams =
                                        typeof backToScreen === 'string'
                                            ? {}
                                            : backToScreen.params;

                                    navigation.navigate(screenName, {
                                        screenParams
                                    });
                                } else {
                                    navigation.goBack();
                                }
                            }}
                            theme="greyLight"
                        >
                            <Icon
                                name="left-arrow"
                                style={{height: 14, width: 14}}
                            />
                        </Button>
                    </View>
                )}
                <View className="flex items-center justify-center px-20 w-full">
                    {title && (
                        <Text
                            className="font-medium text-lg text-center"
                            numberOfLines={1}
                        >
                            {title}
                        </Text>
                    )}
                    {text && (
                        <Text
                            className="font-default mt-2 text-xs"
                            numberOfLines={1}
                        >
                            {text}
                        </Text>
                    )}
                </View>
                {hasAppActions && (
                    <View className="right-4 absolute top-0">
                        <AppActions />
                    </View>
                )}
            </View>
        </View>
    );
};

export default ScreenHeader;
