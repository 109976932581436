import React, {useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import {useRecoilState} from 'recoil';
import {useIsFocused} from '@react-navigation/native';
import i18n from 'i18n-js';
import {toast} from 'react-hot-toast/src/core/toast';
import Button from '@/components/Button';
import MemoizedLayout from '@/components/MemoizedLayout';
import Panel from '@/components/Panel';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import WellbeingScale from '@/components/WellbeingScale';
import useForm from '@/hooks/useForm';
import RequestType from '@/types/request';
import {wellbeingSubmissionState} from '@/utils/store';

const WellbeingScreen: React.FC = () => {
    const isFocused = useIsFocused();
    const [hasCheckedSubmission, setHasCheckedSubmission] =
        useState<boolean>(false);
    const [wellbeingSubmission, setWellbeingSubmission] = useRecoilState(
        wellbeingSubmissionState
    );
    const [value, setValue] = useState<number | null>(null);
    const form = useForm(true);

    // Can only submit if wellbeing submission has not been made or was
    // not made today
    const hasSubmittedToday = () => {
        if (!wellbeingSubmission.lastSubmitted) return false;

        const lastSubmitted = new Date(wellbeingSubmission.lastSubmitted);
        const today = new Date();
        const wasSubmittedToday =
            lastSubmitted.getDate() === today.getDate() &&
            lastSubmitted.getMonth() === today.getMonth() &&
            lastSubmitted.getFullYear() === today.getFullYear();

        return wasSubmittedToday;
    };

    const canSubmit = hasCheckedSubmission && !hasSubmittedToday();

    const handleChange = (value: number) => {
        setValue(value);
    };

    const handleFormError = response => {
        form.setStatus(RequestType.DEFAULT);
        toast(i18n.t('generic.unknownApiError'), {
            icon: 'error',
            id: 'wellbeing-error'
        });
    };

    const handleFormSuccess = async response => {
        if (response.status === 'ok') {
            setWellbeingSubmission({
                lastSubmitted: new Date().getTime(),
                value
            });
            setHasCheckedSubmission(false);
            form.setStatus(RequestType.SUCCESS);
            toast(i18n.t('wellbeing.form.success'), {
                icon: 'check',
                id: 'wellbeing-success'
            });
        } else {
            handleFormError({message: i18n.t('generic.unknownApiError')});
        }
    };

    const handleFormSubmit = () => {
        if (form.status !== RequestType.DEFAULT) return;

        form.setStatus(RequestType.PENDING);
        form.submit({
            onError: handleFormError,
            onSuccess: handleFormSuccess,
            request: {
                config: {body: {scale: value}},
                url: `/api/wellbeing_reports/`
            }
        });
    };

    useEffect(() => {
        setHasCheckedSubmission(true);
    }, [isFocused]);

    return (
        <Screen
            footer={
                <Button
                    isDisabled={!value || !canSubmit}
                    onPress={handleFormSubmit}
                    label={i18n.t('wellbeing.form.submit')}
                />
            }
            footerSize="oneCta"
        >
            <ScreenHeader theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero
                    text={i18n.t('wellbeingScale')}
                    title={i18n.t('wellbeing.title')}
                />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    {hasCheckedSubmission && !canSubmit && (
                        <View className="mb-8">
                            <Panel
                                title={i18n.t('wellbeing.locked')}
                                type="warning"
                            />
                        </View>
                    )}
                    <View className="px-4 relative">
                        <WellbeingScale
                            handleChange={handleChange}
                            value={
                                canSubmit ? value : wellbeingSubmission?.value
                            }
                        />
                        {!canSubmit && (
                            <View className="absolute w-[120%] left-[-10%] h-[120%] top-[-10%] bg-white opacity-60" />
                        )}
                    </View>
                </View>
            </View>
        </Screen>
    );
};

export default WellbeingScreen;
