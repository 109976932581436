import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {colors} from '@/utils/constants';

interface Props {
    name: string;
    style?: any;
}

const Icon: React.FC<Props> = ({name, style = {}}: Props) => {
    switch (name) {
        case 'check': {
            return (
                <Svg width="16" height="12" fill="none" style={style}>
                    <Path
                        d="M1 7l4 4L15 1"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'check-reverse': {
            return (
                <Svg width="16" height="12" fill="none" style={style}>
                    <Path
                        d="M15 7l-4 4L1 1"
                        stroke="#000"
                        strokeWidth="2"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'cross': {
            return (
                <Svg viewBox="0 0 32 32" style={style}>
                    <Path
                        d="m18.83 16 8.59-8.59a2 2 0 0 0-2.83-2.83L16 13.17 7.41 4.59a2 2 0 0 0-2.82 2.82L13.17 16l-8.58 8.59a2 2 0 1 0 2.83 2.83L16 18.83l8.59 8.59a2 2 0 0 0 2.83-2.83Z"
                        fill="currentColor"
                    />
                </Svg>
            );
        }
        case 'download': {
            return (
                <Svg viewBox="0 0 14 14" style={style}>
                    <Path
                        d="M1 10v.75A2.25 2.25 0 003.25 13h7.5A2.25 2.25 0 0013 10.75V10m-3-3l-3 3m0 0L4 7m3 3V1"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'downloaded': {
            return (
                <Svg viewBox="0 0 14 14" style={style} fill="none">
                    <Path
                        d="M1 8v.75A2.25 2.25 0 003.25 11h7.5A2.25 2.25 0 0013 8.75V8M3.25 3.5l3 3 5.25-5.25"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'error': {
            return (
                <Svg viewBox="0 0 20 20" fill={colors.red} style={style}>
                    <Path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                    />
                </Svg>
            );
        }
        case 'exclamation': {
            return (
                <Svg width="24" height="24" fill="none">
                    <Path
                        d="M11.523 21a1.636 1.636 0 100-3.273 1.636 1.636 0 000 3.273zM13.159 15a1.637 1.637 0 01-3.273 0V4.636a1.636 1.636 0 113.273 0V15z"
                        fill="#CE5C43"
                    />
                </Svg>
            );
        }
        case 'lock': {
            return (
                <Svg
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#000"
                    style={style}
                >
                    <Path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                </Svg>
            );
        }
        case 'refresh': {
            return (
                <Svg
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 14 14"
                    style={style}
                >
                    <Path
                        d="M1 1v3.75h.436m11.518 1.5a6.001 6.001 0 00-11.518-1.5m0 0H4.75M13 13V9.25h-.436m0 0a6.002 6.002 0 01-11.518-1.5m11.518 1.5H9.25"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'left-arrow': {
            return (
                <Svg width="8" height="14" fill="none" style={style}>
                    <Path
                        d="M6.25 12.25L1 7l5.25-5.25"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'right-arrow-reverse': {
            return (
                <Svg width="20" height="16">
                    <Path
                        d="M8 1L1 8m0 0l7 7M1 8h18"
                        stroke="#000"
                        strokeWidth="2"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'right-arrow': {
            return (
                <Svg width="20" height="16" fill="none" style={style}>
                    <Path
                        d="M12 1l7 7m0 0l-7 7m7-7H1"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
        case 'settings': {
            return (
                <Svg
                    width="16"
                    height="16"
                    fill="none"
                    style={style}
                    viewBox="0 0 16 16"
                >
                    <Path
                        d="M6.743 2.238c.32-1.317 2.194-1.317 2.514 0a1.293 1.293 0 001.929.8c1.158-.706 2.482.619 1.777 1.776a1.293 1.293 0 00.799 1.93c1.317.32 1.317 2.193 0 2.513a1.293 1.293 0 00-.8 1.929c.706 1.158-.618 2.482-1.776 1.777a1.293 1.293 0 00-1.93.799c-.32 1.317-2.193 1.317-2.513 0a1.293 1.293 0 00-1.929-.8c-1.157.706-2.482-.618-1.777-1.776a1.293 1.293 0 00-.799-1.93c-1.317-.32-1.317-2.193 0-2.513a1.293 1.293 0 00.8-1.929c-.706-1.157.619-2.482 1.776-1.777a1.293 1.293 0 001.93-.799z"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <Path
                        d="M10.25 8a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            );
        }
    }

    return null;
};

export default Icon;
