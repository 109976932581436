import React, {useState} from 'react';
import {View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import {Formik} from 'formik';
import i18n from 'i18n-js';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import Modal from '@/components/Modal';
import SignUpPrivacyModal from '@/components/Modal/SignUpPrivacy';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import TextButton from '@/components/TextButton';
import useAuth from '@/hooks/useAuth';
import useForm from '@/hooks/useForm';
import useModal from '@/hooks/useModal';
import signUpSchema from '@/schemas/signup';
import {RequestType} from '@/types/request';
import type {RootStackParamList} from '@/types/navigation';
import {Visibility} from '@/types/ui';
import {getDefaultFieldValues} from '@/utils/helpers';

type SignUpScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'SignUp'
>;
interface Props {
    navigation: SignUpScreenNavigationProp;
}

const SignUpScreen: React.FC<Props> = ({navigation}: Props) => {
    const {saveAuthToken, signUp} = useAuth();
    const [formValues, setFormValues] = useState<{[key: string]: string}>(null);
    const [scrollViewHeight, setScrollViewHeight] = useState<number>(0);
    const form = useForm();
    const privacyModal = useModal('privacy');
    const initialFormValues = getDefaultFieldValues(signUpSchema.schema.fields);

    const handleFormError = response => {
        privacyModal.close();
    };

    const handleFormSuccess = async response => {
        if (response.status === 'ok') {
            await saveAuthToken(response.jwt_token);

            setTimeout(() => {
                signUp(response.user);
            }, 500);
        } else {
            handleFormError({message: i18n.t('generic.unknownApiError')});
        }
    };

    const handlePrivacyAgreement = async () => {
        form.setStatus(RequestType.PENDING);

        const values = {
            name: formValues.name,
            phone: formValues.phone,
            password: formValues.phone,
            role: 1
        };

        form.submit({
            onError: handleFormError,
            onSuccess: handleFormSuccess,
            request: {
                config: {body: values},
                url: `/api/user/register/`
            }
        });
    };

    const handleFormSubmit = values => {
        setFormValues(values);
        privacyModal.open();
    };

    return (
        <Screen isScrollable={privacyModal.visibility === Visibility.HIDDEN}>
            <Formik
                initialValues={initialFormValues}
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={false}
                validationSchema={signUpSchema.schema}
            >
                {({handleSubmit}) => (
                    <>
                        <ScreenHeader
                            hasModal={
                                privacyModal.visibility !== Visibility.HIDDEN
                            }
                            theme="tertiary"
                        />
                        <MemoizedLayout>
                            <ScreenHero
                                text={i18n.t('signUp.text')}
                                title={i18n.t('signUp.title')}
                            >
                                <TextButton
                                    onPress={() => navigation.replace('SignIn')}
                                >
                                    {i18n.t('signUp.signIn')}
                                </TextButton>
                            </ScreenHero>
                        </MemoizedLayout>
                        <View className="flex-1 mx-auto w-full max-w-2xl">
                            <View className="p-4">
                                <FormBuilder
                                    errors={form.errors}
                                    form={signUpSchema.fields}
                                    handleSubmit={handleSubmit}
                                    name="signUp"
                                    status={form.status}
                                    submitLabel={i18n.t('signUp.form.submit')}
                                />
                            </View>
                        </View>
                        {privacyModal.visibility !== Visibility.HIDDEN && (
                            <Modal visibility={privacyModal.visibility}>
                                <SignUpPrivacyModal
                                    handleClose={() => privacyModal.close()}
                                    handleSubmit={handlePrivacyAgreement}
                                    status={form.status}
                                />
                            </Modal>
                        )}
                    </>
                )}
            </Formik>
        </Screen>
    );
};

export default SignUpScreen;
