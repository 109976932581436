import React from 'react';
import {Platform, View} from 'react-native';
import Svg, {Path} from 'react-native-svg';
import {toast} from 'react-hot-toast/src/core/toast';
import * as Sentry from 'sentry-expo';
import i18n from 'i18n-js';
import Button from '@/components/Button';
import {logEvent} from '@/utils/analytics';
import saveToCameraRoll from './download';

interface Props {
    file?: string;
    url?: string;
}

const SaveAsset: React.FC<Props> = ({file, url}: Props) => {
    const handleSave = async () => {
        try {
            await saveToCameraRoll(Platform.OS === 'web' ? url : file);
            logEvent('Wellbeing badge saved', {platform: Platform.OS});
            toast(i18n.t('assetSavedToDevice'), {
                icon: 'check',
                id: 'download-success'
            });
        } catch (error) {
            if (Platform.OS === 'web') {
                Sentry.Browser.captureException(error);
            } else {
                Sentry.Native.captureException(error);
            }

            toast(error.message, {icon: 'error', id: 'download-error'});
        }
    };

    return (
        <Button onPress={handleSave}>
            <View className="flex flex-row flex-grow justify-center">
                <Svg
                    width="26"
                    height="26"
                    fill="none"
                    style={{
                        width: 30,
                        marginBottom: -6,
                        marginTop: -6,
                        height: 30
                    }}
                    viewBox="0 0 26 26"
                >
                    <Path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 19v1.5A4.5 4.5 0 0 0 5.5 25h15a4.5 4.5 0 0 0 4.5-4.5V19m-6-6-6 6m0 0-6-6m6 6V1"
                    />
                </Svg>
            </View>
        </Button>
    );
};

export default SaveAsset;
