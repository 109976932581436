import React from 'react';
import {Image, Text, View} from 'react-native';
import i18n from 'i18n-js';
import MemoizedLayout from '@/components/MemoizedLayout';
import SaveAsset from '@/components/SaveAsset';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';

const WellbeingBadgeScreen: React.FC = () => (
    <Screen
        footer={
            <SaveAsset
                file={require(`../../assets/images/wellbeing-badge.png`)}
                url="wellbeing-badge.png"
            />
        }
        footerSize="oneCta"
    >
        <ScreenHeader theme="tertiary" />
        <MemoizedLayout>
            <ScreenHero
                text={i18n.t('wellbeingChampion')}
                title={i18n.t('wellbeingBadge.title')}
            />
        </MemoizedLayout>
        <View className="flex-1 mx-auto w-full max-w-2xl">
            <View className="px-4 py-8">
                <View className="mx-auto mb-4 w-[200px] h-[200px]">
                    <Image
                        source={require(`@/assets/images/wellbeing-badge.png`)}
                        style={{width: 200, height: 200}}
                    />
                </View>
                <Text className="font-default px-4 text-center text-sm leading-5 text-gray-600">
                    {i18n.t('wellbeingBadge.text')}
                </Text>
            </View>
        </View>
    </Screen>
);

export default WellbeingBadgeScreen;
