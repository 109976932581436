import React, {Fragment} from 'react';
import Icon from '@/components/Icon';
import * as UI from '@/components/UI/styles';
import * as S from './styles';
import type {FormFieldOptions} from '@/types/form';

interface Props {
    handleRemove: Function;
    options: FormFieldOptions[];
    values: Array<any>;
}

const SelectedValues = ({handleRemove, options, values}: Props) => (
    <S.SelectedValuesContainer>
        {values?.length > 0 &&
            values.map((value, index) => {
                const label = options.find(i => i.value == value);

                return (
                    <Fragment key={value}>
                        {index > 0 && <UI.Spacer type="width" size="sm" />}
                        <S.SelectedValue onPress={() => handleRemove(value)}>
                            <S.SelectedValueLabel>
                                {label?.label}
                            </S.SelectedValueLabel>
                            <Icon name="cross" style={S.removeIcon} />
                        </S.SelectedValue>
                    </Fragment>
                );
            })}
    </S.SelectedValuesContainer>
);

export default SelectedValues;
