import React from 'react';
import {Platform} from 'react-native';
import i18n from 'i18n-js';
import {useRecoilState} from 'recoil';
import Select from './Select';
import * as S from './styles';
import {VideoQualities} from '@/types/video';
import {logEvent} from '@/utils/analytics';
import {videoQualityState} from '@/utils/store';

interface Props {
    qualities: VideoQualities;
}

const VideoQualitySelect: React.FC<Props> = ({qualities}: Props) => {
    const [quality, setQuality] = useRecoilState(videoQualityState);
    const options = Object.keys(qualities)
        .filter(i => qualities[i])
        .map(i => ({
            label: i18n.t(`learning.${i}_quality_video`),
            value: i
        }));

    if (options.length < 2) {
        return null;
    }

    const handleQualityChange = async quality => {
        await logEvent('video_quality_changed', {quality});
        setQuality(quality);
    };

    return (
        <S.Container platform={Platform.OS}>
            <Select
                handleChange={handleQualityChange}
                options={options}
                value={quality}
            />
        </S.Container>
    );
};

export default VideoQualitySelect;
