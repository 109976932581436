import styled, {css} from '@emotion/native';
import {colors} from '@/utils/constants';

export const Container = styled.View`
    background-color: ${colors.yellowLight};
    height: 8px;
    width: 100%;
`;

export const progressBar = css`
    background-color: ${colors.yellow};
    bottom: 0;
    flex: 1;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;
