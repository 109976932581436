import {object, string} from 'yup';

const schema = object().shape({
    quality: string().required('empty')
});

export const fields = {
    fields: {
        quality: {field: 'input', options: [], type: 'radio'}
    }
};

export default {fields, schema};
