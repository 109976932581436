import React, {useRef} from 'react';
import {Platform, View} from 'react-native';
import {useRecoilValue} from 'recoil';
import Svg, {Path} from 'react-native-svg';
import {Picker} from '@react-native-picker/picker';
import {Field} from 'formik';
import i18n from 'i18n-js';
import SelectedValues from './SelectedValues';
import * as S from './styles';
import useLanguage from '@/hooks/useLanguage';
import type {FormFieldOptions} from '@/types/form';
import {colors, fontFamilies, fontSizes} from '@/utils/constants';

interface Props {
    info?: string;
    isMulti?: boolean;
    label?: string;
    mode?: string;
    name: string;
    options: FormFieldOptions[];
    onSubmitEditing?: Function;
    placeholder?: string;
}

const SelectField: React.FC<Props> = ({
    info,
    isMulti,
    label,
    mode = 'date',
    name,
    options = [],
    onSubmitEditing,
    placeholder,
    ...props
}: Props) => {
    const {direction} = useLanguage();
    const $picker = useRef<RNPickerSelect | null>(null);

    const getOptions = selected => {
        const formattedOptions = options.map(option => ({
            label: option.label,
            value: option.value
        }));

        if (isMulti) {
            return formattedOptions.filter(i =>
                Array.isArray(selected)
                    ? !selected.includes(i.value)
                    : selected != i.value
            );
        } else {
            return formattedOptions;
        }
    };

    const handleRemove = (
        value: any,
        currentValues: any[],
        setFieldValue: Function
    ) => {
        setFieldValue(
            name,
            currentValues.filter(i => i !== value)
        );
    };

    const setValue = (newValue, value, setFieldValue: Function) => {
        if (isMulti) {
            const newArrayValue = Array.isArray(value)
                ? [...value, newValue]
                : [newValue];

            setFieldValue(
                name,
                newArrayValue.filter(v => v)
            );
        } else {
            setFieldValue(name, newValue);
        }
    };

    return (
        <Field
            isMulti={isMulti}
            label={label}
            name={name}
            placeholder={placeholder}
        >
            {({field, form, isFocused}) => {
                return (
                    <S.TextInputContainer>
                        <View
                            style={[
                                S.textInput({
                                    direction,
                                    hasBorder: true,
                                    hasError:
                                        form.touched[field.name] &&
                                        form.errors[field.name],
                                    isActive: isFocused
                                }),
                                {paddingBottom: 6, paddingTop: 6}
                            ]}
                        >
                            <Picker
                                onValueChange={value => {
                                    setValue(
                                        value,
                                        field.value,
                                        form.setFieldValue
                                    );
                                }}
                                ref={$picker}
                                style={{
                                    border: 0,
                                    color: colors.greyDark,
                                    fontFamily: fontFamilies.default,
                                    fontSize: fontSizes.default,
                                    height: 36,
                                    // marginTop: -8,
                                    marginLeft: -8,
                                    textAlign:
                                        direction === 'rtl' ? 'right' : 'left'
                                }}
                                selectedValue={field.value}
                            >
                                {getOptions(field.value).map(option => (
                                    <Picker.Item
                                        key={option.value}
                                        label={option.label}
                                        value={option.value}
                                    />
                                ))}
                            </Picker>
                        </View>
                        {Platform.OS === 'ios' && (
                            <S.TextIconContainer
                                onPress={() => {
                                    if ($picker?.current) {
                                        $picker.current.togglePicker();
                                    }
                                }}
                                style={{height: 48}}
                            >
                                <Svg viewBox="0 0 11 8" width="10" height="10">
                                    <Path
                                        d="M10.50172.94253c.2799.2519.32597.66672.12384.97138l-.0681.08782-4.5 5c-.2731.30345-.73202.32873-1.0361.07586l-.07883-.07586-4.5-5c-.2771-.30789-.25214-.7821.05574-1.0592.2799-.2519.69726-.25417.97901-.02119l.08019.07694L5.5 5.379 9.44253.99828c.2519-.2799.66672-.32597.97137-.12384l.08782.0681z"
                                        fill={colors.greyDark}
                                        fillRule="evenodd"
                                    />
                                </Svg>
                            </S.TextIconContainer>
                        )}
                        {isMulti && (
                            <SelectedValues
                                handleRemove={value =>
                                    handleRemove(
                                        value,
                                        field.value,
                                        form.setFieldValue
                                    )
                                }
                                options={options}
                                values={field.value}
                            />
                        )}
                    </S.TextInputContainer>
                );
            }}
        </Field>
    );
};

export default SelectField;
