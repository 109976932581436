import {useRecoilState, useRecoilValue} from 'recoil';
import {toast} from 'react-hot-toast/src/core/toast';
import i18n from 'i18n-js';
import differenceWith from 'lodash/differenceWith';
import useApi from '@/hooks/useApi';
import useAuth from '@/hooks/useAuth';
import type {
    AllLearningContent,
    LearningCourse,
    LearningModule
} from '@/types/learning';
import RequestType from '@/types/request';
import {
    learningContentFetchStatusState,
    learningContentList,
    learningContentState
} from '@/utils/store';

const useLearningContent = () => {
    const api = useApi();
    const {isTeacher} = useAuth();
    const [learningContent, setLearningContent] =
        useRecoilState(learningContentState);
    const [fetchStatus, setFetchStatus] = useRecoilState(
        learningContentFetchStatusState
    );
    const allModules = learningContent?.modules;
    const getModule = (moduleId: number) =>
        useRecoilValue(learningContentList(['modules', 'id', moduleId]));
    const getAllCourses = (moduleId: number) =>
        useRecoilValue(learningContentList(['courses', 'module_id', moduleId]));
    const getCourse = (courseId: number) =>
        useRecoilValue(learningContentList(['courses', 'id', courseId]));

    const canTakeTest = (module: LearningModule) => {
        let isActive = true;

        if (isTeacher() && module.progression !== '100%') {
            isActive = false;
        }

        return isActive;
    };

    const fetchLearningContent = async (
        path: string,
        method = 'get',
        queueOffline = false
    ) => {
        try {
            const response = await api.request(
                `/api${path}`,
                {method},
                queueOffline
            );

            if (response?.data) {
                return response.data;
            } else if (response && queueOffline) {
                return response;
            }
        } catch (error) {
            console.log(error);
            throw Error(error);
        }
    };

    /**
     * We pass the course as an argument here as, without any additional code to support it, the
     * current course list may be out of sync with a pending update.
     */
    const getOverallProgress = (
        courses: LearningCourse[],
        lastCourseId?: number
    ) => {
        const progress = courses.reduce((totalComplete, course) => {
            return totalComplete + (course.is_done ? 1 : 0);
        }, 0);
        const total = courses.length;
        const totalProgress = `${Math.ceil((progress / total) * 100)}%`;
        let lastCourseIndex = 0;
        let currentCourseId;

        /**
         * If we know the last course ID we can easily get its index from the
         * courses list. However, if we don't, such as when we do a fresh content
         * fetch or fresh login, we'll have to find the last completed course to
         * know what the next course should be. We can do this by finding the index
         * of the first incomplete course and take its left adjacent course.
         */
        if (!lastCourseId) {
            const firstIncompleteCourseIndex = courses
                .map(course => course.is_done)
                .indexOf(false);

            if (firstIncompleteCourseIndex > 0) {
                lastCourseIndex = firstIncompleteCourseIndex - 1;
            }
        } else {
            lastCourseIndex = courses
                .map(course => course.id)
                .indexOf(lastCourseId);
        }

        if (lastCourseIndex + 1 !== courses.length) {
            currentCourseId = courses[lastCourseIndex + 1]?.id;
        }

        return {
            currentCourseId,
            progress: totalProgress
        };
    };

    /**
     * TODO: Remove index when API is synced
     */
    const fetchCourses = async (
        moduleId: number,
        moduleTitle: string,
        moduleIndex: number,
        previousModule?: LearningModule
    ) => {
        try {
            const data = await fetchLearningContent(
                `/modules/${moduleId}/courses/`
            );

            const formattedData = await Promise.all(
                data.map(async (course, index) => {
                    let isLocked = false;

                    if (isTeacher()) {
                        isLocked = true;

                        /**
                         * The first course will always be unlocked, for the next courses we need
                         * to check the state of previous courses.
                         *
                         * A course is unlocked if any of these conditions are true:
                         * - It is the first course of the first module
                         * - It is the first course _following_ a module that has a submitted assessment
                         * - It has already been completed
                         * - The previous course in this module has been completed
                         */
                        const isFirstCourseInFirstModule =
                            moduleIndex === 0 && index === 0;
                        const isFirstCourseFollowingCompletedModule =
                            previousModule?.is_done && index === 0;
                        const isCourseComplete = course.is_done;
                        const isPreviousCourseComplete =
                            data?.[index - 1]?.is_done;

                        if (
                            isFirstCourseInFirstModule ||
                            isFirstCourseFollowingCompletedModule ||
                            isCourseComplete ||
                            isPreviousCourseComplete
                        ) {
                            isLocked = false;
                        }

                        if (__DEV__) {
                            console.log(
                                'course: ' +
                                    course.title +
                                    ', module: ' +
                                    moduleId +
                                    ', locked: ' +
                                    isLocked +
                                    ' previous module: ' +
                                    previousModule?.title,
                                {
                                    isFirstCourseInFirstModule,
                                    isFirstCourseFollowingCompletedModule,
                                    isCourseComplete,
                                    isPreviousCourseComplete,
                                    previousModule,
                                    isTeacher: isTeacher()
                                }
                            );
                        }
                    }

                    return {
                        ...course,
                        is_done: course.is_done,
                        is_locked: isLocked,
                        module_id: moduleId,
                        module_title: moduleTitle,
                        position: index + 1
                    };
                })
            );

            return formattedData;
        } catch (error) {
            console.log(error);
        }
    };

    const sync = async () => {
        setFetchStatus(RequestType.PENDING);

        try {
            const rawModules: LearningModule[] = await fetchLearningContent(
                '/modules/list/'
            );

            if (rawModules?.length) {
                const modules = rawModules.map((module, index) => {
                    let isLocked = false;

                    if (isTeacher()) {
                        /**
                         * The first item will always be unlocked, for the next items we need
                         * to check the state of previous items.
                         */
                        if (
                            index > 0 &&
                            rawModules[index - 1].progression !== '100%'
                        ) {
                            isLocked = true;
                        }
                    }

                    return {
                        ...module,
                        is_locked: isLocked,
                        position: index + 1
                    };
                });
                const rawCourses = await Promise.all(
                    modules.map(async (module, index) => {
                        const courses = await fetchCourses(
                            module.id,
                            module.title,
                            index,
                            index > 0 ? modules[index - 1] : null
                        );

                        return courses;
                    })
                );
                const courses = rawCourses.reduce(
                    (allCourses, courses) => [...allCourses, ...courses],
                    []
                );
                const progress = getOverallProgress(courses);

                setLearningContent(prevStatus => ({
                    courses,
                    lastFetched: new Date().getTime(),
                    modules,
                    progress
                }));
                setFetchStatus(RequestType.DEFAULT);

                return {courses, modules};
            } else {
                setLearningContent({
                    courses: [],
                    lastFetched: new Date().getTime(),
                    modules: [],
                    progress: {
                        currentCourseId: null,
                        progress: '0%'
                    }
                });
                setFetchStatus(RequestType.DEFAULT);
            }
        } catch (error) {
            /**
             * If there is modules already stored we don't want to remove them to show an
             * error UI, so show a notification instead.
             */
            if (allModules?.length) {
                toast(`${i18n.t('generic.apiErrorTitle')}: ${error.message}`, {
                    icon: 'error',
                    id: 'learning-fetch-error'
                });
                setFetchStatus(RequestType.DEFAULT);
            } else {
                setFetchStatus(RequestType.ERROR);
            }
        }
    };

    const unlockNextModule = (moduleId: number) => {
        const updatedModules = learningContent.modules.map(module => ({
            ...module,
            is_done: module.id === moduleId ? true : module.is_done
        }));

        setLearningContent(prevLearningContent => ({
            ...prevLearningContent,
            modules: updatedModules
        }));

        if (isTeacher()) {
            const moduleIds = learningContent.modules.map(module => module.id);
            const currentModuleIndex = moduleIds.indexOf(moduleId);

            if (currentModuleIndex < moduleIds.length - 1) {
                const nextModuleId = moduleIds[currentModuleIndex + 1];
                const updatedCourses = learningContent.courses.map(course => {
                    /**
                     * Unlock the first course of the next module
                     */
                    if (
                        course.module_id === nextModuleId &&
                        course.position === 1
                    ) {
                        return {
                            ...course,
                            is_locked: false
                        };
                    } else {
                        return course;
                    }
                });

                setLearningContent(prevLearningContent => ({
                    ...prevLearningContent,
                    courses: updatedCourses
                }));
            }
        }
    };

    const updateCompletedCourse = (courseId: number) => {
        const moduleCourseCompletion = {};
        const updatedCourses = learningContent.courses.reduce(
            (previousCourses, course, index) => {
                const previousCourse =
                    index > 0 ? previousCourses[index - 1] : null;
                const isSameModule =
                    previousCourse?.module_id === course.module_id;
                const isDone = course.id === courseId ? true : course.is_done;
                let isLocked = false;

                if (isTeacher()) {
                    /**
                     * If we've completed the last course in a module, then we need to
                     * lock the next module's courses. These are only unlocked when the
                     * current module's assessment has been submitted.
                     */
                    if (!isSameModule && previousCourse?.is_done) {
                        isLocked = true;
                    } else {
                        isLocked = isSameModule
                            ? !previousCourse.is_done
                            : course.is_locked;
                    }
                }

                /**
                 * Add this course to the `moduleCourseCompletion` object so we can
                 * calculate scores after we update courses.
                 */
                if (!moduleCourseCompletion[course.module_id]) {
                    moduleCourseCompletion[course.module_id] = [];
                }

                moduleCourseCompletion[course.module_id].push(isDone);

                return [
                    ...previousCourses,
                    {
                        ...course,
                        is_done: isDone,
                        is_locked: isLocked
                    }
                ];
            },
            []
        );
        const updatedModules = learningContent.modules.map(module => {
            if (moduleCourseCompletion[module.id]) {
                /**
                 * TODO: Once assessments have been added, we can then figure
                 * out the progression implementation to match the API
                 */
                const completedCourses = moduleCourseCompletion[
                    module.id
                ].filter(course => course);
                const progression = Math.ceil(
                    (completedCourses.length /
                        moduleCourseCompletion[module.id].length) *
                        100
                );

                return {
                    ...module,
                    progression: `${progression}%`
                };
            } else {
                return module;
            }
        });
        const updatedProgress = getOverallProgress(updatedCourses, courseId);

        setLearningContent({
            ...learningContent,
            ...{
                courses: updatedCourses,
                modules: updatedModules,
                progress: updatedProgress
            }
        });
    };

    const completeCourse = async (courseId: number) => {
        try {
            await fetchLearningContent(
                `/courses/${courseId}/mark_as_completed/`,
                'post',
                true
            );

            updateCompletedCourse(courseId);
            return 'success';
        } catch (error) {
            /**
             * TODO: Add error feedback
             */
            // console.log(error.message);
            toast(error.message || i18n.t('generic.apiErrorText'), {
                icon: 'error',
                id: 'error'
            });
        }
    };

    const getNewContent = (
        prevContent: Pick<AllLearningContent, 'courses' | 'modules'>,
        nextContent: Pick<AllLearningContent, 'courses' | 'modules'>
    ) => {
        const modules = differenceWith(
            nextContent.modules,
            prevContent.modules,
            (p, c) => p.id === c.id
        );
        const courses = differenceWith(
            nextContent.courses,
            prevContent.courses,
            (p, c) => p.id === c.id
        );

        if (modules.length || courses.length) {
            return {
                modules,
                courses
            };
        }
    };

    return {
        allModules,
        canTakeTest,
        getAllCourses,
        completeCourse,
        getCourse,
        getModule,
        getNewContent,
        lastSynced: learningContent.lastFetched,
        overallProgress: learningContent.progress,
        status: fetchStatus,
        syncLearningContent: sync,
        unlockNextModule
    };
};

export default useLearningContent;
