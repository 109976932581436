import {useState} from 'react';
import {Visibility} from '@/types/ui';
import {logEvent} from '@/utils/analytics';

const useModal = (name: string) => {
    const [params, setParams] = useState(null);
    const [visibility, setVisibility] = useState<Visibility>(Visibility.HIDDEN);

    const getParams = () => params;

    const open = async (newParams = {}) => {
        setVisibility(Visibility.MOUNTING);
        setParams(() => newParams);
        setTimeout(() => setVisibility(Visibility.VISIBLE), 50);
        await logEvent('modal_opened', {name, ...newParams});
    };

    const close = () => {
        setVisibility(Visibility.HIDING);
        setParams(null);
        setTimeout(() => setVisibility(Visibility.HIDDEN), 500);
    };

    return {
        close,
        getParams,
        open,
        params,
        setVisibility,
        visibility
    };
};

export default useModal;
