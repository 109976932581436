import React, {useEffect} from 'react';
import {View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import {useRecoilState} from 'recoil';
import {Formik} from 'formik';
import i18n from 'i18n-js';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import TextButton from '@/components/TextButton';
import useAuth from '@/hooks/useAuth';
import useForm from '@/hooks/useForm';
import useLearningContent from '@/hooks/useLearningContent';
import signInSchema from '@/schemas/signin';
import type {RootStackParamList} from '@/types/navigation';
import {RequestType} from '@/types/request';
import {getDefaultFieldValues} from '@/utils/helpers';
import {languageScreenNextScreenState} from '@/utils/store';

type SignUpScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'SignUp'
>;
interface Props {
    navigation: SignUpScreenNavigationProp;
}

const SignInScreen: React.FC<Props> = ({navigation}: Props) => {
    const [languageScreenNextScreen, setLanguageScreenNextScreen] =
        useRecoilState(languageScreenNextScreenState);
    const {saveAuthToken, signIn} = useAuth();
    const {syncLearningContent} = useLearningContent();
    const form = useForm();
    const initialFormValues = getDefaultFieldValues(signInSchema.schema.fields);

    const handleFormError = response => {
        form.setErrors(response);
    };

    const handleFormSuccess = async response => {
        if (response?.status === 'ok') {
            await saveAuthToken(response.jwt_token);

            // Prefill the learning content store before we move to the next screen.
            // Note this should not be awaited.
            syncLearningContent();
            signIn({
                ...response.user,
                role: response.user.role.id
            });
            form.setStatus(RequestType.SUCCESS);
        } else {
            handleFormError({message: i18n.t('generic.unknownApiError')});
        }
    };

    const handleFormSubmit = async formValues => {
        form.setStatus(RequestType.PENDING);

        const values = {
            name: formValues.name,
            phone: formValues.phone,
            password: formValues.phone
        };

        form.submit({
            onError: handleFormError,
            onSuccess: handleFormSuccess,
            request: {
                config: {body: values},
                url: '/api/user/login/'
            }
        });
    };

    /**
     * Cleanup workaround for changing language on the first app screen.
     */
    useEffect(() => {
        if (languageScreenNextScreen) {
            setTimeout(() => {
                setLanguageScreenNextScreen(null);
            }, 1000);
        }
    }, []);

    /**
     * We use the submit count as a key as this will force the `KeyboardAwareScrollView`
     * to render. This is required as, when validation messages are shown, the height change
     * isn't handled correctly and elements appear 'removed' in `ScreenHero`.
     */
    return (
        <Screen>
            <ScreenHeader theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero title={i18n.t('signIn.title')}>
                    <TextButton onPress={() => navigation.replace('SignUp')}>
                        {i18n.t('signIn.signUp')}
                    </TextButton>
                </ScreenHero>
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <Formik
                        initialValues={initialFormValues}
                        onSubmit={handleFormSubmit}
                        validateOnBlur={true}
                        validateOnChange={false}
                        validationSchema={signInSchema.schema}
                    >
                        {({handleSubmit}) => (
                            <FormBuilder
                                errors={form.errors}
                                form={signInSchema.fields}
                                handleSubmit={handleSubmit}
                                name="signIn"
                                status={form.status}
                                submitLabel={i18n.t('signIn.form.submit')}
                            />
                        )}
                    </Formik>
                </View>
            </View>
        </Screen>
    );
};

export default SignInScreen;
