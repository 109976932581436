import {Dimensions, Platform} from 'react-native';
import styled, {css} from '@emotion/native';
import {colors, spacing} from '@/utils/constants';
import {px} from '@/utils/helpers';

const window = Dimensions.get('window');

export const background = ({isHidden}) => css`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    flex: 1;
    flex-shrink: 1;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 300;

    ${isHidden ? 'display: none;' : ''}
    ${Platform.OS === 'web' ? 'height: 100vh;' : ''}
`;

export const safeArea = css`
    background-color: transparent;
    padding-top: ${px(spacing.lg * 1.5)};
`;

export const container = css`
    background-color: #fff;
    border-radius: ${px(spacing.md)};
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    flex: 1;
    flex-shrink: 1;
    height: 100%;
    max-height: ${px(window.height - spacing.md * 2)};
    min-height: 200px;
    overflow: hidden;
    max-width: ${px(
        (window.width < 600 ? window.width : 600) - spacing.md * 2
    )};
    width: 100%;
`;

export const ButtonContainer = styled.View`
    background-color: ${colors.white};
    right: ${px(spacing.sm)};
    position: absolute;
    top: ${px(spacing.sm)};
    z-index: 100;
`;

export const ContentContainer = styled.View`
    align-items: center;
    flex: 1;
    flex-shrink: 1;
    justify-content: center;
    padding: ${px(spacing.sm)};
`;
