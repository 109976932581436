import React, {useCallback, useEffect, useState} from 'react';
import {Platform, View} from 'react-native';
import {StatusBar} from 'expo-status-bar';
import * as SplashScreen from 'expo-splash-screen';
import * as Sentry from 'sentry-expo';
import {
    useFonts,
    Roboto_300Light,
    Roboto_500Medium
} from '@expo-google-fonts/roboto';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {RecoilRoot, useRecoilValue} from 'recoil';
import i18n from 'i18n-js';
import {ThemeProvider} from '@emotion/react';
import Notifications from '@/components/Notifications';
import PushNotificationHandler from '@/components/PushNotificationHandler/index.native';
import StoreDebugger from '@/components/StoreDebugger';
import useCachedResources from '@/hooks/useCachedResources';
import ar from '@/locales/ar.json';
import en from '@/locales/en.json';
import es from '@/locales/es.json';
import fr from '@/locales/fr.json';
import pl from '@/locales/pl.json';
import ro from '@/locales/ro.json';
import ru from '@/locales/ru.json';
import ua from '@/locales/ua.json';
import useAuth from '@/hooks/useAuth';
import useLanguage from '@/hooks/useLanguage';
import Navigation from '@/navigation/index';
import {setUser} from '@/utils/analytics';
import {themes} from '@/utils/constants';
import {languageState} from '@/utils/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

serviceWorkerRegistration.register();

Sentry.init({
    dsn: process.env.SENTRY_DSN || '',
    enableInExpoDevelopment: false,
    debug: true
});

SplashScreen.preventAutoHideAsync();

const translations = {ar, en, es, fr, pl, ro, ru, ua};
export const SUPPORTED_LANGUAGES = Object.keys(translations);

/**
 * Wrapper is extracted so we have access to Recoil
 */
const AppLoadingWrapper: React.FC = () => {
    const {authUser} = useAuth();
    const {direction, getDefaultLanguage, language, setAppLanguage} =
        useLanguage();
    const _language = useRecoilValue(languageState);
    const isLoadingComplete = useCachedResources();
    let [isFontsLoaded] = useFonts({
        Roboto_300Light,
        Roboto_500Medium
    });
    const [appIsReady, setAppIsReady] = useState<boolean>(false);

    i18n.translations = translations;
    i18n.locale = language;
    i18n.fallbacks = true;

    const prepareLanguage = async () => {
        try {
            const defaultLanguage = await getDefaultLanguage();

            setAppLanguage(defaultLanguage, true);
        } catch (error) {
            if (Platform.OS === 'web') {
                Sentry.Browser.captureException(error);
            } else {
                Sentry.Native.captureException(error);
            }
        }
    };

    /**
     * Workaround for flex-basis breaking forms etc...
     * TODO: I've not had much time to play with React Native for Web yet, we
     * need to find the "nice" way of adding global stylesheets.
     */
    const addWebCssFallbacks = () => {
        if (Platform.OS === 'web' && document) {
            const style = document.createElement('style');

            style.innerHTML = `
            #flex-basis-fix * {
                flex-basis: auto !important;
            }
            [data-testid="web_picker"] { 
                border: none;
                border-radius: 12px;
                height: 54px;
                // margin-left: -13.5px;
                padding-left: 9px;
                padding-right: 9px;
            }
            video {
                height: 100%;
                position: absolute;
                width: 100%;
            }
            [data-focusvisible-polyfill]:focus {
                box-shadow: 0px 0px 2px #FF7602;
                outline: none;
            }
            [dir="rtl"] input {
                text-align: right;
            }
            `;
            document.getElementsByTagName('head')[0].appendChild(style);
        }
    };

    useEffect(() => {
        if (authUser?.id) {
            setUser(authUser.id.toString(), authUser.role.toString(), language);
        } else {
            setUser(null, null, language);
        }
    }, [authUser, language]);

    useEffect(() => {
        if (isLoadingComplete && isFontsLoaded && language) {
            setAppIsReady(true);
        }
    }, [isLoadingComplete, isFontsLoaded]);

    useEffect(() => {
        addWebCssFallbacks();
        prepareLanguage();
    }, []);

    const onLayoutRootView = useCallback(async () => {
        if (appIsReady) {
            // This tells the splash screen to hide immediately! If we call this after
            // `setAppIsReady`, then we may see a blank screen while the app is
            // loading its initial state and rendering its first pixels. So instead,
            // we hide the splash screen once we know the root view has already
            // performed layout.
            await SplashScreen.hideAsync();
        }
    }, [appIsReady]);

    if (!appIsReady || !isFontsLoaded) {
        return null;
    }

    return (
        <View onLayout={onLayoutRootView} style={{flex: 1}}>
            <ThemeProvider key={_language} theme={{...themes.app, direction}}>
                <Navigation />
                <Notifications />
            </ThemeProvider>
        </View>
    );
};

const App: React.FC = () => (
    <RecoilRoot>
        <SafeAreaProvider>
            {/* {__DEV__ && <StoreDebugger />} */}
            <StatusBar />
            <PushNotificationHandler />
            <AppLoadingWrapper />
        </SafeAreaProvider>
    </RecoilRoot>
);

export default App;
