import React, {useEffect, useState} from 'react';
import {Animated, Easing, Platform, Text, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import NetInfo from '@react-native-community/netinfo';
import {useRecoilState, useRecoilValue} from 'recoil';
import i18n from 'i18n-js';
import {toast} from 'react-hot-toast/src/core/toast';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import useApi from '@/hooks/useApi';
import useLearningContent from '@/hooks/useLearningContent';
import useOffline from '@/hooks/useOffline';
import useResources from '@/hooks/useResources';
import useSettings from '@/hooks/useSettings';
import {logEvent} from '@/utils/analytics';
import {fetchedOnAppLaunchState, learningContentState} from '@/utils/store';

const SyncContentButton: React.FC = () => {
    const api = useApi();
    const {syncLearningContent, getNewContent} = useLearningContent();
    const navigation = useNavigation();
    const {offlineQueue, setOfflineQueue} = useOffline();
    const {deleteAllResources} = useResources();
    const {syncSettings} = useSettings();
    const learningContent = useRecoilValue(learningContentState);
    const [hasFetchedOnAppLaunch, setHasFetchedOnAppLaunch] = useRecoilState(
        fetchedOnAppLaunchState
    );
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [spinAnim] = useState(new Animated.Value(0));
    const interpolateRotation = spinAnim.interpolate({
        inputRange: [0, 1],
        outputRange: ['360deg', '0deg']
    });
    const animatedStyle = {
        transform: [{rotate: interpolateRotation}]
    };

    const startSpinner = () => {
        Animated.loop(
            Animated.timing(spinAnim, {
                duration: 1500,
                easing: Easing.linear,
                toValue: 1,
                useNativeDriver: Platform.OS !== 'web'
            })
        ).start();
    };

    const stopSpinner = () => {
        Animated.timing(spinAnim, null).stop();
        spinAnim.setValue(0);
    };

    /**
     * TODO: Figure out to use this inside offline hook.
     * Currently, the offline hook uses the api hook, which uses the offline hook.
     * This results in circular loading and breaks...
     */
    const syncOfflineQueue = async (queue = offlineQueue) => {
        // console.log('prepping sync', queue);

        if (queue?.length > 0) {
            // console.log('ready to sync');
            const firstItem = queue[0];

            try {
                // console.log('syncing #1 item', firstItem.url);
                const sync = await api.request(
                    firstItem.url,
                    firstItem.options
                );
                const newQueue = [...queue.slice(1)];
                // console.log('finished sync', newQueue);
                setOfflineQueue(newQueue);

                if (newQueue.length >= 1) {
                    // console.log('ready for another sync');
                    return syncOfflineQueue(newQueue);
                } else {
                    // console.log('nothing left to sync');

                    return sync;
                }
            } catch (error) {
                // console.log('error trying to sync');
                toast(error?.message || '', {
                    icon: 'error',
                    id: 'offline-sync-error'
                });
            }
        }
    };

    const handleSync = async () => {
        if (isSyncing) {
            return;
        }

        try {
            setIsSyncing(true);
            const network = await NetInfo.fetch();

            if (network.isConnected) {
                await syncOfflineQueue();
                await syncSettings();
                deleteAllResources();

                const prevContent = {
                    modules: learningContent.modules,
                    courses: learningContent.courses
                };
                const nextContent = await syncLearningContent();

                await logEvent('content_synced');

                /**
                 * We only want to show the 'new content' screen if we have
                 * cached content.
                 */
                if (prevContent.modules?.length) {
                    const newContent = getNewContent(prevContent, nextContent);

                    if (newContent) {
                        navigation.navigate(
                            'LearningContentUpdates',
                            newContent
                        );
                    }
                }
            } else {
                toast(i18n.t('generic.offlineQueue'), {
                    icon: 'error',
                    id: 'offline-sync-error'
                });
            }
        } catch (error) {
            toast(error.message, {icon: 'error', id: `unknown-sync-error`});
        } finally {
            setIsSyncing(false);
        }
    };

    useEffect(() => {
        if (isSyncing) {
            startSpinner();
        } else {
            stopSpinner();
        }
    }, [isSyncing]);

    useEffect(() => {
        if (!hasFetchedOnAppLaunch) {
            setHasFetchedOnAppLaunch(true);
            handleSync();
        }
    }, []);

    return (
        <Button
            hasBadge={offlineQueue?.length > 0}
            isDisabled={isSyncing}
            isIcon={true}
            isGhost={true}
            onPress={handleSync}
            theme="greyLight"
        >
            <View className="flex flex-row h-[14px] w-[30px] px-2">
                <Animated.View style={animatedStyle}>
                    <Icon name="refresh" />
                </Animated.View>
                {offlineQueue?.length > 0 && (
                    <Text className="text-blp-grey-mid mt-[-1px] font-medium text-sm pl-1 leading-none">
                        {offlineQueue.length}
                    </Text>
                )}
            </View>
        </Button>
    );
};

export default SyncContentButton;
