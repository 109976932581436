import React, {ReactNode} from 'react';
import posed from 'react-native-pose';
import Svg, {Path} from 'react-native-svg';
import Button from '@/components/Button';
import * as UI from '@/components/UI/styles';
import * as S from './styles';
import {Visibility} from '@/types/ui';

interface Props {
    children: ReactNode;
    handleClose?: Function;
    visibility: Visibility;
}

const BackgroundContainer = posed.View({
    visible: {
        opacity: 1
        // transition: {damping: 9, stiffness: 100, type: 'spring'}
    },
    hidden: {
        delay: 150,
        opacity: 0,
        transition: {type: 'spring'}
    }
});

const ModalContainer = posed.View({
    visible: {
        delay: 100,
        opacity: 1,
        y: 0
    },
    hidden: {
        opacity: 0,
        // transition: {type: 'spring'},
        y: 10
    }
});

const Modal: React.FC<Props> = ({children, handleClose, visibility}: Props) => (
    <BackgroundContainer
        initial="hidden"
        pose={visibility === Visibility.VISIBLE ? 'visible' : 'hidden'}
        style={S.background({isHidden: visibility === Visibility.HIDDEN})}
    >
        <ModalContainer
            initial="hidden"
            pose={visibility === Visibility.VISIBLE ? 'visible' : 'hidden'}
            style={S.container}
        >
            <S.ContentContainer>{children}</S.ContentContainer>
            {handleClose && (
                <S.ButtonContainer>
                    <Button
                        isGhost={true}
                        isIcon={true}
                        onPress={handleClose}
                        theme="greyLight"
                    >
                        <Svg width="12" height="12" fill="none">
                            <Path
                                d="M1.5 10.5l9-9m-9 0l9 9"
                                stroke="#000"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </Svg>
                    </Button>
                </S.ButtonContainer>
            )}
        </ModalContainer>
    </BackgroundContainer>
);

export default Modal;
