import React, {memo, ReactNode, useState} from 'react';
import {Platform, View} from 'react-native';

interface Props {
    children?: ReactNode;
}

// This component is a fix for a rendering bug in ScrollViews on Android. It is
// mostly found in screens which have a form inside, which render conditional
// error messages.
//
// I've tried searching and debugging and have no idea what is
// going on, but the bug can be reproduced like so:
// 1. Add a scroll view
// 2. Add a ScreenHero at least, then add a form
// 3. Submit the form to produce errors
// 4. Observe error feedback is rendered, observe ScreenHero is removed
//
// Note that the scroll height is preserved which indicates the view content is
// still there, but it is not rendered. I have confirmed that by adding a height
// to a view once it has rendered, it will not be removed when the bug is triggered.
//
// Additionally, this does not happen on all elements, it seems to be a bit random.
const MemoizedLayout: React.FC<Props> = ({children}: Props) => {
    const [viewHeight, setViewHeight] = useState<number>(null);

    const handleLayout = (event: any) => {
        if (!viewHeight && Platform.OS === 'android') {
            const {height} = event.nativeEvent.layout;
            setViewHeight(height);
        }
    };

    return (
        <View
            className="flex w-full native:flex-grow"
            onLayout={handleLayout}
            style={viewHeight ? {height: viewHeight} : {}}
        >
            {children}
        </View>
    );
};

export default memo(MemoizedLayout);
