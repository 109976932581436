import React, {ReactNode} from 'react';
import {ActivityIndicator, View} from 'react-native';
import * as Linking from 'expo-linking';
import {ThemeProvider} from '@emotion/react';
import Icon from '@/components/Icon';
import MemoizedLayout from '@/components/MemoizedLayout';
import {RequestType} from '@/types/request';
import {FontSize, themes} from '@/utils/constants';
import type {Color, Spacing, Theme} from '@/utils/constants';
import * as S from './styles';

export interface Props {
    align?: 'left' | 'center';
    children?: ReactNode;
    fontSize?: FontSize;
    hasBadge?: boolean;
    isDisabled?: boolean;
    isGhost?: boolean;
    isIcon?: boolean;
    label?: string;
    onPress?: Function;
    size?: Spacing;
    status?: RequestType;
    style?: object;
    textColor?: Color;
    theme?: Theme;
    url?: string;
}

const Button: React.FC<Props> = ({
    align = 'center',
    children,
    fontSize = 'lead',
    hasBadge,
    isDisabled,
    isGhost,
    isIcon,
    label,
    onPress,
    size = 'md',
    status = RequestType.DEFAULT,
    textColor,
    theme = 'primary',
    url
}: Props) => {
    const handlePress = () => {
        if (isDisabled || status === RequestType.PENDING) {
            return;
        }

        if (url) {
            Linking.openURL(url);
        } else if (onPress) {
            onPress();
        }
    };

    return (
        <ThemeProvider theme={themes[theme]}>
            <S.Container
                disabled={isDisabled || status === RequestType.PENDING}
                hasBadge={hasBadge}
                isDisabled={isDisabled || status === RequestType.PENDING}
                isGhost={isGhost}
                isIcon={isIcon}
                onPress={handlePress}
                size={size}
            >
                <MemoizedLayout>
                    <View className="flex items-center flex-grow w-full">
                        {status === RequestType.PENDING && (
                            <View className="flex items-center flex-grow">
                                <ActivityIndicator color="#000" />
                            </View>
                        )}
                        {status === RequestType.SUCCESS && (
                            <View className="flex items-center flex-grow">
                                <Icon
                                    name="check"
                                    style={{height: 20, width: 20}}
                                />
                            </View>
                        )}
                        {(status === RequestType.DEFAULT ||
                            status === RequestType.ERROR) && (
                            <>
                                {!isIcon && label ? (
                                    <View className="flex items-center flex-row w-full flex-grow">
                                        <S.Label
                                            align={align}
                                            isGhost={isGhost}
                                            size={fontSize}
                                            textColor={textColor}
                                        >
                                            {label}
                                        </S.Label>
                                        {children}
                                    </View>
                                ) : (
                                    children
                                )}
                            </>
                        )}
                    </View>
                </MemoizedLayout>
            </S.Container>
        </ThemeProvider>
    );
};

export default Button;
