import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import {toast} from 'react-hot-toast/src/core/toast';
import {Formik} from 'formik';
import i18n from 'i18n-js';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import TextButton from '@/components/TextButton';
import useAuth from '@/hooks/useAuth';
import useForm from '@/hooks/useForm';
import useLearningContent from '@/hooks/useLearningContent';
import useSettings from '@/hooks/useSettings';
import userRoleSchema from '@/schemas/user-role';
import type {RootStackParamList} from '@/types/navigation';
import type {AuthUser} from '@/types/auth';
import RequestType from '@/types/request';
import {logEvent} from '@/utils/analytics';

type SignUpScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'SignUp'
>;
interface Props {
    navigation: SignUpScreenNavigationProp;
}

const SignInScreen: React.FC<Props> = ({navigation}: Props) => {
    const {authUser, updateAuthUser} = useAuth();
    const form = useForm(true);
    const {syncLearningContent} = useLearningContent();
    const {settings} = useSettings();

    const authUserRole = authUser.role?.id || authUser.role;
    const [selectedRole, setSelectedRole] = useState(authUserRole);

    const initialValues = {
        role: authUserRole,
        is_nrc_beneficiary_parent:
            authUserRole == 2 ? authUser.is_nrc_beneficiary : '',
        is_nrc_beneficiary_teacher:
            authUserRole == 1 ? authUser.is_nrc_beneficiary : ''
    };

    const handleFormError = () => {
        form.setStatus(RequestType.DEFAULT);
    };

    const handleComplete = async role => {
        /**
         * When the role changes we need to sync learning content again.
         * This is where courses will be locked or unlocked depending on the role.
         */
        await syncLearningContent();
        await logEvent('user_role_changed', {role});
        navigation.goBack();
    };

    const handleFormSuccess = async values => {
        toast(i18n.t('settingsRole.updated'), {
            icon: 'check',
            id: 'settings-role-updated'
        });
        updateAuthUser({role: values.role});
    };

    const handleFormSubmit = formValues => {
        if (form.status !== RequestType.DEFAULT) return;
        form.setStatus(RequestType.PENDING);
        const values = {...formValues};

        /**
         * Parent/Caregiver option has ID 2. This is too tightly-coupled to the
         * CMS...
         */
        if (values.role == 2) {
            values.is_nrc_beneficiary =
                values.is_nrc_beneficiary_parent || false;
        } else {
            values.is_nrc_beneficiary =
                values.is_nrc_beneficiary_teacher || false;
        }

        /**
         * Remove extra fields, these are separated so we can display
         * different labels depending on which role has been selected...
         */
        delete values.is_nrc_beneficiary_parent;
        delete values.is_nrc_beneficiary_teacher;

        form.setStatus(RequestType.PENDING);
        form.submit({
            onError: handleFormError,
            onSuccess: () => handleFormSuccess(values),
            request: {
                config: {
                    body: {
                        ...authUser,
                        ...values,
                        classroom_size: authUser.classroom_size || 0,
                        family_size: authUser.family_size || 0,
                        school_name: 'N/A'
                    }
                },
                url: `/api/user/update/`
            }
        });
    };

    useEffect(() => {
        /**
         * We have to wait for the auth role to change before we can complete
         * and resync data.
         */
        if (form.status === RequestType.SUCCESS) {
            handleComplete(authUser.role);
        }
    }, [authUser.role]);

    return (
        <Screen>
            <ScreenHeader theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero title={i18n.t('settingsRole.title')} />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validateOnBlur={true}
                        validateOnChange={false}
                        validationSchema={userRoleSchema.schema}
                    >
                        {({handleSubmit, setFieldValue, values}) => (
                            <FormBuilder
                                errors={form.errors}
                                form={userRoleSchema.fields}
                                handleSubmit={handleSubmit}
                                handleChange={newValues => {
                                    // If a user's role changes we must confirm their
                                    // beneficiary status again.
                                    if (newValues.role != selectedRole) {
                                        setSelectedRole(newValues.role);
                                        setFieldValue(
                                            'is_nrc_beneficiary_parent',
                                            ''
                                        );
                                        setFieldValue(
                                            'is_nrc_beneficiary_teacher',
                                            ''
                                        );
                                    }
                                }}
                                name="settingsRole"
                                options={{
                                    role:
                                        settings?.roles?.map(i => ({
                                            label: i.name,
                                            value: i.id
                                        })) || null
                                }}
                                status={form.status}
                                submitLabel={i18n.t('settingsRole.form.submit')}
                            />
                        )}
                    </Formik>
                </View>
            </View>
        </Screen>
    );
};

export default SignInScreen;
