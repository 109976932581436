import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {useRecoilValue} from 'recoil';
import Icon from '@/components/Icon';
import VideoDownload from '@/components/VideoDownload';
import * as UI from '@/components/UI/styles';
import * as S from './styles';
import useOffline from '@/hooks/useOffline';
import {LearningProgressState, LearningCourse} from '@/types/learning';
import type {Color} from '@/utils/constants';
import {getCourseProgressState} from '@/utils/helpers';
import {videoQualityState} from '@/utils/store';

interface Props extends Partial<LearningCourse> {
    backgroundColor?: Color;
    handleDownloadModalOpen?: Function;
    hasIcon?: boolean;
}

const CourseCard: React.FC<Props> = ({
    backgroundColor,
    handleDownloadModalOpen,
    high_quality_video,
    id,
    is_done,
    is_locked,
    low_quality_video,
    medium_quality_video,
    module_id,
    module_title,
    position,
    title
}: Props) => {
    const navigation = useNavigation();
    const progressState = getCourseProgressState(is_done, is_locked);

    /**
     * TODO: Remove this duplicated code
     */
    const {getVideoUri, downloadedVideosList} = useOffline();
    const videoQuality = useRecoilValue(videoQualityState);
    const videoQualities = {
        high: high_quality_video,
        low: low_quality_video,
        medium: medium_quality_video
    };
    const videoUrl = videoQualities?.[videoQuality] || videoQualities.low;
    const hasDownloaded = downloadedVideosList.includes(
        getVideoUri(videoUrl, id)
    );

    const handlePress = () => {
        navigation.navigate('LearningCourse', {
            courseId: id,
            moduleId: module_id,
            title
        });
    };

    return (
        <UI.View>
            <S.Container
                backgroundColor={backgroundColor}
                disabled={
                    progressState === LearningProgressState.LOCKED && !is_done
                }
                isDisabled={
                    progressState === LearningProgressState.LOCKED && !is_done
                }
                onPress={handlePress}
            >
                <S.Content>
                    <S.IconContainer
                        hasBackgroundColor={true}
                        isLocked={is_locked}
                        isComplete={is_done}
                    >
                        {is_done ? (
                            <Icon name="check" style={S.icon} />
                        ) : (
                            <S.Position>{position}</S.Position>
                        )}
                    </S.IconContainer>
                    <S.TextContainer>
                        {module_title && <UI.Text>{module_title}</UI.Text>}
                        <UI.Heading size="lead">{title}</UI.Heading>
                    </S.TextContainer>
                    <S.IconContainer
                        hasBackgroundColor={false}
                        alignRight={true}
                    ></S.IconContainer>
                </S.Content>
            </S.Container>
            {videoQualities && videoQuality && (
                <S.DownloadContainer>
                    <VideoDownload
                        hasDownloaded={hasDownloaded}
                        handleOpen={handleDownloadModalOpen}
                        id={id}
                        qualities={videoQualities}
                        quality={videoQuality}
                    />
                </S.DownloadContainer>
            )}
        </UI.View>
    );
};

export default CourseCard;
