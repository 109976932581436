import React, {ReactNode} from 'react';
import {View} from 'react-native';
import Error from './Error';
import Empty from './Empty';
import Loading from './Loading';

interface Props {
    children: ReactNode;
    empty?: string | ReactNode;
    error?: string | ReactNode;
    hasError?: boolean;
    isEmpty?: boolean;
    isLoading?: boolean;
    loading?: string | ReactNode;
}

const ApiView: React.FC<Props> = ({
    children,
    empty,
    error,
    hasError,
    isEmpty,
    isLoading,
    loading
}: Props) => {
    if (isLoading) {
        return <View>{loading ? <>{loading}</> : <Loading />}</View>;
    } else if (hasError) {
        return <View>{error ? <>{error}</> : <Error />}</View>;
    } else if (isEmpty) {
        return <View>{empty ? <>{empty}</> : <Empty />}</View>;
    } else {
        return <View>{children}</View>;
    }
};

export default ApiView;
