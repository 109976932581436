import styled from '@emotion/native';
import {fontSizes, spacing} from '@/utils/constants';
import {px} from '@/utils/helpers';

export const Container = styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const Grid = styled.View`
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: ${px(spacing.sm * -1)};
`;

export const GridItem = styled.View`
    padding-left: ${px(spacing.sm)};
    margin-bottom: ${px(spacing.sm)};
    width: 50%;
`;

export const LanguagePreview = styled.Text`
    font-size: ${px(fontSizes.h1)};
    line-height: ${px(fontSizes.h1)};
    text-align-vertical: center;
`;
