import React from 'react';
import {TouchableOpacity} from 'react-native';
import Svg, {Path} from 'react-native-svg';
import {Field} from 'formik';
import * as S from './styles';
import {colors} from '@/utils/constants';

interface Props {
    isChecked?: boolean;
    label: string;
    name: string;
    onLabelPress?: Function;
    onPress?: Function;
}

const CheckboxInput: React.FC<Props> = ({
    isChecked,
    label,
    name,
    onLabelPress,
    onPress
}: Props) => {
    const handlePress = () => {
        if (onPress) {
            onPress();
        }
    };

    return (
        <Field label={label} name={name}>
            {({field, form, ...fieldProps}) => {
                return (
                    <S.CheckboxContainer>
                        <S.CheckboxIconContainer
                            isChecked={field.value}
                            onPress={() => {
                                form.setFieldValue(name, !field.value);
                            }}
                        >
                            {field.value && (
                                <Svg viewBox="0 0 24 24" style={S.checkboxIcon}>
                                    <Path
                                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                                        fill={colors.white}
                                    />
                                </Svg>
                            )}
                        </S.CheckboxIconContainer>

                        <TouchableOpacity
                            onPress={() => {
                                if (onLabelPress) {
                                    onLabelPress();
                                } else {
                                    form.setFieldValue(name, !field.value);
                                }
                            }}
                            style={{flexShrink: 1}}
                        >
                            <S.CheckboxLabel isChecked={isChecked}>
                                {label}
                            </S.CheckboxLabel>
                        </TouchableOpacity>
                    </S.CheckboxContainer>
                );
            }}
        </Field>
    );
};

export default CheckboxInput;
