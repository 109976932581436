import React from 'react';
import {Text, View} from 'react-native';
import i18n from 'i18n-js';
import {useNavigation} from '@react-navigation/native';
import Panel from '@/components/Panel';
import TextButton from '@/components/TextButton';
import * as UI from '@/components/UI/styles';
import {logEvent} from '@/utils/analytics';

const AccountDetailsPrompt: React.FC = () => {
    const navigation = useNavigation();

    const handlePress = async () => {
        await logEvent('account_details_prompt_clicked');
        navigation.navigate('SettingsAccount');
    };

    return (
        <View className="flex mb-4">
            <Panel type="warning" title={i18n.t('accountPrompt.title')}>
                <Text className="mb-2 text-sm leading-5">
                    {i18n.t('accountPrompt.text')}
                </Text>
                <TextButton onPress={handlePress}>
                    {i18n.t('accountPrompt.cta')}
                </TextButton>
            </Panel>
        </View>
    );
};

export default AccountDetailsPrompt;
