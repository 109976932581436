import React, {useState} from 'react';
import {Alert, Platform, Text, View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import * as Application from 'expo-application';
import i18n from 'i18n-js';
import format from 'date-fns/format';
import Button from '@/components/Button';
import MemoizedLayout from '@/components/MemoizedLayout';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import TextButton from '@/components/TextButton';
import useAuth from '@/hooks/useAuth';
import useLearningContent from '@/hooks/useLearningContent';
import type {RootStackParamList} from '@/types/navigation';
import RequestType from '@/types/request';

type SettingsScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'Settings'
>;
interface Props {
    navigation: SettingsScreenNavigationProp;
}

const SettingsScreen: React.FC<Props> = ({navigation}: Props) => {
    const {logout} = useAuth();
    const {lastSynced} = useLearningContent();
    const [status, setStatus] = useState<RequestType>(RequestType.DEFAULT);

    const handleNavigation = (
        screen: keyof RootStackParamList,
        params?: any
    ) => {
        navigation.push(screen, params);
    };

    const processLogout = async () => {
        try {
            setStatus(RequestType.PENDING);
            await logout();
        } catch (error) {
            setStatus(RequestType.DEFAULT);
            console.log(error);
        }
    };

    const handleLogout = () => {
        if (Platform.OS === 'web') {
            processLogout();
        } else {
            Alert.alert(
                i18n.t('settings.logoutAlert.title'),
                i18n.t('settings.logoutAlert.text'),
                [
                    {
                        text: i18n.t('settings.logoutAlert.cancel'),
                        style: 'cancel'
                    },
                    {
                        style: 'destructive',
                        text: i18n.t('settings.logoutAlert.confirm'),
                        onPress: processLogout
                    }
                ]
            );
        }
    };

    return (
        <Screen>
            <ScreenHeader theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero title={i18n.t('settings.title')} />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <View className="space-y-2">
                        <View>
                            <Button
                                isGhost={true}
                                label={i18n.t('settings.account')}
                                onPress={() =>
                                    handleNavigation('SettingsAccount')
                                }
                                theme="greyLight"
                            />
                        </View>
                        <View>
                            <Button
                                isGhost={true}
                                label={i18n.t('settings.role')}
                                onPress={() => handleNavigation('SettingsRole')}
                                theme="greyLight"
                            />
                        </View>
                        <View>
                            <Button
                                isGhost={true}
                                label={i18n.t('settings.language')}
                                onPress={() =>
                                    handleNavigation('SettingsLanguage')
                                }
                                theme="greyLight"
                            />
                        </View>
                        {Platform.OS === 'android' && (
                            <View>
                                <Button
                                    isGhost={true}
                                    label={i18n.t('settings.notifications')}
                                    onPress={() =>
                                        handleNavigation('SettingsReminders')
                                    }
                                    theme="greyLight"
                                />
                            </View>
                        )}
                    </View>
                    <View className="mt-16">
                        <Button
                            label={i18n.t('settings.logout')}
                            onPress={() => handleLogout()}
                            status={status}
                            theme="red"
                        />
                        <View className="mt-4 mx-auto">
                            <TextButton
                                onPress={() =>
                                    Linking.openURL(i18n.t('signUp.privacyUrl'))
                                }
                            >
                                {i18n.t('settings.privacy')}
                            </TextButton>
                        </View>
                        <View className="mx-auto">
                            {Application?.nativeApplicationVersion && (
                                <Text className="text-center font-default leading-6 text-xs">
                                    {i18n.t('generic.version', {
                                        version:
                                            Application.nativeApplicationVersion
                                    })}{' '}
                                    {Platform.OS === 'android' &&
                                        `(${Application.nativeBuildVersion})`}
                                </Text>
                            )}
                            {lastSynced && (
                                <View>
                                    <Text className="text-center font-default text-xs">
                                        {i18n.t('generic.lastSynced', {
                                            date: format(
                                                new Date(lastSynced),
                                                'PPpp'
                                            )
                                        })}{' '}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        </Screen>
    );
};

export default SettingsScreen;
