import i18n from 'i18n-js';
import {Platform} from 'react-native';
import * as Device from 'expo-device';
import {useRecoilValue} from 'recoil';
import useAuth from '@/hooks/useAuth';
import useOffline from '@/hooks/useOffline';
import fetch, {fetchOptions} from '@/utils/fetch';
import {languageState} from '@/utils/store';

const getApiUrl = url => {
    // Handle devices not having access to localhost
    if (Platform.OS === 'android' && process.env.NODE_ENV === 'development') {
        if (Device.isDevice) {
            // return `https://admin.blp.app.nrc.no${url}`,
            return `https://admin.blp-dev.app.nrc.no${url}`;
        } else {
            return `http://10.0.2.2:3000${url}`;
        }
    } else {
        return `${process.env.API_URL}${url}`;
    }
};

const useApi = (withAuth: boolean = true) => {
    const {getAuthToken, logout} = useAuth();
    const {addToOfflineQueue} = useOffline();
    const language = useRecoilValue(languageState);

    const request = async <T>(
        url: string,
        options = {},
        queueOffline = false
    ): Promise<T> => {
        try {
            const token = withAuth ? await getAuthToken() : null;
            const response = await fetch(
                getApiUrl(url),
                fetchOptions(options, language, token)
            );

            return response;
        } catch (error) {
            /**
             * If the reponse is unauthorized then this could include:
             * - The user was removed from the CMS
             * - The user's token is stale
             */
            if (error?.data?.message?.includes('Unauthorized')) {
                await logout(i18n.t('generic.apiErrorTitle'));
            }

            /**
             * If the request failed because of a network error we can
             * optionally add this to the offline queue and it will be
             * pushed with the next online sync.
             */
            if (error.data === 'Network request failed' && queueOffline) {
                addToOfflineQueue({url, options});

                /**
                 * Return so the parent call's "success" flow can run.
                 */
                return;
            }

            throw error;
        }
    };

    return {
        request
    };
};

export default useApi;
