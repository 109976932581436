import {array, object, string} from 'yup';

const schema = object().shape({
    name: string().required('empty'),
    phone: string().min(5, 'empty').max(16, 'empty').required('empty'),
    gender_id: string().required('empty'),
    role: string().required('empty'),
    country_of_residence: string().required('empty').nullable(),
    state_of_residence: string().required('empty').nullable(),
    disability_status: array().of(string()).required('empty'),
    classroom_size: string().when('role', {
        is: (val: string | number) => val == 1,
        then: string().required('empty')
    }),
    family_size: string().when('role', {
        is: (val: string | number) => val == 2,
        then: string().required('empty')
    })
});

export const fields = {
    fields: {
        name: {autoCompleteType: 'name', field: 'input'},
        phone: {
            autoCompleteType: 'tel',
            dataDetectorTypes: 'phoneNumber',
            field: 'input'
        },
        gender_id: {field: 'input', options: [], type: 'radio'},
        role: {field: 'input', type: 'hidden'},
        family_size: {
            field: 'input',
            hidden: [
                ['role', '1'],
                ['role', ''],
                ['role', null]
            ],
            keyboardType: 'number-pad'
        },
        classroom_size: {
            field: 'input',
            hidden: [
                ['role', '2'],
                ['role', ''],
                ['role', null]
            ],
            keyboardType: 'number-pad'
        },
        country_of_residence: {field: 'select', options: []},
        state_of_residence: {
            field: 'select',
            hidden: [['country_of_residence', '']],
            options: []
        },
        disability_status: {field: 'select', isMulti: true, options: []}
    }
};

export default {fields, schema};
