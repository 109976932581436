import {boolean, object, string} from 'yup';

const schema = object().shape({
    nick_name: string().required('empty'),
    phone: string().required('empty'),
    subject: string().required('empty'),
    preferred_time: string().required('empty'),
    consent: boolean().oneOf([true], 'empty')
});

export const fields = {
    fields: {
        nick_name: {field: 'input'},
        phone: {
            autoCompleteType: 'tel',
            dataDetectorTypes: 'phoneNumber',
            field: 'input',
            keyboardType: 'number-pad'
        },
        subject: {field: 'textarea', rows: 2},
        preferred_time: {field: 'input', options: [], type: 'radio'},
        consent: {field: 'input', type: 'checkbox'}
    }
};

export default {fields, schema};
