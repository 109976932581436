import React, {useEffect, useState} from 'react';
import {LayoutChangeEvent} from 'react-native';
import posed from 'react-native-pose';
import * as S from './styles';
import {getProgressBarOffset} from '@/utils/helpers';

interface Props {
    progress: number;
}

const ProgressBarContainer = posed.View({
    visible: {
        opacity: 1,
        transition: {
            stiffness: 300,
            mass: 4,
            damping: 70,
            type: 'spring'
        },
        x: ({xEnd}) => xEnd
    },
    hidden: {
        opacity: ({opacity}) => opacity,
        transition: {type: 'spring'},
        x: ({xStart}) => xStart
    }
});

const ProgressBar: React.FC<Props> = ({progress}: Props) => {
    const [barWidth, setBarWidth] = useState<number>(0);
    const [progressOffset, setProgressOffset] = useState<number>(0);
    const [previousOffset, setPreviousOffset] = useState<number>(null);

    const updateProgress = () => {
        const offset = getProgressBarOffset(progress, barWidth);

        setPreviousOffset(progressOffset);
        setProgressOffset(offset);
    };

    const handleLayoutChange = (event: LayoutChangeEvent) => {
        if (progress > 0) {
            const {width} = event.nativeEvent.layout;

            setBarWidth(width);
        }
    };

    useEffect(() => {
        updateProgress();
    }, [progress, barWidth]);

    return (
        <S.Container key={progress} onLayout={handleLayoutChange}>
            {Number.isInteger(barWidth) &&
                barWidth > 0 &&
                Number.isInteger(progressOffset) && (
                    <ProgressBarContainer
                        initialPose="hidden"
                        pose="visible"
                        key={progressOffset}
                        opacity={previousOffset ? 1 : 0}
                        style={S.progressBar}
                        xEnd={progressOffset}
                        xStart={previousOffset || barWidth * -1}
                    />
                )}
        </S.Container>
    );
};

export default ProgressBar;
