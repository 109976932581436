import React from 'react';
import {View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import i18n from 'i18n-js';
import Button from '@/components/Button';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import type {RootStackParamList} from '@/types/navigation';
import MemoizedLayout from '@/components/MemoizedLayout';

type MoreScreenNavigationProp = StackNavigationProp<RootStackParamList, 'More'>;
interface Props {
    navigation: MoreScreenNavigationProp;
}

const MoreScreen: React.FC<Props> = ({navigation}: Props) => (
    <Screen>
        <ScreenHeader hasBackButton={false} theme="tertiary" />
        <MemoizedLayout>
            <ScreenHero title={i18n.t('more.title')} />
        </MemoizedLayout>
        <View className="flex-1 mx-auto w-full max-w-2xl">
            <View className="p-4 space-y-2">
                <View>
                    <Button
                        isGhost={true}
                        label={i18n.t('more.wellbeing')}
                        onPress={() => {
                            navigation.push('Wellbeing');
                        }}
                        theme="greyLight"
                    />
                </View>
                <View>
                    <Button
                        isGhost={true}
                        label={i18n.t('more.badge')}
                        onPress={() => navigation.push('WellbeingBadge')}
                        theme="greyLight"
                    />
                </View>
                <View>
                    <Button
                        isGhost={true}
                        label={i18n.t('more.feedback')}
                        onPress={() => {
                            navigation.push('Feedback');
                        }}
                        theme="greyLight"
                    />
                </View>
                <View>
                    <Button
                        isGhost={true}
                        label={i18n.t('more.share')}
                        onPress={() => navigation.push('Share')}
                        theme="greyLight"
                    />
                </View>
                <View>
                    <Button
                        isGhost={true}
                        label={i18n.t('more.guide')}
                        onPress={() => navigation.push('Guide')}
                        theme="greyLight"
                    />
                </View>
                <View>
                    <Button
                        isGhost={true}
                        label={i18n.t('more.forum')}
                        onPress={() => {
                            navigation.push('Forum');
                        }}
                        theme="greyLight"
                    />
                </View>
            </View>
        </View>
    </Screen>
);

export default MoreScreen;
