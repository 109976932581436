import React from 'react';
import {Text, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import i18n from 'i18n-js';
import CourseCard from '@/components/CourseCard';
import TextButton from '@/components/TextButton';
import useLearningContent from '@/hooks/useLearningContent';
import type {Color} from '@/utils/constants';

interface Props {
    backgroundColor?: Color;
    ctaType?: 'button' | 'text';
    showProgress?: boolean;
}

const LearningProgress: React.FC<Props> = ({
    backgroundColor = 'white',
    ctaType = 'button',
    showProgress = true
}: Props) => {
    const navigation = useNavigation();
    const {getCourse, overallProgress} = useLearningContent();
    const course = getCourse(overallProgress.currentCourseId)?.[0] || null;
    const hasNextCourse = course && !course.is_locked && !course.is_done;

    if (!parseInt(overallProgress?.progress)) {
        return null;
    }

    const handlePress = () => {
        navigation.navigate('LearningCourse', {
            courseId: course.id,
            moduleId: course.module_id,
            title: course.title
        });
    };

    return (
        <View
            className="mt-4"
            key={overallProgress.progress}
            style={{minHeight: 30}}
        >
            {showProgress && (
                <Text className="font-default text-md leading-6">
                    {i18n.t('learningIndex.totalProgress1')}{' '}
                    <Text className="font-medium text-md">
                        {overallProgress.progress}
                    </Text>{' '}
                    {i18n.t('learningIndex.totalProgress2')}
                </Text>
            )}
            {hasNextCourse && (
                <View className="mt-2">
                    {ctaType === 'button' && (
                        <>
                            <Text className="font-medium text-blp-grey-dark text-md mb-2">
                                {i18n.t('learningIndex.currentCourse')}
                            </Text>
                            <CourseCard
                                backgroundColor={backgroundColor}
                                id={course.id}
                                title={course.title}
                                position={course.position}
                            />
                        </>
                    )}
                </View>
            )}
        </View>
    );
};

export default LearningProgress;
