import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {Field} from 'formik';
import isInteger from 'lodash/isInteger';
import Grid from '@/components/Grid';
import * as S from './styles';
import {colors} from '@/utils/constants';

interface Props {
    hasError?: boolean;
    info?: string;
    label?: string;
    name: string;
    options: {
        label: string;
        value: number | string;
    }[];
    setValue: Function;
    value: boolean | number | string;
}

const CheckboxField: React.FC<Props> = ({
    hasError,
    info,
    label,
    name,
    options = [],
    setValue,
    value
}: Props) => (
    <Field label={label} name={name}>
        {({field, form, isFocused}) => {
            return (
                <Grid direction="vertical" size="sm">
                    {options.map(option => {
                        const isSelected = Array.isArray(field.value)
                            ? field.value.includes(option.value)
                            : false;

                        return (
                            <S.RadioButton
                                key={option.value}
                                onPress={() => {
                                    if (
                                        Array.isArray(field.value) &&
                                        field.value.includes(option.value)
                                    ) {
                                        setValue(
                                            field.value.filter(
                                                value => value !== option.value
                                            )
                                        );
                                    } else {
                                        setValue([
                                            ...(field.value || []),
                                            option.value
                                        ]);
                                    }
                                }}
                            >
                                <S.CheckboxButtonIcon isSelected={isSelected}>
                                    {isSelected && (
                                        <Svg
                                            viewBox="0 0 24 24"
                                            style={{
                                                ...S.checkboxIcon,
                                                marginTop: -1
                                            }}
                                        >
                                            <Path
                                                d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                                                fill={colors.white}
                                            />
                                        </Svg>
                                    )}
                                </S.CheckboxButtonIcon>
                                <S.RadioButtonLabel isSelected={isSelected}>
                                    {option.label}
                                </S.RadioButtonLabel>
                            </S.RadioButton>
                        );
                    })}
                </Grid>
            );
        }}
    </Field>
);

export default CheckboxField;
