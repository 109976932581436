import React, {useEffect, useState} from 'react';
import {ScrollView, Text, View} from 'react-native';
import {StackScreenProps} from '@react-navigation/stack';
import i18n from 'i18n-js';
import {toast} from 'react-hot-toast/src/core/toast';
import CourseContent from '@/components/CourseContent';
import CourseVideo from '@/components/CourseVideo';
import LearningProgress from '@/components/LearningProgress';
import Modal from '@/components/Modal';
import ProgressBar from '@/components/ProgressBar';
import ResourcesList from '@/components/ResourcesList';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import VideoDownloadModal from '@/components/Modal/VideoDownload';
import useLearningContent from '@/hooks/useLearningContent';
import useModal from '@/hooks/useModal';
import type {RootStackParamList} from '@/types/navigation';
import {Visibility} from '@/types/ui';
import type {LearningCourse} from '@/types/learning';

type Props = StackScreenProps<RootStackParamList, 'LearningCourse'>;

const LearningCourseScreen: React.FC<Props> = ({route}: Props) => {
    const {courseId} = route.params;
    const [hasCompleted, setHasCompleted] = useState<boolean>();
    const [orientation, setOrientation] = useState<'landscape' | 'portait'>(
        'portait'
    );
    const downloadModal = useModal('video_download');
    const {completeCourse, getCourse} = useLearningContent();
    const course = getCourse(courseId)?.[0] as LearningCourse;
    const videoQualities = {
        high: course.high_quality_video,
        low: course.low_quality_video,
        medium: course.medium_quality_video
    };

    const handleVideoWatched = async () => {
        if (!course.is_done) {
            try {
                const submit = await completeCourse(courseId);
                if (!submit) return;

                setHasCompleted(true);
                toast(i18n.t('learning.courseCompleted'), {
                    icon: 'check',
                    id: 'learning-course-complete'
                });
            } catch (error) {
                toast(error.message || i18n.t('generic.apiErrorText'), {
                    icon: 'error',
                    id: 'learning-course-error'
                });
            }
        }
    };

    useEffect(() => {
        setHasCompleted(course?.is_done);
    }, [courseId, course]);

    useEffect(() => {
        // used for debugging only
        // handleVideoWatched();
    }, []);

    return (
        <Screen>
            <ScreenHeader title={course.title} />
            <ProgressBar progress={course.is_done ? 100 : 0} />
            <CourseContent {...course} key={orientation} />
            <View className="relative z-50">
                <CourseVideo
                    handleDownloadModalOpen={video => downloadModal.open(video)}
                    handlePlayStatusChanged={isPlaying => {
                        setOrientation(isPlaying ? 'landscape' : 'portait');
                    }}
                    handleVideoWatched={handleVideoWatched}
                    hasCompleted={course.is_done || hasCompleted}
                    id={courseId}
                    qualities={videoQualities}
                    video_length={course.video_length}
                />
            </View>
            <View className="flex mx-auto w-full max-w-2xl">
                <View className="p-4">
                    {course.description && (
                        <Text className="font-default text-md leading-6 whitespace-pre">
                            {course.description
                                .replaceAll('   ', '\n\n')
                                .replaceAll('  ', '\n\n')}
                        </Text>
                    )}
                    {(course.resources?.length > 0 || hasCompleted) && (
                        <View className="mt-8 space-y-4">
                            {course.resources?.length > 0 && (
                                <View>
                                    <Text className="font-medium text-blp-grey-dark text-md mb-2">
                                        {i18n.t('resources.title')}
                                    </Text>
                                    <ResourcesList
                                        backToScreen={{
                                            name: 'LearningCourse',
                                            params: {courseId: courseId}
                                        }}
                                        resources={course.resources}
                                    />
                                </View>
                            )}
                            {hasCompleted && (
                                <LearningProgress
                                    backgroundColor="tertiary"
                                    showProgress={false}
                                />
                            )}
                        </View>
                    )}
                </View>
            </View>
            {downloadModal.visibility !== Visibility.HIDDEN && (
                <Modal
                    handleClose={() => downloadModal.close()}
                    visibility={downloadModal.visibility}
                >
                    <VideoDownloadModal
                        handleClose={() => downloadModal.close()}
                        id={course.id}
                        qualities={videoQualities}
                    />
                </Modal>
            )}
        </Screen>
    );
};

export default LearningCourseScreen;
