import styled, {css} from '@emotion/native';
import {px} from '@/utils/helpers';
import {radius, spacing, themes} from '@/utils/constants';

export const Container = styled.TouchableOpacity<{
    isDisabled?: boolean;
}>`
    background-color: #fff;
    border-radius: ${px(radius.lg)};
    border: 1px solid ${themes.greyLight.background};
    flex: 1;
    flex-basis: auto;
    overflow: hidden;
    width: 100%;

    ${props => props.isDisabled && 'opacity: 0.5;'}
`;

export const Content = styled.View`
    align-items: center;
    flex: 1;
    flex-direction: row;
    flex-basis: auto;
    padding: ${px(spacing.button)} ${px(spacing.md)};w
`;

export const TextContainer = styled.View`
    flex: 1;
`;

export const IconContainer = styled.View`
    align-items: center;
    height: 18px;
    margin-left: ${px(spacing.md)};
    width: 20px;
`;

export const icon = css`
    height: 18px;
    width: 20px;
`;
