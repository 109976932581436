import {Platform} from 'react-native';
import * as FileSystem from 'expo-file-system';
import {useRecoilState} from 'recoil';
import {OfflineQueueItem} from '@/types/request';
import {offlineQueueState, videoDownloadsState} from '@/utils/store';

const PATH = `${FileSystem.documentDirectory}offline/`;

const useOffline = () => {
    const [downloadedVideosList, setDownloadedVideosList] =
        useRecoilState<string[]>(videoDownloadsState);
    const [offlineQueue, setOfflineQueue] =
        useRecoilState<OfflineQueueItem[]>(offlineQueueState);

    const addToOfflineQueue = (request: OfflineQueueItem) => {
        /**
         * Don't have duplicate requests to the same URL, always use the latest request per URL.
         */
        const filteredQueue = offlineQueue.filter(i => i.url !== request.url);

        setOfflineQueue(queue => [...filteredQueue, request]);
    };

    const addToDownloadCache = (uri: string) => {
        if (!downloadedVideosList.includes(uri)) {
            setDownloadedVideosList([...downloadedVideosList, uri]);
        }
    };

    const removeFromDownloadCache = (uri: string) => {
        setDownloadedVideosList(downloadedVideosList.filter(i => i !== uri));
    };

    const createDirectoryIfEmpty = async () => {
        if (Platform.OS === 'web') {
            return;
        }

        const directoryInfo = await FileSystem.getInfoAsync(PATH);

        if (!directoryInfo.exists) {
            await FileSystem.makeDirectoryAsync(PATH, {intermediates: true});
        }
    };

    const getVideoUri = (url: string, id: number) => {
        if (url && id) {
            const extension = url.split('.').pop();
            const filename = `course_${id}_video.${extension}`;

            return `${PATH}${filename}`;
        }
    };

    const deleteDownloadedVideo = async (url: string, id: number) => {
        if (Platform.OS === 'web') {
            return;
        }

        try {
            const uri = getVideoUri(url, id);

            if (uri) {
                await FileSystem.deleteAsync(uri);
                removeFromDownloadCache(uri);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const deleteAllDownloadedVideos = async () => {
        if (Platform.OS === 'web') {
            return;
        }

        try {
            const directoryInfo = await FileSystem.getInfoAsync(PATH);

            if (directoryInfo.exists) {
                await FileSystem.deleteAsync(PATH);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getDownloadedVideoUri = async (url: string, id: number) => {
        if (Platform.OS === 'web') {
            return;
        }

        try {
            const videoUri = getVideoUri(url, id);

            if (videoUri) {
                const {uri} = await FileSystem.getInfoAsync(`${videoUri}`);

                if (uri) {
                    addToDownloadCache(uri);

                    return uri;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const downloadVideo = async (
        url: string,
        id: number,
        callback: Function
    ) => {
        if (Platform.OS === 'web') {
            return;
        }

        try {
            await createDirectoryIfEmpty();

            const uri = getVideoUri(url, id);

            if (uri) {
                const downloadObject = FileSystem.createDownloadResumable(
                    url,
                    uri,
                    {},
                    progress => callback('progress', progress)
                );
                const response = await downloadObject.downloadAsync();

                addToDownloadCache(uri);

                return callback('success', response);
            } else {
                callback('error', 'unreadable url');
            }
        } catch (error) {
            console.log(error);
            callback('error', error);
        }
    };

    return {
        addToOfflineQueue,
        deleteAllDownloadedVideos,
        deleteDownloadedVideo,
        downloadVideo,
        downloadedVideosList,
        getDownloadedVideoUri,
        getVideoUri,
        offlineQueue,
        setOfflineQueue
    };
};

export default useOffline;
