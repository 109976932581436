import React from 'react';
import {Platform} from 'react-native';
import posed from 'react-native-pose';
import Grid from '@/components/Grid';
import * as S from './styles';

interface Props {
    activeIndex: number;
    size: number;
}

const ItemContainer = posed.View({
    active: {
        opacity: 1,
        scale: 1.6,
        transition: {damping: 9, stiffness: 100, type: 'spring'}
    },
    inactive: {
        opacity: 0.6,
        scale: 0.9,
        transition: {type: 'spring'}
    }
});

const OnboardingPagination: React.FC<Props> = ({
    activeIndex,
    size = 1
}: Props) => (
    <Grid direction="vertical" size={Platform.OS === 'web' ? 'md' : 'sm'}>
        {Array.from(Array(size).keys()).map(i => (
            <ItemContainer
                key={i}
                pose={activeIndex === i ? 'active' : 'inactive'}
            >
                <S.PaginationItem />
            </ItemContainer>
        ))}
    </Grid>
);

export default OnboardingPagination;
