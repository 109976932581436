import React, {useEffect, useState} from 'react';
import {Platform, View} from 'react-native';
import {StackNavigationProp} from '@react-navigation/stack';
import i18n from 'i18n-js';
import {useRecoilState} from 'recoil';
import {toast} from 'react-hot-toast/src/core/toast';
import Button from '@/components/Button';
import LanguageSelector from '@/components/LanguageSelector';
import MemoizedLayout from '@/components/MemoizedLayout';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import StickyFooter from '@/components/StickyFooter';
import * as UI from '@/components/UI/styles';
import useAuth from '@/hooks/useAuth';
import useLanguage from '@/hooks/useLanguage';
import useLearningContent from '@/hooks/useLearningContent';
import useSettings from '@/hooks/useSettings';
import type {AuthUser} from '@/types/auth';
import type {RootStackParamList} from '@/types/navigation';
import RequestType from '@/types/request';
import {languageState} from '@/utils/store';

type Language = AuthUser['preferred_language'];
type SettingsScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'SettingsLanguage'
>;
interface Props {
    navigation: SettingsScreenNavigationProp;
}

const SettingsLanguageScreen: React.FC<Props> = ({navigation}: Props) => {
    const {authUser} = useAuth();
    const {setAppLanguage} = useLanguage();
    const {syncLearningContent} = useLearningContent();
    const {syncSettings} = useSettings();
    const [language, setLanguage] = useRecoilState<Language>(languageState);
    const [newLanguage, setNewLanuage] = useState<Language>(
        language || authUser.preferred_language
    );
    const [formStatus, setFormStatus] = useState<RequestType>(
        RequestType.DEFAULT
    );

    /**
     * Text align will flip if we're viewing RTL so we need to know how to align
     * based on current LTR/RTL view.
     */
    const getTextAlignment = () => {
        if (newLanguage === 'ar') {
            return language !== 'ar' ? 'right' : 'left';
        } else {
            return language === 'ar' ? 'right' : 'left';
        }
    };

    const handleSelection = (language: Language) => {
        setNewLanuage(language);
    };

    const handleComplete = async () => {
        await syncLearningContent();
        await syncSettings();

        if (Platform.OS === 'web') {
            window.location.reload();
        }
    };

    /**
     * If we are changing language we also need to refresh learning content and settings.
     * This of course can't work if we're offline, therefore stale language content will show.
     */
    const prepAndChangeLanguage = async () => {
        try {
            setAppLanguage(newLanguage);

            setTimeout(() => {
                setFormStatus(RequestType.SUCCESS);
            }, 500);
        } catch (error) {
            /**
             * TODO: Let the user know something went wrong here
             */
            console.log(error);
            setAppLanguage(newLanguage);
        }
    };

    /**
     * Submissions only happen when the language has changed, so unlike the language selection
     * screen, we do not need to check for this.
     */
    const handleSubmit = async () => {
        i18n.locale = newLanguage;
        setFormStatus(RequestType.PENDING);
        toast(i18n.t('settingsLanguage.updated'), {
            icon: 'check',
            id: 'settings-language-updated'
        });
        await prepAndChangeLanguage();
    };

    useEffect(() => {
        /**
         * We have to wait for the stored language to change before we can complete
         * and resync data.
         */
        if (language === newLanguage && formStatus === RequestType.SUCCESS) {
            handleComplete();
        }
    }, [language, newLanguage, formStatus]);

    return (
        <Screen
            footer={
                <Button
                    isDisabled={newLanguage === language}
                    label={i18n.t('settingsLanguage.submitCta', {
                        locale: newLanguage
                    })}
                    onPress={handleSubmit}
                    status={formStatus}
                />
            }
            footerSize="oneCta"
        >
            <ScreenHeader theme="tertiary" />
            <ScreenHero
                subtitle={i18n.t('settings.title', {
                    locale: newLanguage
                })}
                title={i18n.t('settingsLanguage.title', {
                    locale: newLanguage
                })}
            />
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <LanguageSelector
                        activeLanguage={newLanguage}
                        onPress={handleSelection}
                    />
                </View>
            </View>
        </Screen>
    );
};

export default SettingsLanguageScreen;
