import styled from '@emotion/native';
import {colors, spacing} from '@/utils/constants';
import {px} from '@/utils/helpers';

export const Container = styled.View`
    flex: 1;
    flex-direction: row;
`;

export const Dot = styled.View`
    background-color: ${colors.black};
    border-radius: ${px(spacing.xs)};
    height: ${px(spacing.xs)};
    margin-right: ${px(spacing.md)};
    margin-top: ${px(spacing.sm)};
    width: ${px(spacing.xs)};
`;

export const TextContainer = styled.View`
    flex-shrink: 1;
    padding-right: ${px(spacing.sm)};
`;
