import merge from 'lodash/merge';
import type {Language} from '@/types/languages';

export interface FetchOptions {
    body?: any;
    headers?: {
        [key: string]: string;
    };
    method?: 'delete' | 'get' | 'post' | 'put';
}

export const fetchOptions = (
    options: FetchOptions = {},
    langauge: Language,
    token?: string
) => {
    let defaultOptions = {
        method: options.body ? 'post' : 'get',
        headers: {
            Accept: 'application/json',
            'Accept-Language': langauge,
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        defaultOptions.headers['Authorization'] = `JWT ${token}`;
    }

    if (options.body) {
        options.body = JSON.stringify(options.body);
    }

    return merge(defaultOptions, options);
};

// @ts-ignore
const fetchWrapper = async (...args) => {
    try {
        /**
         * TODO: Quick workaround for CMS not using headers for language...
         */
        if (
            !args[0].includes('lang') &&
            args[1]?.headers?.['Accept-Language']
        ) {
            const lang = args[1].headers['Accept-Language'];

            args[0] = `${args[0]}${
                args[0].includes('?') ? '&' : '?'
            }lang=${lang}`;
        }

        // @ts-ignore
        const response = await fetch(...args);
        const data = await response.json();

        if (response.ok) {
            return data;
        }

        let error = new Error(response.statusText);

        // @ts-ignore
        error.response = response;
        // @ts-ignore
        error.data = data;

        throw error;
    } catch (error) {
        if (!error.data) {
            error.data = error.message;
        }

        throw error;
    }
};

export default fetchWrapper;
