import {Platform} from 'react-native';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {toast} from 'react-hot-toast/src/core/toast';
import differenceInDays from 'date-fns/differenceInDays';
import useOffline from '@/hooks/useOffline';
import type {AuthUser} from '@/types/auth';
import {
    authUserState,
    clearStorage,
    languageScreenNextScreenState,
    LEARNING_CONTENT_DEFAULT,
    learningContentFetchStatusState,
    learningContentState,
    offlineQueueState,
    remindersState,
    remindersStateDefault,
    settingsState,
    wellbeingSubmissionState,
    wellbeingSubmissionStateDefault,
    videoQualityState,
    videoDownloadsState
} from '@/utils/store';
import {logEvent} from '@/utils/analytics';
import {timeout} from '@/utils/helpers';

/**
 * TODO: Could we use .native files for native vs web swaps when using Secure Store?
 * https://github.com/expo/expo/issues/7744#issuecomment-708897016
 */
const useAuth = () => {
    const {deleteAllDownloadedVideos} = useOffline();
    const [authUser, setAuthUser] = useRecoilState(authUserState);
    const setLanguageScreenNextScreenState = useSetRecoilState(
        languageScreenNextScreenState
    );
    const setLearningContentFetchStatusState = useSetRecoilState(
        learningContentFetchStatusState
    );
    const [settings, setSettings] = useRecoilState(settingsState);
    const setLearningContentState = useSetRecoilState(learningContentState);
    const setOfflineQueueState = useSetRecoilState(offlineQueueState);
    const setRemindersState = useSetRecoilState(remindersState);
    const setWellbeingSubmissionState = useSetRecoilState(
        wellbeingSubmissionState
    );
    const setVideoQualityState = useSetRecoilState(videoQualityState);
    const setVideoDownloadsState = useSetRecoilState(videoDownloadsState);

    const getAuthToken = async () => {
        if (Platform.OS === 'web') {
            const token = await AsyncStorage.getItem('authToken');

            return token;
        } else {
            const token = await SecureStore.getItemAsync('authToken');

            return token;
        }
    };

    const deleteAuthToken = async () => {
        if (Platform.OS === 'web') {
            await AsyncStorage.removeItem('authToken');
        } else {
            await SecureStore.deleteItemAsync('authToken');
        }
    };

    const saveAuthToken = async (token: string) => {
        if (Platform.OS === 'web') {
            await AsyncStorage.setItem('authToken', token);
        } else {
            await SecureStore.setItemAsync('authToken', token);
        }
    };

    const completeSignUp = async (values: Partial<AuthUser>) => {
        setAuthUser({
            ...authUser,
            ...values,
            has_done_walkthrough: true,
            has_completed_profile: false,
            is_active: true
        });
    };

    const isNewUser = () => {
        if (authUser?.created_at) {
            return (
                differenceInDays(new Date(), new Date(authUser.created_at)) <= 1
            );
        }

        return false;
    };

    /**
     * TODO: This is a bit brittle as it relies on CMS content.
     */
    const isTeacher = (user = authUser) => {
        // @ts-ignore
        return (
            user?.role == 1 ||
            user?.role?.id == 1 ||
            user?.role?.name?.toLowerCase()?.includes('teach')
        );
    };

    const canViewForum = () => {
        /**
         * Only users who reside in Jordan can view the forum. If the user has completed
         * their account information then we can check if their country of residence matches
         * Jordan.
         */
        if (authUser?.country_of_residence_id && settings?.countries) {
            const country = settings.countries.find(
                i => i.id == authUser.country_of_residence_id
            );

            if (country) {
                return ['Jordan', 'الأردن'].includes(country.name);
            }
        }

        return false;
    };

    const resetUserState = async (
        timePadding = 0,
        clearLearningState = true
    ) => {
        try {
            /**
             * TODO: Is there a better way to do this in Recoil? I can't find any API that supports it...
             */
            setSettings(null);
            setLanguageScreenNextScreenState(null);
            setOfflineQueueState([]);
            setRemindersState(remindersStateDefault);
            setWellbeingSubmissionState(wellbeingSubmissionStateDefault);
            setVideoQualityState('low');
            setVideoDownloadsState([]);

            if (clearLearningState) {
                setLearningContentFetchStatusState(null);
                setLearningContentState(LEARNING_CONTENT_DEFAULT);
            }

            await timeout(timePadding);
            await clearStorage();
            await deleteAllDownloadedVideos();
        } catch (error) {
            console.log(error);
        }
    };

    const logout = async (message?: string) => {
        await resetUserState(1000);
        await deleteAuthToken();
        await logEvent('user_logged_out');
        setAuthUser(null);

        if (!authUser && message) {
            toast(message, {icon: 'error', id: 'logged-out'});
        }
    };

    const signIn = async (values: Partial<AuthUser>) => {
        await resetUserState(0, false);
        await logEvent('user_logged_in');
        setAuthUser({
            ...values,
            is_active: true
        });
    };

    const signUp = async (values: Partial<AuthUser>) => {
        setAuthUser({
            ...values,
            has_completed_profile: false,
            has_done_walkthrough: false,
            is_active: true,
            password: null
        });
    };

    const updateAuthUser = (newValues: Partial<AuthUser>) => {
        setAuthUser(authUser => ({
            ...authUser,
            ...newValues
        }));
    };

    return {
        authUser,
        canViewForum,
        completeSignUp,
        getAuthToken,
        isNewUser,
        isTeacher,
        logout,
        saveAuthToken,
        signIn,
        signUp,
        updateAuthUser
    };
};

export default useAuth;
