import {boolean, object, string} from 'yup';

const schema = object().shape({
    role: string().required('empty'),
    is_nrc_beneficiary_teacher: boolean().when('role', {
        is: (val: string | number) => val == 1,
        then: boolean().required('empty')
    }),
    is_nrc_beneficiary_parent: boolean().when('role', {
        is: (val: string | number) => val == 2,
        then: boolean().required('empty')
    })
});

export const fields = {
    fields: {
        role: {field: 'input', options: [], type: 'radio'},
        is_nrc_beneficiary_parent: {
            field: 'input',
            hidden: [
                ['role', '1'],
                ['role', ''],
                ['role', null]
            ],
            options: [],
            type: 'radio'
        },
        is_nrc_beneficiary_teacher: {
            field: 'input',
            hidden: [
                ['role', '2'],
                ['role', ''],
                ['role', null]
            ],
            options: [],
            type: 'radio'
        }
    }
};

export default {fields, schema};
