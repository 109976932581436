import React, {forwardRef, useState} from 'react';
import {Platform, TextInput} from 'react-native';
import Svg, {Path} from 'react-native-svg';
import {Field} from 'formik';
import {colors} from '@/utils/constants';
import * as S from './styles';
import useLanguage from '@/hooks/useLanguage';

interface Props {
    autoComplete?: string;
    autoCompleteType?: string;
    autoGrow?: boolean;
    description?: string;
    disabled?: string;
    hasBorder?: boolean;
    info?: string;
    keyboardType?: string;
    label?: string;
    minLength?: string;
    multiline?: boolean;
    name: string;
    onBlur?: Function;
    placeholder?: string;
    rows?: number;
    textAlign?: string;
    type?: string;
    [key: string]: any;
}

const PasswordToggle = ({isSecureTextEntry, handleToggle}) => (
    <S.TextIconContainer onPress={handleToggle}>
        {!isSecureTextEntry ? (
            <Svg height="20" width="20" viewBox="0 0 20 20">
                <Path
                    d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
                    fill={colors.greyDark}
                />
            </Svg>
        ) : (
            <Svg height="20" width="20" viewBox="0 0 20 20">
                <Path
                    d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                    fill={colors.greyDark}
                />
            </Svg>
        )}
    </S.TextIconContainer>
);

const InputField = forwardRef(
    (
        {
            autoGrow = false,
            autoComplete,
            autoCompleteType = 'off',
            description,
            disabled,
            hasBorder = true,
            info,
            keyboardType = 'default',
            label,
            multiline,
            name,
            onBlur,
            placeholder,
            rows = 1,
            textAlign = 'left',
            type,
            ...props
        }: Props,
        ref
    ) => {
        const {direction} = useLanguage();
        const [isSecureTextEntry, setSecureTextEntry] = useState<boolean>(
            type === 'password' || false
        );
        const [isFocused, setIsFocused] = useState(false);

        const toggleSecureTextVisible = () => {
            setSecureTextEntry(!isSecureTextEntry);
        };

        /**
         * Not sure why, but rendering textarea on web dismisses RTL mode regardless
         * of how the styles or props are set
         */
        if (Platform.OS === 'web' && multiline) {
            multiline = false;
            rows = 1;
        }

        return (
            <Field label={label} name={name} placeholder={placeholder}>
                {({field, form, ...fieldProps}) => {
                    return (
                        <S.TextInputContainer>
                            {/* @ts-ignore */}
                            <TextInput
                                autoCompleteType={autoCompleteType}
                                keyboardType={keyboardType}
                                multiline={multiline}
                                numberOfLines={rows}
                                onBlur={() => {
                                    setIsFocused(false);

                                    if (props.onBlur) {
                                        props.onBlur();
                                    }
                                }}
                                onChangeText={form.handleChange(name)}
                                onFocus={() => {
                                    setIsFocused(true);

                                    if (props.onFocus) {
                                        props.onFocus();
                                    }
                                }}
                                placeholder={placeholder || ''}
                                ref={ref}
                                name={name}
                                style={S.textInput({
                                    direction,
                                    hasBorder,
                                    hasError:
                                        form.touched[field.name] &&
                                        form.errors[field.name],
                                    isActive: isFocused,
                                    rows
                                })}
                                textAlign={
                                    direction === 'rtl' ? 'right' : 'left'
                                }
                                textAlignVertical={multiline ? 'top' : 'center'}
                                {...props}
                                value={props.value?.toString()}
                                secureTextEntry={isSecureTextEntry}
                            />
                            {type === 'password' && (
                                <PasswordToggle
                                    isSecureTextEntry={isSecureTextEntry}
                                    handleToggle={toggleSecureTextVisible}
                                />
                            )}
                        </S.TextInputContainer>
                    );
                }}
            </Field>
        );
    }
);

export default InputField;
