import {Platform} from 'react-native';
import {initializeApp} from 'firebase/app';
import {
    getAnalytics,
    logEvent as logEventPrimitive,
    setUserId as setUserIdPromitive
} from 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_WEB_API_KEY,
    projectId: process.env.FIREBASE_WEB_PROJECT_ID,
    appId: process.env.FIREBASE_WEB_APP_ID,
    measurementId: process.env.FIREBASE_WEB_MEASUREMENT_ID
};

const IS_ENABLED = !__DEV__ && Platform.OS === 'web';
const app = IS_ENABLED ? initializeApp(firebaseConfig) : null;
const analytics = IS_ENABLED ? getAnalytics(app) : null;

export const logEvent = (event: string, params = {}) => {
    // console.log('logEvent', event, params);
    if (!IS_ENABLED) return;

    try {
        logEventPrimitive(analytics, event, params);
    } catch (error) {
        console.log(error);
    }
};

export const logScreenView = (name: string, params = {}) => {
    // console.log('logScreenView', name, params);
    if (!IS_ENABLED) return;

    try {
        // @ts-ignore
        logEventPrimitive(analytics, 'screen_view', {
            ...params,
            firebase_class: name,
            firebase_screen: name
        });
    } catch (error) {
        console.log(error);
    }
};

export const setUser = (id: string | null, role: string, language: string) => {
    // console.log('setUser', id, role, language);
    if (!IS_ENABLED) return;

    try {
        setUserIdPromitive(analytics, id);
    } catch (error) {
        console.log(error);
    }
};
