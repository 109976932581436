import React, {ReactNode} from 'react';
import {Text, View} from 'react-native';
import clsx from 'clsx';
import Icon from '@/components/Icon';
import MemoizedLayout from '../MemoizedLayout';

interface Props {
    children?: ReactNode;
    icon?: string;
    type?: keyof typeof THEMES;
    title?: string;
}

// TODO: Move this to a separate file
const THEMES = {
    default: {
        bg: 'bg-blp-grey border-blp-grey',
        text: 'text-blp-black'
    },
    error: {
        bg: 'bg-blp-red-light border-blp-red border-dashed',
        text: 'text-blp-red'
    },
    pending: {
        bg: 'border-dashed border-blp-grey-light',
        text: 'text-black'
    },
    warning: {
        bg: 'border-blp-orange',
        text: 'text-black'
    }
};

const Panel: React.FC<Props> = ({
    children,
    icon,
    type = 'default',
    title
}: Props) => (
    <MemoizedLayout>
        <View
            className={clsx('p-5 flex-1 rounded-xl flex-grow border border-1', {
                [THEMES[type].bg]: type
            })}
        >
            <View className="flex flex-row items-center flex-shrink">
                {icon && (
                    <View>
                        <Icon name={icon} style={{height: 14, width: 14}} />
                    </View>
                )}
                {title && (
                    <Text
                        className={`font-medium text-md leading-5 web:font-medium web:tracking-wide ${THEMES[type].text}`}
                    >
                        {title}
                    </Text>
                )}
            </View>
            {children && (
                <View className={clsx({'mt-2': !!title})}>{children}</View>
            )}
        </View>
    </MemoizedLayout>
);

export default Panel;
