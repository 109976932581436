import {
    cancelAllScheduledNotificationsAsync,
    scheduleNotificationAsync
} from 'expo-notifications';
import i18n from 'i18n-js';
import shuffle from 'lodash/shuffle';
import {useRecoilState} from 'recoil';
import {toast} from 'react-hot-toast/src/core/toast';
import {remindersState} from '@/utils/store';

// The notification handler is defined in `./src/components/PushNotificationsHandler.tsx`
// which is used in `./src/App.tsx`
const useReminders = () => {
    const [config, setConfig] = useRecoilState(remindersState);
    const messages = shuffle(i18n.t('reminders.messages'));

    const removeNotifications = async () => {
        try {
            await cancelAllScheduledNotificationsAsync();
            setConfig({...config, hasBeenPrompted: true, enabled: false});
            toast(i18n.t('reminders.removedSuccess'), {
                icon: 'check',
                id: 'reminders-success'
            });
        } catch (error) {
            toast(error.message, {
                icon: 'error',
                id: 'reminders-error'
            });
        }
    };

    const scheduleNotifications = async () => {
        try {
            await scheduleNotificationAsync({
                content: {
                    title: messages[0].title,
                    body: messages[0].message
                },
                // https://docs.expo.dev/versions/latest/sdk/notifications/#weeklytriggerinput
                trigger: {
                    weekday: 2,
                    hour: 10,
                    minute: 0,
                    // seconds: 20,
                    repeats: true
                }
            });
            setConfig({...config, hasBeenPrompted: true, enabled: true});
            toast(i18n.t('reminders.scheduledSuccess'), {
                icon: 'check',
                id: 'reminders-success'
            });
        } catch (error) {
            toast(error.message, {
                icon: 'error',
                id: 'reminders-error'
            });
        }
    };

    return {
        config,
        removeNotifications,
        scheduleNotifications,
        setConfig
    };
};

export default useReminders;
