import React, {ReactNode, useEffect, useRef, useReducer, useState} from 'react';

interface Props {
    all?: any[];
    children?: ReactNode;
    delay?: number;
    fallbackRetries?: number;
    handleFallback?: Function;
    none?: any[];
    some?: any[];
}

const Conditional: React.FC<Props> = ({
    all,
    children,
    delay = 0,
    fallbackRetries = 1,
    handleFallback,
    none,
    some
}: Props) => {
    /**
     * Store values in a ref to prevent stale values if there is a delay used.
     */
    const values = useRef({all, none, some});
    const timerRef = useRef(null);
    const [fallbackCallCount, setFallbackCallCount] = useState<number>(0);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const checkConditions = () => {
        /**
         * We do not want to continually call fallbacks. An example scenario is:
         * - Load a screen for the first time, it has no content
         * - Our condition has a fallback which fetches for new content
         * - No new content is found, therefore condition fails, therefore callback
         *   gets called once again
         * - Repeat
         *
         * By storing the callback call count and having a retry count, we prevent
         * this from happening.
         */
        if (handleFallback && fallbackCallCount >= fallbackRetries) {
            return;
        }

        let willBeVisible = false;

        if (values.current.all) {
            const matched = values.current.all.filter(i => i);

            if (matched.length === values.current.all.length) {
                willBeVisible = true;
            }
        }

        setIsVisible(willBeVisible);

        if (!willBeVisible && handleFallback) {
            setFallbackCallCount(count => count + 1);
            handleFallback();
        }
    };

    useEffect(() => {
        values.current = {all, none, some};

        if (delay) {
            timerRef.current = setTimeout(() => checkConditions(), delay);
        } else {
            checkConditions();
        }
    }, [all, none, some]);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    if (!isVisible || !children) {
        return null;
    }

    return <>{children}</>;
};

export default Conditional;
