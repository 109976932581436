import React, {ReactNode} from 'react';
import * as UI from '@/components/UI/styles';
import * as S from './styles';

interface Props {
    children: ReactNode;
}

const ListItem: React.FC<Props> = ({children}: Props) => (
    <S.Container>
        <S.Dot />
        <S.TextContainer>
            <UI.Text>{children}</UI.Text>
        </S.TextContainer>
    </S.Container>
);

export default ListItem;
