import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Text, View} from 'react-native';
import i18n from 'i18n-js';
import AccountDetailsPrompt from '@/components/AccountDetailsPrompt';
import ApiViewLoading from '@/components/ApiView/Loading';
import MemoizedLayout from '@/components/MemoizedLayout';
import ModuleCard from '@/components/ModuleCard';
import LearningProgress from '@/components/LearningProgress';
import LearningReminderPrompt from '@/components/LearningReminderPrompt';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import useAuth from '@/hooks/useAuth';
import useLearningContent from '@/hooks/useLearningContent';
import {RequestType} from '@/types/request';

const LearningScreen: React.FC = () => {
    const [isAwaitingRender, setIsAwaitingRender] = useState<boolean>(true);
    const {authUser, isNewUser} = useAuth();
    const {allModules, overallProgress, status, syncLearningContent} =
        useLearningContent();
    let showCompleteProfilePrompt = true;

    if (
        authUser?.has_completed_profile ||
        allModules?.[0]?.progression !== '100%' ||
        allModules?.[0]?.is_done === false
    ) {
        showCompleteProfilePrompt = false;
    }

    const screenStatus = {
        hasAccountDetailsPrompt: showCompleteProfilePrompt,
        hasModules: allModules?.length > 0,
        hasProgress: !!parseInt(overallProgress?.progress),
        isEmpty: !allModules || allModules?.length === 0,
        isLoading:
            status === RequestType.PENDING &&
            (!allModules || allModules?.length === 0)
    };
    const newUser = isNewUser();

    useEffect(() => {
        let timeout;

        if (!screenStatus.isLoading) {
            timeout = setTimeout(() => {
                setIsAwaitingRender(false);
            }, 700);
        } else if (timeout) {
            clearTimeout(timeout);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [screenStatus.isLoading]);

    if (screenStatus.isLoading) {
        return (
            <View className="left-0 bottom-0 w-full top-0 h-full bg-white flex justify-center items-center">
                <ActivityIndicator color="#FF7602" />
            </View>
        );
    } else {
        return (
            <>
                <Screen>
                    <ScreenHeader
                        hasBackButton={false}
                        hasAppActions={true}
                        theme="tertiary"
                    />
                    <MemoizedLayout>
                        <View className="flex">
                            <ScreenHero
                                title={i18n.t(
                                    newUser
                                        ? 'learningIndex.titleFirst'
                                        : 'learningIndex.title'
                                )}
                            >
                                {screenStatus.hasProgress && (
                                    <LearningProgress ctaType="text" />
                                )}
                            </ScreenHero>
                        </View>
                    </MemoizedLayout>
                    <View className="flex-1 mx-auto w-full max-w-2xl">
                        <View className="p-4">
                            {showCompleteProfilePrompt && (
                                <AccountDetailsPrompt />
                            )}
                            <LearningReminderPrompt />
                            <MemoizedLayout>
                                <View className="space-y-3">
                                    {allModules?.length > 0 &&
                                        allModules.map((module, index) => (
                                            <View key={module.id}>
                                                <ModuleCard {...module} />
                                            </View>
                                        ))}
                                </View>
                            </MemoizedLayout>
                        </View>
                    </View>
                </Screen>
                {isAwaitingRender && (
                    <View className="left-0 bottom-0 w-full top-0 h-full bg-white flex justify-center items-center">
                        <ActivityIndicator color="#FF7602" />
                    </View>
                )}
            </>
        );
    }
};

export default LearningScreen;
