import React from 'react';
import {useToaster} from 'react-hot-toast/src/core/use-toaster';
import Toast from './Toast';
import * as S from './styles';

const Notifications = () => {
    const {toasts, handlers} = useToaster({
        duration: 2000
    });

    return (
        <S.Toast>
            {toasts.map((t, index) => {
                return (
                    <Toast
                        key={t.id}
                        offset={handlers.calculateOffset(t, {
                            defaultPosition: 'top-center',
                            gutter: 8,
                            reverseOrder: false
                        })}
                        t={t}
                        updateHeight={handlers.updateHeight}
                    />
                );
            })}
        </S.Toast>
    );
};

export default Notifications;
