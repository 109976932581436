import React from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import useLanguage from '@/hooks/useLanguage';
import {ResourceItem} from '@/types/resource';
import * as S from './styles';

interface Props {
    // The card list can be presented in multiple screens so we need to know
    // where to go back to when the user clicks on the back button
    backToScreen?: {name: string; params?: any};
    resources: ResourceItem[];
}

const ResourcesList: React.FC<Props> = ({backToScreen, resources}) => {
    const {direction} = useLanguage();
    const navigation = useNavigation();

    const handlePress = (resource: ResourceItem) => {
        navigation.navigate('ResourcesDetail', {
            resourceId: resource.id,
            backToScreen,
            resource
        });
    };

    if (!resources?.length) return null;

    return (
        <View className="space-y-3">
            {resources.map((resource: any, index: number) => (
                <View key={resource.id}>
                    <Button
                        align="left"
                        onPress={() => handlePress(resource)}
                        isGhost={true}
                        label={resource.title}
                        theme="greyLight"
                    >
                        <S.IconContainer>
                            <Icon
                                name={
                                    direction === 'rtl'
                                        ? 'right-arrow-reverse'
                                        : 'right-arrow'
                                }
                                style={S.icon}
                            />
                        </S.IconContainer>
                    </Button>
                </View>
            ))}
        </View>
    );
};

export default ResourcesList;
