import {boolean, object, string} from 'yup';

const schema = object().shape({
    gender_id: string().required('empty'),
    role: string().required('empty'),
    family_size: string().when('role', {
        is: (val: string | number) => val == 2,
        then: string().min(1, 'empty').required('empty')
    }),
    classroom_size: string().when('role', {
        is: (val: string | number) => val == 1,
        then: string().min(1, 'empty').required('empty')
    }),
    is_nrc_beneficiary_teacher: boolean().when('role', {
        is: (val: string | number) => val == 1,
        then: boolean().required('empty')
    }),
    is_nrc_beneficiary_parent: boolean().when('role', {
        is: (val: string | number) => val == 2,
        then: boolean().required('empty')
    })
});

export const fields = {
    fields: {
        gender_id: {
            field: 'input',
            options: [],
            type: 'radio'
        },
        role: {
            field: 'input',
            options: [],
            type: 'radio'
        },
        family_size: {
            field: 'input',
            hidden: [
                ['role', '1'],
                ['role', ''],
                ['role', null]
            ],
            keyboardType: 'number-pad'
        },
        classroom_size: {
            field: 'input',
            hidden: [
                ['role', '2'],
                ['role', ''],
                ['role', null]
            ],
            keyboardType: 'number-pad'
        },
        is_nrc_beneficiary_parent: {
            field: 'input',
            hidden: [
                ['role', '1'],
                ['role', ''],
                ['role', null]
            ],
            options: [],
            type: 'radio'
        },
        is_nrc_beneficiary_teacher: {
            field: 'input',
            hidden: [
                ['role', '2'],
                ['role', ''],
                ['role', null]
            ],
            options: [],
            type: 'radio'
        }
    }
};

export default {fields, schema};
