import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {useRecoilValue} from 'recoil';
import i18n from 'i18n-js';
import Icon from '@/components/Icon';
import * as UI from '@/components/UI/styles';
import useLanguage from '@/hooks/useLanguage';
import * as S from './styles';

interface Props {
    id: number;
    title: string;
    type: 'course' | 'module';
}

const NewLearningContentCard: React.FC<Props> = ({id, title, type}: Props) => {
    const {direction} = useLanguage();
    const navigation = useNavigation();

    const handlePress = () => {
        navigation.navigate('LearningModule', {moduleId: id, title});
    };

    return (
        <UI.View>
            <S.Container onPress={handlePress}>
                <S.Content>
                    <S.TextContainer>
                        <UI.Text>
                            {i18n.t(
                                type === 'course'
                                    ? 'learningContentUpdates.newCourse'
                                    : 'learningContentUpdates.newModule'
                            )}
                        </UI.Text>
                        <UI.Heading size="h5">{title}</UI.Heading>
                    </S.TextContainer>
                    <S.IconContainer
                        hasBackgroundColor={false}
                        alignRight={true}
                    >
                        <Icon
                            name={
                                direction === 'rtl'
                                    ? 'right-arrow-reverse'
                                    : 'right-arrow'
                            }
                            style={S.icon}
                        />
                    </S.IconContainer>
                </S.Content>
            </S.Container>
        </UI.View>
    );
};

export default NewLearningContentCard;
