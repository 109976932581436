import React from 'react';
import {View} from 'react-native';
import i18n from 'i18n-js';
import {Formik} from 'formik';
import FormBuilder from '@/components/Form';
import MemoizedLayout from '@/components/MemoizedLayout';
import Screen from '@/components/Screen';
import ScreenHeader from '@/components/ScreenHeader';
import ScreenHero from '@/components/ScreenHero';
import remindersSchema from '@/schemas/reminders';
import useForm from '@/hooks/useForm';
import useReminders from '@/hooks/useReminders';
import RequestType from '@/types/request';

const SettingsRemindersScreen: React.FC = () => {
    const {config, removeNotifications, scheduleNotifications} = useReminders();
    const form = useForm(true);
    const initialValues = {enabled: config?.enabled ? 1 : 0};

    const handleFormSubmit = async values => {
        if (form.status === RequestType.PENDING) return;

        form.setStatus(RequestType.PENDING);

        if (values.enabled) {
            await scheduleNotifications();
        } else {
            await removeNotifications();
        }

        form.setStatus(RequestType.SUCCESS);
        setTimeout(() => form.setStatus(RequestType.DEFAULT), 1000);
    };

    return (
        <Screen>
            <ScreenHeader theme="tertiary" />
            <MemoizedLayout>
                <ScreenHero
                    subtitle={i18n.t('settings.title')}
                    title={i18n.t('settingsReminders.title')}
                />
            </MemoizedLayout>
            <View className="flex-1 mx-auto w-full max-w-2xl">
                <View className="p-4">
                    <View className="h-4" />
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validateOnBlur={true}
                        validateOnChange={false}
                        validationSchema={remindersSchema.schema}
                    >
                        {({handleSubmit, values}) => (
                            <FormBuilder
                                errors={form.errors}
                                form={remindersSchema.fields}
                                handleSubmit={handleSubmit}
                                name="settingsReminders"
                                options={{
                                    enabled: [
                                        {
                                            label: i18n.t(
                                                'settingsReminders.form.enabled'
                                            ),
                                            value: 1
                                        },
                                        {
                                            label: i18n.t(
                                                'settingsReminders.form.notifications_disabled'
                                            ),
                                            value: 0
                                        }
                                    ]
                                }}
                                status={form.status}
                                submitLabel={i18n.t(
                                    'settingsReminders.form.submit'
                                )}
                            />
                        )}
                    </Formik>
                </View>
            </View>
        </Screen>
    );
};

export default SettingsRemindersScreen;
